import { BehaviorSubject, tap } from 'rxjs';
import { Injectable } from '@angular/core';

@Injectable({
	providedIn: 'root',
})
export class DimensionsScreenService {
	data: { width: number; height: number } = { width: window.innerWidth, height: window.innerHeight };
	change: BehaviorSubject<{ width: number; height: number }> = new BehaviorSubject(this.data);
	constructor() {
		this.change.pipe(tap((newData: { width: number; height: number }) => (this.data = { ...this.data, ...newData }))).subscribe();
	}
}
