import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'vl-card-title',
  templateUrl: './vl-card-title.component.html',
  styleUrls: ['./vl-card-title.component.scss']
})
export class VlCardTitleComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
