import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { catchError, Observable, throwError } from 'rxjs';
import { environment } from 'src/environments/environment';
import { ApiService } from './api.service';

@Injectable({
	providedIn: 'root',
})
export class FileUploadService {
	constructor(private http: HttpClient, private api: ApiService) {}

	fileUpload(path: string, file: File, field: string = 'image'): Observable<any> {
		const formData = new FormData();
		formData.append(field, file);
		return this.http
			.post(`${environment.storage}${path}`, formData, {
				reportProgress: true,
				observe: 'events' as 'body',
				headers: { file: 'true' },
			})
			.pipe(catchError(this.formatErrors));
	}

	getFile(idImage: string): Observable<any> {
		return this.http
			.get(`${environment.storage}/c/${idImage}`, {
				headers: { file: 'true' },
			})
			.pipe(catchError(this.formatErrors));
	}

	getFileDocument(idImage: string): Observable<any> {
		return this.api.get(`/v2/files/${idImage}`);
	}

	private formatErrors(error: any) {
		return throwError(error.error);
	}
}
