import { Component, OnInit, ViewEncapsulation } from '@angular/core'
import { ActivatedRoute } from '@angular/router'

@Component({
  selector: 'app-content',
  templateUrl: './content.component.html',
  styleUrls: ['./content.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class ContentComponent implements OnInit {
  constructor(private activatedRoutes: ActivatedRoute) {}

  ngOnInit(): void {
    const myRoutes = this.activatedRoutes.snapshot.routeConfig
  }
}
