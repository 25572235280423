import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ContentRoutingModule } from './content-routing.module';
import { ContentComponent } from './content.component';
import { CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { FormComponent } from './form/form.component';
import { AccountsComponent } from './accounts/accounts.component';
import { WidgetComponent } from './widget/widget.component';
import { InstagramComponent } from './instagram/instagram.component';
import { MatTableModule } from '@angular/material/table';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatSortModule } from '@angular/material/sort';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatMenuModule } from '@angular/material/menu';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatDialogModule, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSelectModule } from '@angular/material/select';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatStepperModule } from '@angular/material/stepper';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatExpansionModule } from '@angular/material/expansion';

// import { ToastrModule, ToastrService } from 'ngx-toastr';
// import { NgxLoadingModule } from 'ngx-loading';
import { MatChipsModule } from '@angular/material/chips';
import { CustomTooltipDirective } from 'src/app/directives/custom-tooltip.directive';
import { OverlayModule } from '@angular/cdk/overlay';
import { ConfirmDialogComponent } from 'src/app/@components/confirm-dialog/confirm-dialog.component';

import { AttentionComponent } from './contact-center/attention/attention.component';
import { HookFormsComponent } from './contact-center/hook-forms/hook-forms.component';
import { ComponentsModule } from 'src/app/@components/components.module';

import { TestComponent } from './test/test.component';
import { AnalyticsComponent } from './analytics/analytics.component';
import { SatisfactionComponent } from './satisfaction/satisfaction.component';
import { ResolutivityComponent } from './resolutivity/resolutivity.component';
import { AttentionsComponent } from './attentions/attentions.component';
import { WhatsappActivationComponent } from './whatsapp-activation/whatsapp-activation.component';
import { WhatsappPersonalizeComponent } from './whatsapp-personalize/whatsapp-personalize.component';
import { FbsdkService } from 'src/app/services/backend/fbsdk.service';
import { FacebookActivationComponent } from './facebook-activation/facebook-activation.component';
import { FacebookPersonalizeComponent } from './facebook-personalize/facebook-personalize.component';
import { CopyDirective } from './../../directives/copy.directive';

import { GoogleChartsModule } from 'angular-google-charts';
import { FirstWhatsappStepsComponent } from './first-whatsapp-steps/first-whatsapp-steps.component';
import { TicketsIntegrationComponent } from './tickets-integration/tickets-integration.component';

import { DropzoneModule } from 'ngx-dropzone-wrapper';
import { InstagramActivationComponent } from './instagram-activation/instagram-activation.component';
import { InstagramPersonalizeComponent } from './instagram-personalize/instagram-personalize.component';
import { InstagramStepsComponent } from './instagram-steps/instagram-steps.component';
import { VlCardModule } from '@components/vl-card/vl-card.module';
import { VlCodePreviewComponent } from '@components/vl-code-preview/vl-code-preview.component';

@NgModule({
	declarations: [
		ContentComponent,
		CopyDirective,
		FormComponent,
		AccountsComponent,
		WidgetComponent,
		InstagramComponent,
		CustomTooltipDirective,
		ConfirmDialogComponent,
		WhatsappActivationComponent,
		WhatsappPersonalizeComponent,
		TestComponent,
		AnalyticsComponent,
		SatisfactionComponent,
		ResolutivityComponent,
		AttentionsComponent,
		FacebookActivationComponent,
		FacebookPersonalizeComponent,
		InstagramActivationComponent,
		InstagramPersonalizeComponent,
		InstagramStepsComponent,
		FirstWhatsappStepsComponent,
		TicketsIntegrationComponent,
	],
	imports: [
		CommonModule,
		ContentRoutingModule,
		OverlayModule,
		FormsModule,
		ReactiveFormsModule,
		// ToastrModule.forRoot(),
		// NgxLoadingModule.forRoot({}),
		ComponentsModule,
		GoogleChartsModule,
		MatStepperModule,
		DropzoneModule,
		VlCardModule,
		VlCodePreviewComponent,
	],
	providers: [
		// ToastrService,
		ConfirmDialogComponent,
		{ provide: MAT_DIALOG_DATA, useValue: {} },
	],
	schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class ContentModule {
	constructor() {}
}
