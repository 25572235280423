import { ApiService } from './../../../services/backend/api.service';
import { PreferencesService } from './../../../services/backend/preferences.service';
import { AuthService } from 'src/app/services/storage/auth.service';
import { ToastService } from './../../../services/backend/toast.service';
import { Component, OnInit } from '@angular/core';

@Component({
	selector: 'app-test',
	templateUrl: './test.component.html',
	styleUrls: ['./test.component.scss'],
})
export class TestComponent implements OnInit {
	constructor(
		private toastService: ToastService,
		private AuthService: AuthService,
		private preferencesService: PreferencesService,
		private apiService: ApiService
	) {}

	ngOnInit(): void {
		this.apiService.get(`/c/${this.AuthService.getInfo().company}/pref`).subscribe();

		this.apiService.get('/apps/url/' + 'e9ffad8372381c4255b05c5675ab2917b0c3980db6a959f95b212d53e7783f48').subscribe();
	}
}
