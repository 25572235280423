<div class="row" *ngFor="let item of data" [ngStyle]="{ '--col': item.customCol ? item.customCol : 'initial' }">
	<p class="row__key">{{ item.label || item.placeholder }}</p>
	<p class="row__value" *ngIf="item._id != 'download' && item._id != 'upload'">{{ item.value || '-' }}</p>
	<p class="row__value" *ngIf="item._id == 'download'">
		<a [href]="files + '/c/' + item.download" target="_blank">{{ getOriginalName(item.download, item.label) }}</a>
	</p>
	<p class="row__value" *ngIf="item._id == 'upload'">
		<a [href]="item.download ? files + '/c/' + item.download : 'javascript:void(0)'" [target]="item.download ? '_blank' : ''">{{
			getOriginalName(item.download, item.label)
		}}</a>
	</p>
</div>
<div class="row" *ngIf="data.length % 2 !== 0 && screenWidth > 1023">
	<p class="row__key"></p>
	<p class="row__value"></p>
</div>

<p class="notDataText" *ngIf="data.length === 0">No hay información disponible de esta sección en esta atención</p>
