import { Component, OnInit, OnDestroy, HostListener, inject } from '@angular/core';
import { ApiService } from '@services/backend/api.service';
import { ChartType } from 'angular-google-charts';
import { Subject, lastValueFrom } from 'rxjs';
import { finalize, take, takeUntil, tap } from 'rxjs/operators';
import { DatePipe } from '@angular/common';
import { LoadingService } from '@services/components/loading.service';
import { HttpParams } from '@angular/common/http';
import { FormBuilder } from '@angular/forms';
import * as moment from 'moment';
import { PreferencesService } from '@services/backend/preferences.service';

@Component({
	selector: 'app-attentions',
	templateUrl: './attentions.component.html',
	styleUrls: ['./attentions.component.scss'],
	providers: [DatePipe],
})
export class AttentionsComponent implements OnInit, OnDestroy {
	@HostListener('window:resize', ['$event'])
	onWindowResize() {
		this.getScreenWidth = window.innerWidth;
	}

	@HostListener('window:load', ['$event'])
	onWindowLoad() {
		this.getScreenWidth = window.innerWidth;
	}

	constructor(private api: ApiService, private datePipe: DatePipe, private _loadingSrc: LoadingService, private formBuilder: FormBuilder) {
		this.initForm();
	}

	public getScreenWidth: number = window.innerWidth;
	public today: Date = new Date();

	typeChartBar = ChartType.Bar;
	typePieChart = ChartType.PieChart;
	show: boolean = false;
	form: any;
	isCustom: boolean = false;

	////Dashboard General data

	//groups
	dataChartGroups = [];
	dataGroups: any = [];
	groups: any = {};

	///abilities
	dataChartAbilities = [];
	dataAbilities: any = [];
	abilities: any = {};

	///total attentions

	totalAttentions: any = 0;

	///total resolution
	totalResolution: any = 0;

	///total satisfaction
	totalSatisfaction: any = 0;
	totalSas: any = 0;
	totalA: any = 0;
	/// total attentions by channel
	totalChannel: any = [];

	myData = [
		['Ene', 0],
		['Feb', 0],
		['Mar', 0],
		['Abr', 0],
		['May', 0],
		['Jun', 0],
		['Jul', 0],
		['Ago', 0],
		['Sep', 0],
		['Oct', 0],
		['Nov', 0],
		['Dic', 0],
	];
	myOptions: any = {
		colors: ['#4B27EF'],
		legend: 'none',
	};
	myOptions1: any = {
		legend: 'none',
		pieHole: 0.6,
		pieSliceText: 'none',
		pieSliceTextStyle: {
			bold: true,
			fontSize: 12,
		},
	};
	myOptions2: any = {
		legend: 'none',
		pieSliceText: 'none',
	};

	colors = [
		['#EA345F', '#6848F3', '#3B404D'],
		['#1B7C9E', '#48526B', '#D43224'],
		['#EBE14B', '#F08C3E', '#12A312'],
		['#3B404D', '#6848F3', '#EA345F'],
		['#D43224', '#48526B', '#1B7C9E'],
		['#F08C3E', '#12A312', '#EBE14B'],
	];
	filterType: any = 'year';
	filterText: any = 'Año';
	averageTime: any = 0;
	averageTimeOld: any = 0;
	averageTimePercentage: any = 0;
	isData: any = false;
	public minDate: Date = new Date();
	private _unSuscribeAll: Subject<any> = new Subject();
	private _preferencesSrc: PreferencesService = inject(PreferencesService);

	onSelect($event: any) {
		const { selection } = $event;

		if (!selection.length) {
			return;
		}

		const { row } = selection[0];

		const diccionaryDayWeek: any = {
			Domingo: 0,
			Lunes: 1,
			Martes: 2,
			Miercoles: 3,
			Jueves: 4,
			Viernes: 5,
			Sabado: 6,
		};
		const diccionaryMothYears: any = {
			Ene: 0,
			Feb: 1,
			Mar: 2,
			Abr: 3,
			May: 4,
			Jun: 5,
			Jul: 6,
			Ago: 7,
			Sep: 8,
			Oct: 9,
			Nov: 10,
			Dic: 11,
		};

		let init = new Date();
		let end = new Date();
		let diffDay = 0;
		const date = new Date();

		switch (this.filterType) {
			case 'custom':
				const [day = new Date().getDate(), month = new Date().getMonth(), year = new Date().getFullYear()] = (
					this.myData[row][0] as string
				)?.split('/');

				init = new Date(Number(year), Number(month) - 1, Number(day));
				end = new Date(Number(year), Number(month) - 1, Number(day));

				break;
			case 'year':
				date.setMonth(diccionaryMothYears[this.myData[row][0]]);
				date.setDate(1);
				init = new Date(date);
				date.setDate(30);
				end = date;
				break;
			case 'mounth':
				date.setDate(this.myData[row][0] as number);
				init = date;
				end = date;
				break;
			case 'weekly':
				diffDay = date.getDay() - diccionaryDayWeek[this.myData[row][0]];
				date.setDate(date.getDate() - diffDay);

				init = date;
				end = date;

				break;
			case 'day':
				break;
		}

		this.handleData('custom');
		this.form.controls['dateInit'].setValue(init);
		this.form.controls['dateEnd'].setValue(end);
	}

	handleData(type: any) {
		const today = new Date();
		this.filterType = type;

		switch (type) {
			case 'mounth':
				this.show = false;
				this.isCustom = false;
				this.filterText = 'Mes';
				let firstDayMounth = new Date(today.getFullYear(), today.getMonth(), 1);
				let lastDayMounth = new Date(today.getFullYear(), today.getMonth() + 1, 0);

				this.getData({ primaryDate: today, startDate: firstDayMounth, endDate: lastDayMounth });

				break;
			case 'day':
				this.show = false;
				this.isCustom = false;
				const last = new Date();
				this.filterText = 'Día';
				last.setHours(last.getHours() + 23);
				last.setMinutes(last.getMinutes() + 59);
				last.setSeconds(last.getSeconds() + 59);
				last.setMilliseconds(last.getMilliseconds() + 999);

				this.getData({ primaryDate: today, startDate: today, endDate: last });
				break;
			case 'year':
				this.filterText = 'Año';
				this.show = false;
				this.isCustom = false;
				const currentYear = new Date().getFullYear();
				const firstDayYear = new Date(currentYear, 0, 1);
				const lastDayYear = new Date(currentYear, 11, 31);

				this.getData({ primaryDate: today, startDate: firstDayYear, endDate: lastDayYear });

				break;
			case 'weekly':
				this.filterText = 'Semana';
				this.show = false;
				this.isCustom = false;
				const firstWeek = new Date(today.setDate(today.getDate() - today.getDay()));
				const lastWeek = new Date(today.setDate(today.getDate() - today.getDay() + 6));

				this.getData({ primaryDate: today, startDate: firstWeek, endDate: lastWeek });

				break;
			case 'custom':
				this.show = true;
				break;
		}
	}

	async getData({ primaryDate = new Date(), startDate = null, endDate = null, type = this.filterType }: any = {}) {
		try {
			this._loadingSrc.initiate();

			const currentYear = primaryDate.getFullYear();

			const bodyParams: any = {
				startDate: startDate ?? this.transformDate(new Date(currentYear, 0, 1)),
				endDate: endDate ?? this.transformDate(new Date(currentYear, 11, 31)),
				type,
			};
			const httpParams = new HttpParams({ fromObject: bodyParams });

			let isWaitingToResponse = {
				abilities: true,
				group: true,
				conversations: true,
				resolutivity: true,
				satisfaction: true,
				channel: true,
			};

			//Mover a la funcion correspondiente

			this.api
				.get(`/v2/dashboard/general`, httpParams)
				.pipe(
					takeUntil(this._unSuscribeAll),
					tap((response: any) => {
						///abilities
						if (!response.abilities.chartData.find((chart: any) => chart[1] !== 0)) {
							response.abilities.chartData.push(['not result', 1]);
							this.myOptions1 = {
								...this.myOptions1,
								tooltip: {
									trigger: 'none',
								},
								colors: ['#E6E7F3'],
							};
						} else {
							this.myOptions1 = {
								...this.myOptions1,
								colors: this.colors[this.getRandomInt(5)],
								tooltip: {
									trigger: 'hover',
								},
							};
						}
						this.abilities = response.abilities;
						this.dataChartAbilities = this.abilities.chartData;
						this.dataAbilities = this.abilities.abilities;

						///groups
						if (!response.groups.chartData.find((chart: any) => chart[1] !== 0)) {
							response.groups.chartData.push(['not result', 1]);
							this.myOptions2 = {
								...this.myOptions2,
								tooltip: {
									trigger: 'none',
								},
								colors: ['#E6E7F3'],
							};
						} else {
							this.myOptions2 = {
								...this.myOptions2,
								tooltip: {
									trigger: 'hover',
								},
								colors: this.colors[this.getRandomInt(5)],
							};
						}

						this.groups = response.groups;
						this.dataChartGroups = this.groups.chartData;
						this.dataGroups = this.groups.groups;
						///totalAttentions
						const { attentionsTotal, attentionsTotalOld } = response;

						this.totalAttentions = {
							attentionsTotal: attentionsTotal,
							attentionsTotalOld: attentionsTotalOld,
							percentage: this.getPercentage(attentionsTotalOld, attentionsTotal),
						};
						this.totalA = this.getPercentage(this.totalAttentions.attentionsTotalOld, this.totalAttentions.attentionsTotal);

						///resolutiviad

						if (response.resolution === null) response.resolution = 0;
						if (response.resolutionOld === null) response.resolutionOld = 0;
						this.totalResolution = {
							resolution: response.resolution,
							resolutionOld: response.resolutionOld,
						};

						///satisfaction

						if (response.average === null) response.average = 0;
						if (response.averageOld === null) response.averageOld = 0;

						const { average, averageOld } = response;

						this.totalSatisfaction = {
							average: average,
							averageOld: averageOld,
							percentage: this.getPercentage(averageOld, average),
						};
						this.totalSas = this.getPercentage(this.totalSatisfaction.averageOld, this.totalSatisfaction.average);

						///channels

						this.totalChannel = response.channels;

						////average time

						this.averageTime = response.averageTime === null ? '0:00:00' : response.averageTime.split('T')[1].split('.')[0];
						this.averageTimePercentage =
							response.averageTime === null && response.averageTimeOld === null ? 0 : response.averageTimePercetage;

						this.averageTimeOld = response.averageTimeOld === null ? '0:00:00' : response.averageTimeOld.split('T')[1].split('.')[0];

						this.myData = response.chartBarData;
						if (this.myData.find((el: any) => el[1] > 0)) {
							this.isData = true;
						} else {
							this.isData = false;
						}
					}),
					finalize(() => {
						isWaitingToResponse = {
							...isWaitingToResponse,
							abilities: false,
							group: false,
							conversations: false,
							resolutivity: false,
							satisfaction: false,
							channel: false,
						};
						Object.values(isWaitingToResponse).includes(true) ? '' : this._loadingSrc.hide();
					})
				)
				.subscribe();
		} catch (error) {
			console.log(error);
			this._loadingSrc.hide();
		}
	}

	transformDate(date: Date) {
		const DATE = new Date(date);
		return new Intl.DateTimeFormat('es', { year: 'numeric', month: 'numeric', day: 'numeric' }).format(DATE).split('/').reverse().join('-');
	}

	getRandomInt(max: any) {
		return Math.floor(Math.random() * max);
	}

	getPercentage(value1: any, value2: any): any {
		if (value1 > value2) {
			if (value1 === 0) {
				return `+ ${(value2 * 10).toFixed(2)}`;
			} else {
				const result = (((value1 - value2) / value1) * 100).toFixed(2);
				return `- ${result}`;
			}
		} else {
			if (value1 === 0) {
				return `+ ${(value2 * 10).toFixed(2)}`;
			} else {
				const result = (((value2 - value1) / value1) * 100).toFixed(2);
				return `+ ${result}`;
			}
		}
	}

	initForm() {
		var today = new Date();

		this.form = this.formBuilder.group({
			dateInit: [''],
			dateEnd: [''],
		});

		this.form.valueChanges.subscribe((data: any) => {
			const today = data.dateInit !== '' ? moment.utc(new Date(data.dateInit)) : moment.utc(new Date(data.dateEnd));
			const endDate = data.dateEnd !== '' ? moment.utc(new Date(data.dateEnd)) : moment.utc(new Date(data.dateInit));
			const diff = endDate.diff(today, 'd');
			endDate.add(23, 'h');
			endDate.add(59, 'm');
			endDate.add(59, 's');

			this.isCustom = true;
			this.getData({
				primaryDate: new Date(today.toString()),
				startDate: new Date(today.toString()),
				endDate: new Date(endDate.toString()),
				type: diff === 0 ? 'day' : undefined,
			});
		});
	}

	ngOnInit() {
		const today = new Date();
		this._unSuscribeAll = new Subject();
		this.filterType = 'weekly';
		this.filterText = 'Semana';
		this.show = false;
		this.isCustom = false;
		const firstWeek = new Date(today.setDate(today.getDate() - today.getDay()));
		const lastWeek = new Date(today.setDate(today.getDate() - today.getDay() + 6));

		this.getData({ primaryDate: today, startDate: firstWeek, endDate: lastWeek });
		this.myOptions1 = {
			...this.myOptions1,
			colors: this.colors[this.getRandomInt(5)],
		};
		this.myOptions2 = {
			...this.myOptions2,
			colors: this.colors[this.getRandomInt(5)],
		};

		lastValueFrom(this._preferencesSrc.getPreferences()).then(({ createdAt }) => {
			this.minDate = new Date(createdAt);
		});
	}

	ngOnDestroy(): void {
		this._unSuscribeAll.next({});
		this._unSuscribeAll.complete();
	}
}
