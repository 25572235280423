import { Injectable } from '@angular/core'
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpHeaders,
  HttpErrorResponse,
} from '@angular/common/http'
import { catchError, Observable, throwError } from 'rxjs'
import { AuthService } from '../services/storage/auth.service'
import { Router } from '@angular/router'

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  constructor(private _authService: AuthService, private router: Router) {}

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    // Obtengo el token actual de la sesion...
    const token = this._authService.getToken()
    let request = req
    const isFile = req.headers.has('file')

    const headers: any = {
      'Content-Type': 'application/json',
    }

    // Validacion de existencia del token para agregar la cabecera...
    if (token) {
      // Modificación del request añadiendo la cabecera de autorizacion de videolink...
      headers.Authorization = `videolink ${token}`
    }
    if (isFile) {
      delete headers['Content-Type']
      let he = new HttpHeaders(headers).delete('file')
    }

    request = req.clone({
      setHeaders: headers,
    })

    return next.handle(request).pipe(
      catchError((err: HttpErrorResponse) => {
        // Redirección al login en caso de status 401...
        if (err.status === 401) {
          this._authService.clearAll()
          this.router.navigate(['/auth/login'])
        }

        return throwError(err)
      })
    )
  }
}
