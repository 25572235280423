import { Component, OnInit, Input, TemplateRef } from '@angular/core';

@Component({
	selector: 'vl-stepper',
	templateUrl: './vl-stepper.component.html',
	styleUrls: ['./vl-stepper.component.scss'],
})
export class VlStepperComponent implements OnInit {
	@Input() stepActive: number = 0;
	@Input() config: Array<{
		header: string;
		content: TemplateRef<any>;
		keyConditionByNextStep: string;
		keyConditionByLastAction: string;
		textNextStep?: string;
		textPrevStep?: string;
		textLastStep?: string;
		lastAction?: () => any;
	}> = [];

	@Input() conditionsStep: any = {};

	constructor() {}

	ngOnInit(): void {}
}
