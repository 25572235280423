<!-- <div class="v-select__info" [ngClass]="[isOpenPanel ? 'active' : '', customClass ? customClass : '']" (click)="togglePanelOptions()">
	<ng-container *ngIf="!config.multiple; else isMulti">
		<div class="v-select__single">
			<div class="v-select__single__text" *ngIf="infoSelected.length === 0; else selection">
				{{ config.tofilter ? 'Todos' : 'Seleccionar' }}
			</div>
			<ng-template #selection>
				<div class="v-select__single__group">
					<ng-container *ngIf="inView.icon">
						<div class="v-select__single__group_icon">
							<v-icon [name]="inView.icon" class="icon" classCustom="v-select_icon"></v-icon>
						</div>
					</ng-container>
					<ng-container *ngIf="inView.name">
						<div class="v-select__single__group_text">{{ inView.name }}</div>
					</ng-container>
				</div>
			</ng-template>
		</div>
	</ng-container>
	<ng-template #isMulti>
		<div class="v-select__multi">
			<div class="v-select__multi__text" *ngIf="infoViewMulti.length === 0; else selection">
				{{ config.tofilter ? 'Todos' : 'Seleccionar' }}
			</div>
			<ng-template #selection>
				<div class="v-select__multi__group" *ngFor="let item of infoViewMulti; let i = index">
					<div class="v-select__multi__group_icon" *ngIf="item.icon && config?.withIcon">
						<v-icon [name]="item.icon"></v-icon>
					</div>
					<div class="v-select__multi__group_text" *ngIf="item.name && !config?.onlyIcon">
						{{ item.name }}
						<span *ngIf="i != infoSelected.length - 1">,</span>
					</div>
				</div>
				<span *ngIf="infoSelected.length > 2">...</span>
			</ng-template>
		</div>
	</ng-template>
	<div class="v-select__arrow">
		<svg viewBox="0 0 16 11" fill="none" xmlns="http://www.w3.org/2000/svg">
			<path
				fill-rule="evenodd"
				clip-rule="evenodd"
				d="M14.6641 0.537109C15.7254 0.537109 16.0377 1.20409 15.3622 2.02625L8.65227 10.1929C8.3523 10.558 7.87232 10.5535 7.57865 10.181L1.16974 2.05108C0.510599 1.21494 0.83717 0.537109 1.8978 0.537109H14.6641Z"
				class="withFill"
			/>
		</svg>
	</div>
</div>
<div class="v-select__overlay" *ngIf="isOpenPanel" (click)="closePanelOptions()"></div>
<div class="v-select__panelOptions" [ngClass]="[isOpenPanel ? 'active' : '']">
	<v-option
		*ngFor="let option of options"
		[item]="option"
		[config]="config"
		(toggleSelection)="change($event)"
		[isSelected]="infoViewMulti.includes(option)"
		[customClass]="optionCustomClass"
	></v-option>
</div> -->

<mat-select
	[placeholder]="placeholder ? placeholder : ' Seleccionar'"
	[ngClass]="['selectCustom', config.tofilter ? 'toFilter' : '']"
	[panelClass]="['panelOfSelectCustom', config.tofilter ? 'toFilter' : '']"
	[value]="value"
	[multiple]="config.multiple"
	(selectionChange)="change($event)"
	#select
>
	<mat-select-trigger>
		<ng-container *ngIf="infoSelected && infoSelected.length === 0; else withInfo">
			<span>{{ config.tofilter ? 'Todos' : ' Seleccionar' }}</span></ng-container
		>
		<ng-template #withInfo>
			<ng-container *ngIf="config.multiple; else single">
				<div class="optionContent" [ngClass]="config.withIcon ? 'withIcon' : ''">
					<v-icon [name]="infoViewMulti[0].icon" class="optionContent__icon" *ngIf="infoViewMulti[0]?.icon && config?.withIcon"></v-icon>
					<div class="optionContent__text">
						{{ infoViewMulti[0].name }}
						<span *ngIf="infoSelected.length > 1"> y {{ infoSelected.length - 1 }} mas...</span>
					</div>
				</div>
			</ng-container>
			<ng-template #single>
				<div class="optionContent" [ngClass]="config.withIcon ? 'withIcon' : ''">
					<v-icon [name]="inView.icon" class="optionContent__icon" *ngIf="inView.icon && config?.withIcon"></v-icon>
					<div class="optionContent__text">{{ inView.name }}</div>
				</div>
			</ng-template>
		</ng-template>
	</mat-select-trigger>

	<ng-container *ngFor="let option of options">
		<mat-option
			[value]="option.value"
			[ngClass]="['optionCustom', config.tofilter ? 'toFilter' : '']"
			disableRipple
			[disabled]="checkOkay(option.value, option.name)"
		>
			<div class="optionContent" [ngClass]="config.withIcon ? 'withIcon' : ''">
				<v-icon [name]="option.icon" class="optionContent__icon" *ngIf="config.withIcon"></v-icon>
				<div class="optionContent__text" [title]="checkOkayTitle(option.name)">{{ option.name }}</div>
			</div>
		</mat-option>
	</ng-container>
</mat-select>
