<main>
	<app-title-section [title]="contentTitle" [description]="contentDescription"></app-title-section>

	<div class="limiter" *ngIf="false">
		<form [formGroup]="selectAppForm" class="ccSelect_container">
			<p class="title-select">Selecciona el widget.</p>
			<div class="same-line">
				<div class="input-field">
					<mat-form-field appearence="none" style="height: 39px !important">
						<div class="input-context" style="margin-top: -20px !important">
							<mat-select formControlName="app">
								<mat-option value="0" (click)="changeToken('0')">Selecciona el widget</mat-option>
								<mat-option *ngFor="let itemA of appsList" (click)="changeApp(itemA._id)" [value]="itemA._id">
									{{ itemA.name }}
								</mat-option>
							</mat-select>
						</div>
					</mat-form-field>
				</div>
				<a target="_blank" [href]="urlApp" target="_blank" *ngIf="getScreenWidth > 768" class="btn_view">Ir al widget</a>
				<a target="_blank" [href]="urlApp" target="_blank" *ngIf="getScreenWidth < 768" class="btn_view little"
					><img src="../../../../assets/img/openurl.svg" alt="icono de ir al widget"
				/></a>
			</div>
		</form>
		<!-- <div class="img-container">
			<img
				src="../../../../assets/img/widget-principal.png"
				alt="imagen donde se pueden visualizar las distintas presentaciones del widget de videolink"
			/>
		</div> -->
		<div class="accordion-container">
			<div class="subtitle-container">
				<h3 class="subtitle-container__content">Configuraciones</h3>
			</div>
			<div class="accordion-container__content">
				<mat-accordion>
					<mat-expansion-panel>
						<mat-expansion-panel-header>
							<mat-panel-title class="title-custom"> Instalación y configuración </mat-panel-title>
						</mat-expansion-panel-header>
						<div class="accordion-content">
							<div class="accordion-content_contenido">
								<header>
									<p class="p-content">
										Widget para solicitar una atención en cualquier sitio web con un agente de su empresa registrado en VideoLink
									</p>
									<h3>Pasos para configurar el Widget en tu sitio web</h3>
								</header>
								<div class="accordion-content_contenido_steps">
									<h5 class="title">Gestiona tus aplicaciones</h5>
									<p class="p-content" *ngIf="listItemsApps.length == 0">
										En estos momentos aún no cuentas con una aplicación de VideoLink en tu empresa, crea una aplicación.
									</p>
									<p class="p-content" *ngIf="listItemsApps.length > 0">Gestiona tus aplicaciones de VideoLink.</p>
									<a [routerLink]="['/dashboard/attentions/aplications']" class="link-apps">Gestionar aplicaciones</a>
									<!-- <p class="title">Genera el selector</p>
									<form [formGroup]="formToken">
										<div class="input-field">
											<mat-form-field appearence="none" style="height: 39px !important">
												<div class="input-context" style="margin-top: -20px !important">
													<mat-select formControlName="tokenItem">
														<mat-option value="0" (click)="changeToken('0')"> Selecciona la aplicación </mat-option>
														<mat-option *ngFor="let itemA of listItemsApps" (click)="changeToken(itemA._id)" [value]="itemA._id">
															{{ itemA.name }}
														</mat-option>
													</mat-select>
												</div>
											</mat-form-field>
										</div>
										<div class="reload-container">
											<button (click)="initAll()" class="button-reload">Recargar</button>
										</div>
									</form> -->
									<div class="copy-container">
										<div class="data-field">
											<div class="input-field">
												<input type="text" value="{{ tuToken }}" class="input-copy" disabled />
												<app-copy-button [contentCopy]="tuToken"></app-copy-button>
											</div>
										</div>
									</div>

									<h4 class="title">Instalación y configuración</h4>

									<p class="p-content">
										Dentro del archivo “index.html” de tu sitio web en el directorio raíz antes de la etiqueta de cierre del
										<strong>body</strong>, se debe incluir la etiqueta personalizada del widget de VideoLink. En la etiqueta de apertura
										personalizada del widget de VideoLink, se debe de incluir el atributo obligatorio token y dentro de las comillas dobles
										del mismo, el valor de la semilla (token).
									</p>
									<div class="accordion-content_co">
										<div class="accordion-content_co_code-content">
											<button
												title="copy example source"
												aria-label="copy example source yo clipboard"
												(click)="textToClipboard(contentSecond + tuToken + contentSecondTwo)"
												type="button"
											>
												<img src="../../../../assets/img/copy-to-clipboard.svg" alt="" />
											</button>
										</div>
										<!-- <pre appCopy><code>{{contentSecond}}{{tuToken}}{{contentSecondTwo}}</code></pre> -->
										<pre
											appCopy
										><code><p style="color:rgb(49, 121, 246)">&lt;widget-videolink <span style="color:rgb(239, 181, 73)">token</span>=<span style="color:rgb(36, 187, 58)">"{{tuToken}}"</span>&gt; &lt;/widget-videolink&gt;</p></code></pre>
									</div>
									<h5 class="title">Opcional</h5>
									<p class="p-content">
										2. Opcionalmente, si desea que su widget omita el formulario, puede especificar dos atributos adicionales:
									</p>
									<p class="p-content">
										2.1 Atributo mail: dentro de las dobles comillas del mismo, el correo por defecto para el momento de pedir una atención.
									</p>
									<div class="accordion-content_co">
										<div class="accordion-content_co_code-content">
											<button
												title="copy example source"
												aria-label="copy example source yo clipboard"
												(click)="textToClipboard(contentThree + tuToken + contentThreeTwo)"
												type="button"
											>
												<img src="../../../../assets/img/copy-to-clipboard.svg" alt="" />
											</button>
										</div>
										<!-- <pre appCopy><code>{{contentThree}}{{tuToken}}{{contentThreeTwo}}</code></pre> -->
										<pre><code><p style="color:rgb(49, 121, 246)"> &lt;widget-videolink <span style="color:rgb(239, 181, 73)">token</span>=<span style="color:rgb(36, 187, 58)">"{{tuToken}}"</span> <span style="color:rgb(239, 181, 73)">mail</span>=<span style="color:rgb(36, 187, 58)">"no-reply@mail.com"</span>&gt; &lt;/widget-videolink&gt;</p></code></pre>
									</div>
									<p class="p-content">
										2.2 Atributo position: dentro de las dobles comillas del mismo, se indica la posición en la que se ubicará el widget
										dentro de su sitio web (en la esquina inferior izquierda o esquina inferior derecha). En caso de que no agregue este
										atributo, el widget se ubicará en la esquina inferior derecha.
									</p>
									<div class="accordion-content_co">
										<div class="accordion-content_co_code-content">
											<button
												title="copy example source"
												aria-label="copy example source yo clipboard"
												(click)="textToClipboard(contentFour + tuToken + contentFourTwo)"
												type="button"
											>
												<img src="../../../../assets/img/copy-to-clipboard.svg" alt="" />
											</button>
										</div>
										<!-- <pre appCopy><code>{{contentFour}}{{tuToken}}{{contentFourTwo}}</code></pre> -->
										<pre><code><p style="color:rgb(49, 121, 246)">&lt;widget-videolink <span style="color:rgb(239, 181, 73)">token</span>=<span style="color:rgb(36, 187, 58)">"{{tuToken}}"</span> <span style="color:rgb(239, 181, 73)">mail</span>=<span style="color:rgb(36, 187, 58)">"no-reply@mail.com"</span> <span style="color:rgb(239, 181, 73)">position</span>=<span style="color:rgb(36, 187, 58)">"left"</span>&lt;/widget-videolink&gt;</p></code></pre>
									</div>
									<p class="title">Último paso</p>
									<p class="p-content">
										3. Incluir los siguientes scripts debajo de la etiqueta personalizada widget-videolink y justo antes de la etiqueta de
										cierre del body
									</p>
									<p class="p-content">
										<strong>
											Importante: los mismos atributos que están en la etiqueta personalizada de widget-videolink y sus valores deben de ser
											agregados en la etiqueta de widget-vdeolink_, se deben replicar en el mismo orden dentro de la función start_ del
											últmo script agregado, tal como se muestra a continuación:
										</strong>
									</p>
									<div class="accordion-content_co">
										<div class="accordion-content_co_code-content">
											<button
												title="copy example source"
												aria-label="copy example source yo clipboard"
												(click)="textToClipboard(contentFive + tuToken + contentFiveTwo)"
												type="button"
											>
												<img src="../../../../assets/img/copy-to-clipboard.svg" alt="" />
											</button>
										</div>
										<!-- <pre appCopy><code>{{contentFive}}{{tuToken}}{{contentFiveTwo}}</code></pre> -->
										<pre><code><p style="color:rgb(49, 121, 246)">&lt;link <span style="color:rgb(239, 181, 73)">rel</span>=<span style="color:rgb(36, 187, 58)">"stylesheet"</span> <span style="color:rgb(239, 181, 73)">href</span>=<span><span style="color:rgb(36, 187, 58)">"{{assetsStyle}}"</span></span> /&gt; <br> &lt;script <span style="color:rgb(239, 181, 73)">src</span>=<span style="color:rgb(36, 187, 58)">"{{assetsJs}}"</span>&gt; <br> &lt;/script&gt; <br>&lt;script&gt;<br> window.vlGlobal.start(<br><span style="color:rgb(36, 187, 58)">"{{tuToken}}"</span>,<br><span style="color:rgb(36, 187, 58)">"no-reply@mail.com"</span>,<br><span style="color:rgb(36, 187, 58)">"left"</span>); <br>&lt;/script&gt;</p></code></pre>
									</div>
								</div>
							</div>
						</div>
					</mat-expansion-panel>
					<mat-expansion-panel (opened)="panelOpenState = true" (closed)="panelOpenState = false">
						<mat-expansion-panel-header>
							<mat-panel-title class="title-custom"> Ejemplo simple con página HTML (sitio público)</mat-panel-title>
						</mat-expansion-panel-header>
						<div class="accordion-content">
							<div class="accordion-content_co">
								<div class="accordion-content_co_code-content">
									<button
										title="copy example source"
										aria-label="copy example source yo clipboard"
										(click)="textToClipboard(contentFirst + tuToken + contentFirstTwo + tuToken + contentFirstThree)"
										type="button"
									>
										<img src="../../../../assets/img/copy-to-clipboard.svg" alt="" />
									</button>
								</div>
								<pre><code><p style="color:rgb(49, 121, 246)">&lt;!DOCTYPE <span style="color: rgb(239, 181, 73)">html</span>&gt; <br> &lt;html <span style="color:rgb(239, 181, 73)">lang</span>=<span style="color:rgb(36, 187, 58)">"es-ve"</span>&gt; <br> &lt;body&gt; <br> &lt;widget-videolink  <span style="color:rgb(239, 181, 73)">token</span>=<span style="color:rgb(36, 187, 58)">"{{tuToken}}"</span> <span style="color: rgb(239, 181, 73);">mail</span>=<span style="color:rgb(36, 187, 58)">"no-reply@mail.com"</span> <span style="color:rgb(239, 181, 73)">position</span>=<span style="color:rgb(36, 187, 58)">"left"</span>&gt;  &lt;/widget-videolink&gt; <br> &lt;link rel="stylesheet" <span style="color:rgb(239, 181, 73)">href</span>=<span style="color:rgb(36, 187, 58)">"{{this.assetsStyle}}"</span> /&gt; <br> &lt;script&gt; <span style="color:rgb(239, 181, 73)">src</span>=<span style="color:rgb(36, 187, 58)">"{{assetsJs}}"</span>&gt;&lt;/script&gt; <br> &lt;script&gt; <br> window.vlGlobal.start(<br><span style="color:rgb(36, 187, 58)">"{{tuToken}}",<br>"no-reply@mail.com", <br> "left"</span>); <br>&lt;/script&gt; <br> &lt;/body&gt; <br>  &lt;/html&gt;</p></code></pre>
							</div>
							<div></div>
						</div>
					</mat-expansion-panel>
					<mat-expansion-panel (opened)="panelOpenState = true" (closed)="panelOpenState = false">
						<mat-expansion-panel-header>
							<mat-panel-title class="title-custom"> Ejemplo simple con página HTML (sitio privado)</mat-panel-title>
						</mat-expansion-panel-header>
						<div class="accordion-content">
							<div class="accordion-content_co">
								<div class="accordion-content_co_code-content">
									<button
										title="copy example source"
										aria-label="copy example source yo clipboard"
										(click)="textToClipboard(contentSix + tuToken + contentSixTwo + tuToken + contentSixThree)"
										type="button"
									>
										<img src="../../../../assets/img/copy-to-clipboard.svg" alt="" />
									</button>
								</div>
								<!-- <pre appCopy><code>{{contentSix}}{{tuToken}}{{contentSixTwo}}{{tuToken}}{{contentSixThree}}</code></pre> -->
								<pre><code><p style="color:rgb(49, 121, 246)">&lt;!DOCTYPE <span style="color: rgb(239, 181, 73)">html</span>&gt; <br>&lt;html <span style="color:rgb(239, 181, 73)">lang</span>=<span style="color:rgb(36, 187, 58)">"es-ve"</span>&gt; <br> &lt;body&gt; <br> &lt;widget-videolink <span style="color:rgb(239, 181, 73)">token</span>=<span style="color:rgb(36, 187, 58)">"{{tuToken}}"</span> <span style="color:rgb(239, 181, 73)">mail</span>=<span style="color:rgb(36, 187, 58)">"no-reply@mail.com"</span> <span style="color:rgb(239, 181, 73)">position</span>=<span style="color:rgb(36, 187, 58)">"left"</span>&gt;&lt;/widget-videolink&gt; <br> &lt;link <span style="color:rgb(239, 181, 73)">rel</span>=<span style="color:rgb(36, 187, 58)">"stylesheet"</span> <span style="color:rgb(239, 181, 73)">href</span>=<span style="color:rgb(36, 187, 58)">"{{this.assetsStyle}}"</span> /&gt; <br> &lt;script <span style="color:rgb(239, 181, 73)">src</span>=<span style="color:rgb(36, 187, 58)">"{{this.assetsJs}}"</span>&gt; &lt;/script&gt; <br> &lt;script&gt; <br> window.vlGlobal.start(<br><span style="color:rgb(36, 187, 58)">"{{tuToken}}",<br>"no-reply@mail.com",<br>"left"</span>); <br>	&lt;/script&gt; <br> &lt;/body&gt; <br>&lt;/html&gt;</code></pre>
							</div>
							<div></div>
						</div>
					</mat-expansion-panel>
				</mat-accordion>
			</div>
		</div>
	</div>

	<div class="vl-intagration">
		<div class="vl-intagration-list">
			<vl-card>
				<vl-card-header
					titleCard="Instalación"
					descriptionCard="Únicamente es necesario incluir este script en tu página web o aplicación de manera sencilla. La configuración avanzada del widget se hará  de forma automática."
				>
				</vl-card-header>

				<vl-code-preview [code]="codes[0]">
					<code class="vl-code-sim"
						>&lt;<span class="vl-code-tag">script</span><span class="vl-code-att"> src </span>="<span class="vl-code-val">{{
							cdnContactCenter
						}}</span
						>"&gt;&lt;/<span class="vl-code-tag">script</span>&gt;</code
					>
				</vl-code-preview>
			</vl-card>
			<vl-card>
				<vl-card-header
					titleCard="Configuración"
					descriptionCard='En la vista donde quieres ver el widget, añade la etiqueta personalizada "videolink-wid" de VideoLink. 
                En la apertura de la etiqueta, asegúrate de incluir los atributos necesarios: "token" con el token de tu aplicación y "data" con el JSON Data VideoLink.'
				>
				</vl-card-header>
				<vl-code-preview [code]="codes[1]">
					<code class="vl-code-sim"
						>&lt;<span class="vl-code-tag">videolink-wid</span><span class="vl-code-att"> token</span>="<span class="vl-code-val"
							>APP_TOKEN</span
						>

						<span class="vl-code-att"> data</span>="<span class="vl-code-val">JSON_Data_VideoLink</span>"&gt; &lt;/<span class="vl-code-tag"
							>videolink-wid</span
						>&gt;</code
					>
				</vl-code-preview>
			</vl-card>
			<vl-card>
				<vl-card-header
					titleCard="JSON Data VideoLink"
					descriptionCard='Es un JSON convertido en texto, el JSON es un tipo de archivo que organiza la información de una manera específica. Este JSON debe contener cuatro elementos obligatorios: "clientId" (ID principal del contacto), "name" (nombre), "email" (correo electrónico) y "phone" (número de teléfono). Además, puede incluir un elemento opcional llamado "data", que puede contener más información si es necesario.'
				>
				</vl-card-header>
				<vl-code-preview [code]="codes[2]">
					<code class="vl-code-sim">
						&apos;&#123; <br />
						<span class="vl-code-spc"></span> &quot;<span class="vl-code-tag">clientId</span>&quot; : &quot;<span class="vl-code-val"
							>example@example.com</span
						>&quot; <br />
						<span class="vl-code-spc"></span> &quot;<span class="vl-code-tag">name</span>&quot; : &quot;<span class="vl-code-val"
							>John Dave</span
						>&quot; <br />
						<span class="vl-code-spc"></span> &quot;<span class="vl-code-tag">email</span>&quot; : &quot;<span class="vl-code-val"
							>example@example.com</span
						>&quot; <br />
						<span class="vl-code-spc"></span> &quot;<span class="vl-code-tag">phone</span>&quot; : &quot;<span class="vl-code-val"
							>12345678900</span
						>&quot; <br />
						<span class="vl-code-spc"></span> &quot;<span class="vl-code-tag">data</span>&quot; : &#123; <br />

						<span class="vl-code-spc"></span><span class="vl-code-spc"></span> &quot;<span class="vl-code-tag">DNI</span>&quot; :
						&quot;<span class="vl-code-val">12345676</span>&quot; <br />
						<span class="vl-code-spc"></span><span class="vl-code-spc"></span> &quot;<span class="vl-code-tag">Licence</span>&quot; :
						&quot;<span class="vl-code-val">Free</span>&quot; <br />
						<span class="vl-code-spc"></span> <span class="vl-code-spc"></span> //... <br />
						<span class="vl-code-spc"></span>

						&#125;&apos;

						<br />
						&#125;&apos;
					</code>
				</vl-code-preview>
			</vl-card>
			<vl-card>
				<vl-card-header
					titleCard="Web pública"
					descriptionCard="En los casos, donde se desconoce que contacto va a interactuar con el widget, deberas indicar en la configuración de la aplicación, que la misma es pública, de forma que el widget ignore la información del JSON Data Videolink y solicite la corroboración de identidad del contacto, en este caso puedes utilizar el siguiente JSON Data VideoLink."
				>
				</vl-card-header>
				<vl-code-preview [code]="codes[3]">
					<code class="vl-code-sim">
						&apos;&#123; <br />
						<span class="vl-code-spc"></span> &quot;<span class="vl-code-tag">clientId</span>&quot; : &quot;<span class="vl-code-val"
							>unknown@unknown.com</span
						>&quot;, <br />
						<span class="vl-code-spc"></span> &quot;<span class="vl-code-tag">name</span>&quot; : &quot;<span class="vl-code-val"
							>unknown</span
						>&quot;, <br />
						<span class="vl-code-spc"></span> &quot;<span class="vl-code-tag">email</span>&quot; : &quot;<span class="vl-code-val"
							>unknown@unknown.com</span
						>&quot;, <br />
						<span class="vl-code-spc"></span> &quot;<span class="vl-code-tag">phone</span>&quot; : &quot;<span class="vl-code-val"
							>00000000000</span
						>&quot; <br />
						&#125;&apos;</code
					>
				</vl-code-preview>
			</vl-card>
		</div>
		<div class="vl-integration-example">
			<vl-card>
				<vl-card-header titleCard="Ejemplo simple con página HTML"></vl-card-header>
				<vl-code-preview [code]="codes[4]">
					<code class="vl-code-sim"> &lt;!DOCTYPE <span class="vl-code-att"> html </span>&gt; </code><br />
					<code class="vl-code-sim"> &lt;html&gt; </code><br />
					<span class="vl-code-spc"></span><code class="vl-code-sim"> &lt;head&gt; </code><br />
					<span class="vl-code-spc"></span><span class="vl-code-spc"></span><code class="vl-code-sim"> &lt;title&gt; </code>
					<code class="vl-code-sim"> Página de ejemplo </code>
					<code class="vl-code-sim"> &lt;/title&gt; </code><br />
					<span class="vl-code-spc"></span><code class="vl-code-sim"> &lt;/head&gt; </code><br />
					<span class="vl-code-spc"></span><code class="vl-code-sim"> &lt;body&gt; </code><br />

					<span class="vl-code-spc"></span><span class="vl-code-spc"></span>

					<code class="vl-code-sim"
						>&lt;<span class="vl-code-tag">videolink-wid</span><span class="vl-code-att"> token</span>='<span class="vl-code-val"
							>APP_TOKEN</span
						>'

						<span class="vl-code-att"> data</span>=<code class="vl-code-sim"
							>&apos;&#123;<br />
							<span class="vl-code-spc"></span><span class="vl-code-spc"></span><span class="vl-code-spc"></span> &quot;<span
								class="vl-code-tag"
								>clientId</span
							>&quot; : &quot;<span class="vl-code-val">unknown@unknown.com</span>&quot;, <br />
							<span class="vl-code-spc"></span><span class="vl-code-spc"></span><span class="vl-code-spc"></span> &quot;<span
								class="vl-code-tag"
								>name</span
							>&quot; : &quot;<span class="vl-code-val">unknown</span>&quot;, <br />
							<span class="vl-code-spc"></span><span class="vl-code-spc"></span><span class="vl-code-spc"></span> &quot;<span
								class="vl-code-tag"
								>email</span
							>&quot; : &quot;<span class="vl-code-val">unknown@unknown.com</span>&quot;, <br />
							<span class="vl-code-spc"></span><span class="vl-code-spc"></span><span class="vl-code-spc"></span> &quot;<span
								class="vl-code-tag"
								>phone</span
							>&quot; : &quot;<span class="vl-code-val">00000000000</span>&quot; <br /><span class="vl-code-spc"></span>
							<span class="vl-code-spc"></span><span class="vl-code-spc"></span>&#125;&apos;</code
						>&gt; &lt;/<span class="vl-code-tag">videolink-wid</span>&gt;</code
					>

					<br />
					<br />

					<span class="vl-code-spc"></span><span class="vl-code-spc"></span
					><code class="vl-code-sim"
						>&lt;<span class="vl-code-tag">script</span><span class="vl-code-att"> src </span>="<span class="vl-code-val">{{
							cdnContactCenter
						}}</span
						>"&gt;&lt;/<span class="vl-code-tag">script</span>&gt;</code
					><br />

					<span class="vl-code-spc"></span><code class="vl-code-sim"> &lt;/body&gt; </code><br />
					<code class="vl-code-sim"> &lt;/html&gt; </code>

					<!-- {{ '

					<!DOCTYPE html>
					<html>
						<head>
							<title>Página de ejemplo</title>
						</head>
						<body>
							<videolink-wid token="TU_TOKEN"> </videolink-wid>
							<script src="https://s3.amazonaws.com/cdn1.videolink.app/videolink.js"></script>
						</body>
					</html>
					' }} -->
				</vl-code-preview>
			</vl-card>
		</div>
	</div>
</main>
