<main>
	<app-title-section [title]="contentTitle" [description]="contentDescription"></app-title-section>

	<div class="limiter">
		<div class="img-container">
            <img
            src="../../../../assets/img/integration-desktop.png"
            alt="imagen donde se puede visualizar las diferentes presentaciones del contact center"
            *ngIf="getScreenWidth > 820"
        />
        <img
            src="../../../../assets/img/integration-mobile.png"
            alt="imagen donde se puede visualizar las diferentes presentaciones del contact center"
            *ngIf="getScreenWidth < 820"
        />
		</div>
		<div class="accordion-container">
			<div class="subtitle-container">
				<h3 class="subtitle-container__content">Configuraciones</h3>
			</div>
			<div class="accordion-container__content">
				<mat-accordion>
					<mat-expansion-panel>
						<mat-expansion-panel-header>
							<mat-panel-title class="title-custom"> Instalación y configuración </mat-panel-title>
						</mat-expansion-panel-header>
						<div class="accordion-content">
							<div class="accordion-content_contenido">
								<header>
									<p class="p-content">
										Tickets para solicitar una atención en cualquier sitio web con un agente de su empresa registrado en
										VideoLink
									</p>
									<h3>Pasos para configurar Tickets en tu sitio web</h3>
								</header>
								<div class="accordion-content_contenido_steps">
									<h5 class="title">Gestiona tus aplicaciones</h5>
									<p class="p-content" *ngIf="listItemsApps.length == 0">
										En estos momentos aún no cuentas con una aplicación de VideoLink en tu empresa, crea una aplicación.
									</p>
									<p class="p-content" *ngIf="listItemsApps.length > 0">Gestiona tus aplicaciones de VideoLink.</p>
									<a [routerLink]="['/dashboard/attentions/aplications']" class="link-apps">Gestionar aplicaciones</a>
									<p class="title">Genera el selector</p>
									<form [formGroup]="formToken">
										<div class="input-field">
											<mat-form-field appearence="none" style="height: 39px !important">
												<div class="input-context" style="margin-top: -20px !important">
													<mat-select formControlName="tokenItem">
														<mat-option value="0" (click)="changeToken('0')">
															Selecciona la aplicación
														</mat-option>
														<mat-option
															*ngFor="let itemA of listItemsApps"
															(click)="changeToken(itemA._id)"
															[value]="itemA._id"
														>
															{{ itemA.name }}
														</mat-option>
													</mat-select>
												</div>
											</mat-form-field>
										</div>
										<div class="reload-container">
											<button (click)="initAll()" class="button-reload">Recargar</button>
										</div>
									</form>
									<div class="copy-container">
										<div class="data-field">
											<div class="input-field">
												<input type="text" value="{{ tuToken }}" class="input-copy" />
												<app-copy-button [contentCopy]="tuToken"></app-copy-button>
											</div>
										</div>
									</div>

									<h4 class="title">Instalación y configuración</h4>

									<p class="p-content">
										Dentro del archivo “index.html” de tu sitio web en el directorio raíz antes de la etiqueta de cierre
										del <strong>body</strong>, se debe incluir la etiqueta personalizada de tickets de VideoLink. En la
										etiqueta de apertura personalizada del tickets de VideoLink, se debe de incluir el atributo
										obligatorio token y dentro de las comillas dobles del mismo, el valor de la semilla (token).
									</p>
									<div class="accordion-content_co">
										<div class="accordion-content_co_code-content">
											<button
												title="copy example source"
												aria-label="copy example source yo clipboard"
												(click)="textToClipboard(contentSecond + tuToken + contentSecondTwo)"
												type="button"
											>
												<img src="../../../../assets/img/copy-to-clipboard.svg" alt="" />
											</button>
										</div>
										<pre appCopy><code>{{contentSecond}}{{tuToken}}{{contentSecondTwo}}</code></pre>
									</div>
									<h5 class="title">Opcional</h5>
									<p class="p-content">
										2. Opcionalmente, si desea que su tickets omita el formulario, puede especificar dos atributos
										adicionales:
									</p>
									<p class="p-content">
										2.1 Atributo mail: dentro de las dobles comillas del mismo, el correo por defecto para el momento de
										pedir una atención.
									</p>
									<div class="accordion-content_co">
										<div class="accordion-content_co_code-content">
											<button
												title="copy example source"
												aria-label="copy example source yo clipboard"
												(click)="textToClipboard(contentThree + tuToken + contentThreeTwo)"
												type="button"
											>
												<img src="../../../../assets/img/copy-to-clipboard.svg" alt="" />
											</button>
										</div>
										<pre appCopy><code>{{contentThree}}{{tuToken}}{{contentThreeTwo}}</code></pre>
									</div>
									<p class="p-content">
										2.2 Atributo position: dentro de las dobles comillas del mismo, se indica la posición en la que se
										ubicará el tickets dentro de su sitio web (en la esquina inferior izquierda o esquina inferior
										derecha). En caso de que no agregue este atributo, el tickets se ubicará en la esquina inferior
										derecha.
									</p>
									<div class="accordion-content_co">
										<div class="accordion-content_co_code-content">
											<button
												title="copy example source"
												aria-label="copy example source yo clipboard"
												(click)="textToClipboard(contentFour + tuToken + contentFourTwo)"
												type="button"
											>
												<img src="../../../../assets/img/copy-to-clipboard.svg" alt="" />
											</button>
										</div>
										<pre appCopy><code>{{contentFour}}{{tuToken}}{{contentFourTwo}}</code></pre>
									</div>
									<p class="title">Último paso</p>
									<p class="p-content">
										3. Incluir los siguientes scripts debajo de la etiqueta personalizada tickets-videolink y justo antes
										de la etiqueta de cierre del body
									</p>
									<p class="p-content">
										<strong>
											Importante: los mismos atributos que están en la etiqueta personalizada de tickets-videolink y
											sus valores deben de ser agregados en la etiqueta de tickets-vdeolink_, se deben replicar en el
											mismo orden dentro de la función start_ del últmo script agregado, tal como se muestra a
											continuación:
										</strong>
									</p>
									<div class="accordion-content_co">
										<div class="accordion-content_co_code-content">
											<button
												title="copy example source"
												aria-label="copy example source yo clipboard"
												(click)="textToClipboard(contentFive + tuToken + contentFiveTwo)"
												type="button"
											>
												<img src="../../../../assets/img/copy-to-clipboard.svg" alt="" />
											</button>
										</div>
										<pre appCopy><code>{{contentFive}}{{tuToken}}{{contentFiveTwo}}</code></pre>
									</div>
								</div>
							</div>
						</div>
					</mat-expansion-panel>
					<mat-expansion-panel (opened)="panelOpenState = true" (closed)="panelOpenState = false">
						<mat-expansion-panel-header>
							<mat-panel-title class="title-custom"> Ejemplo simple con página HTML (sitio público)</mat-panel-title>
						</mat-expansion-panel-header>
						<div class="accordion-content">
							<div class="accordion-content_co">
								<div class="accordion-content_co_code-content">
									<button
										title="copy example source"
										aria-label="copy example source yo clipboard"
										(click)="textToClipboard(contentFirst + tuToken + contentFirstTwo + tuToken + contentFirstThree)"
										type="button"
									>
										<img src="../../../../assets/img/copy-to-clipboard.svg" alt="" />
									</button>
								</div>
								<pre
									appCopy
								><code>{{contentFirst}}{{tuToken}}{{contentFirstTwo}}{{tuToken}}{{contentFirstThree}}</code></pre>
							</div>
							<div></div>
						</div>
					</mat-expansion-panel>
					<mat-expansion-panel (opened)="panelOpenState = true" (closed)="panelOpenState = false">
						<mat-expansion-panel-header>
							<mat-panel-title class="title-custom"> Ejemplo simple con página HTML (sitio privado)</mat-panel-title>
						</mat-expansion-panel-header>
						<div class="accordion-content">
							<div class="accordion-content_co">
								<div class="accordion-content_co_code-content">
									<button
										title="copy example source"
										aria-label="copy example source yo clipboard"
										(click)="textToClipboard(contentSix + tuToken + contentSixTwo + tuToken + contentSixThree)"
										type="button"
									>
										<img src="../../../../assets/img/copy-to-clipboard.svg" alt="" />
									</button>
								</div>
								<pre appCopy><code>{{contentSix}}{{tuToken}}{{contentSixTwo}}{{tuToken}}{{contentSixThree}}</code></pre>
							</div>
							<div></div>
						</div>
					</mat-expansion-panel>
				</mat-accordion>
			</div>
		</div>
	</div>
</main>
