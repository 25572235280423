import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable, EMPTY, Subject, tap, of, BehaviorSubject, filter } from 'rxjs';
import { TourService } from '@services/components/tour.service';
import { Router } from '@angular/router';

@Injectable()
export class TourInterceptor implements HttpInterceptor {
	private isTourActive: boolean = false;

	constructor(private _toutSrc: TourService) {
		this._toutSrc.isActive.pipe(tap((status) => (this.isTourActive = status))).subscribe();
	}

	intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
		const evaluation = [
			{
				url: '/chats/v2/list',
				response: 'attentionsList.json',
			},
			{
				url: '/chat/v2/c5e90b103d4fffadf51a80ce',
				response: 'detailAttentions.json',
			},
			{
				url: '/chats/v2/list/queue',
				response: 'attentionsQueued.json',
			},
			{
				url: '/chats/v2/list/current',
				response: 'attentionsCurrent.json',
			},
			{
				url: '/users/v2/list',
				response: 'usersList.json',
			},
			{
				url: '/groups/v2/list',
				response: 'groupsList.json',
			},
			{
				url: '/ability/v2/list',
				response: 'abilityList.json',
			},
			{
				url: '/app/v2/list',
				response: 'appList.json',
			},
		];
		let match = false;
		let url;

		evaluation.forEach((item) => {
			if (request.url.includes(item.url)) {
				match = true;
				url = `/assets/tour/${item.response}`;
			}
		});

		const clonne = request.clone({
			url,
		});

		return match && this.isTourActive ? next.handle(clonne) : next.handle(request);
	}
}
