import { filter, finalize, Subject, takeUntil, tap } from 'rxjs';
import { Component, OnInit, OnDestroy, AfterViewInit, AfterViewChecked } from '@angular/core';
import { TourService } from '@services/components/tour.service';
import { DimensionsScreenService } from '@services/components/dimensions-screen.service';
import { trigger, state, style, animate, transition } from '@angular/animations';
import { NavigationCancel, NavigationEnd, NavigationError, Router } from '@angular/router';

@Component({
	selector: 'Tour',
	templateUrl: './tour.component.html',
	styleUrls: ['./tour.component.scss'],
	animations: [
		trigger('Steps', [
			state(
				'show',
				style({
					height: 'auto',
				})
			),
			state(
				'hidden',
				style({
					height: 0,
				})
			),
			transition('show => hidden', [animate('1s 100ms ease')]),
			transition('hidden => show', [animate('1s 100ms ease')]),
		]),
	],
})
export class TourComponent implements OnInit, OnDestroy, AfterViewInit, AfterViewChecked {
	public isTourActive: boolean = false;
	public modalInView: boolean = false;
	public lastStep: number = 0;
	private _stepActiveOfModule: any = { title: '', text: '', path: '', clipPath: {} };
	private _unSuscribeAll: Subject<any> = new Subject();

	public modalInfo: any = {};

	constructor(private _tourSrc: TourService, private _dimensionsScreenScr: DimensionsScreenService, private _router: Router) {
		this.buildModalInfo();
		this._dimensionsScreenScr.change
			.pipe(
				takeUntil(this._unSuscribeAll),
				tap(({ width, height }) => {})
			)
			.subscribe();
		this._tourSrc.isActive
			.pipe(
				takeUntil(this._unSuscribeAll),
				tap((status) => (this.isTourActive = status))
			)
			.subscribe();

		this._tourSrc.tourActive
			.pipe(
				takeUntil(this._unSuscribeAll),
				tap((tourActive) => {
					if (!this._tourSrc.isActive) return tourActive;
					const { module, step } = tourActive;
					if (module && step) {
						const contentData = this._tourSrc.dataTour.find((item) => item.module === module);
						if (contentData) {
							const stepsOfModule = contentData?.steps;

							this._stepActiveOfModule = stepsOfModule[step - 1];
							this.lastStep = step;

							this.isTourActive && this._router.navigateByUrl(this._stepActiveOfModule.path);
							this.buildViewOfStep();
						}
					}
				})
			)
			.subscribe();

		this._tourSrc.actions
			.pipe(
				takeUntil(this._unSuscribeAll),
				tap(({ action, config }) => {
					switch (action) {
						case 'showModal':
							this.showModal(config);
							this.modalInView = true;
							break;
						case 'hiddenModal':
							this.modalInView = false;
							if (config.notification) {
								let msg = '';

								switch (this.modalInfo.type) {
									case 'start':
										msg = 'Accede aquí para empezar el tutorial.';
										break;
									case 'restart':
										msg = 'Accede aquí para continuar el tutorial o reiniciarlo.';
										break;
									case 'exit':
										msg = 'Accede aquí para continuar el tutorial o reiniciarlo.';
										break;
									case 'finish':
										msg = 'Accede aquí para ver el tutorial nuevamente.';
										break;
								}
								this._tourSrc.actions.emit({ action: 'showTooltipTour', config: { msg } });
								this.buildModalInfo();
							}
							break;

						default:
							break;
					}
				})
			)
			.subscribe();

	}

	ngOnInit(): void {}

	ngOnDestroy(): void {
		this._unSuscribeAll.next({});
		this._unSuscribeAll.complete();
	}

	ngAfterViewInit(): void {
		this.buildViewOfStep();
	}
	ngAfterViewChecked(): void {
		this.buildViewOfStep();
	}

	exitTour() {
		this._tourSrc.hiddenTour();
	}

	buildViewOfStep() {
		if (!this.isTourActive) return;
		if (!this._stepActiveOfModule.clipPath) return this.cutOverlay([]);

		const { clipPath } = this._stepActiveOfModule;
		const { resourcesID = [], cut } = clipPath;
		const selectorOne = resourcesID[0] ?? null;
		const selectorTwo = resourcesID[1] ?? null;

		const resourceOne: HTMLElement | null = selectorOne ? document.querySelector(`${selectorOne}`) : null;
		const resourceTwo: HTMLElement | null = selectorTwo ? document.querySelector(`${selectorTwo}`) : null;

		if (
			((cut === 0 || cut === 2 || cut === 4 || cut === 5 || cut === 6) && !resourceOne) ||
			((cut === 1 || cut === 3 || cut === 7) && (!resourceOne || !resourceTwo))
		)
			return this.cutOverlay([]);

		this._tourSrc.actions.next({
			action: 'openAccordeonSubMenu',
			config: { indexAccordeonActive: clipPath.indexAccordeonActive ?? null },
		});

		let _points: string[] = [];

		if (cut === 0) {
			const { points } = this.generatePointTriggerNavMenu({
				resource: (resourceOne as HTMLElement).getBoundingClientRect(),
			});

			_points = points;
		}

		if (cut === 1) {
			let { points } = this.generatePointTriggerNavMenuSubmenu({
				resource_one: (resourceOne as HTMLElement).getBoundingClientRect(),
				resource_two: (resourceTwo as HTMLElement).getBoundingClientRect(),
			});
			_points = points;
		}
		if (cut === 2) {
			let { points } = this.generatePointTriggerNavSubmenuAccordeon({
				resource: (resourceOne as HTMLElement).getBoundingClientRect(),
			});
			_points = points;
		}
		if (cut === 3) {
			let { points } = this.generatePointTriggerNavSubmenuAccordeonTable({
				resource_one: (resourceOne as HTMLElement).getBoundingClientRect(),
				resource_two: (resourceTwo as HTMLElement).getBoundingClientRect(),
			});
			_points = points;
		}
		if (cut === 4) {
			let { points } = this.generatePointBtn({
				resource: (resourceOne as HTMLElement).getBoundingClientRect(),
			});
			_points = points;
		}
		if (cut === 5) {
			let { points } = this.generatePointDetails({
				resource: (resourceOne as HTMLElement).getBoundingClientRect(),
			});
			_points = points;
		}
		if (cut === 6) {
			let { points } = this.generatePointTriggerNavSubmenu({
				resource: (resourceOne as HTMLElement).getBoundingClientRect(),
			});
			_points = points;
		}
		if (cut === 7) {
			let { points } = this.generatePointTriggerNavSubmenuTable({
				resource_one: (resourceOne as HTMLElement).getBoundingClientRect(),
				resource_two: (resourceTwo as HTMLElement).getBoundingClientRect(),
			});
			_points = points;
		}

		_points.length === 0 ? this.cutOverlay([]) : this.cutOverlay(_points);
	}

	generatePointTriggerNavMenu({
		resource = {
			x: 0,
			y: 0,
			width: 0,
			height: 0,
			top: 0,
			right: 0,
			bottom: 0,
			left: 0,
		},
	}: {
		resource: { x: number; y: number; width: number; height: number; top: number; right: number; bottom: number; left: number };
	}) {
		let points: string[] = [];

		points = [...points, `0px ${resource.y + resource.height / 2}px`]; //! Inicio
		points = [...points, `${resource.x}px ${resource.y + resource.height / 2}px`]; //! Desplazamieto

		//Inicio del corte para el Trigger Nav
		points = [...points, `${resource.x}px ${resource.bottom}px`]; //! ABAJO IZQUIERDA.
		points = [...points, `${resource.right}px ${resource.bottom}px`]; //! ABAJO DERECHA.

		points = [...points, `${resource.right}px ${resource.y + resource.height / 2}px`]; //! mitad de la figura.
		points = [...points, `${resource.right}px ${resource.y + resource.height / 2}px`]; //! Retorno a mitad de la figura.

		points = [...points, `${resource.right}px ${resource.y}px`]; //! ARRIBA DERECHA
		points = [...points, `${resource.left}px ${resource.y}px`]; //! ARRIBA IZQUIEDA

		//Fin del corte para el Trigger Nav
		points = [...points, `${resource.x}px ${resource.y + resource.height / 2}px`]; //! Fin Desplazamieto
		points = [...points, `0px ${resource.y + resource.height / 2}px`]; //! Fin

		return { points };
	}

	generatePointTriggerNavMenuSubmenu({
		resource_one = {
			x: 0,
			y: 0,
			width: 0,
			height: 0,
			top: 0,
			right: 0,
			bottom: 0,
			left: 0,
		},
		resource_two = {
			x: 0,
			y: 0,
			width: 0,
			height: 0,
			top: 0,
			right: 0,
			bottom: 0,
			left: 0,
		},
	}: {
		resource_one: { x: number; y: number; width: number; height: number; top: number; right: number; bottom: number; left: number };
		resource_two: { x: number; y: number; width: number; height: number; top: number; right: number; bottom: number; left: number };
	}) {
		let points: string[] = [];

		points = [...points, `0px ${resource_one.y + resource_one.height / 2}px`]; //! Inicio
		points = [...points, `${resource_one.x}px ${resource_one.y + resource_one.height / 2}px`]; //! Desplazamieto

		//Inicio del corte para el trigger Nav
		points = [...points, `${resource_one.x}px ${resource_one.bottom}px`]; //! ABAJO IZQUIERDA.
		points = [...points, `${resource_one.right}px ${resource_one.bottom}px`]; //! ABAJO DERECHA.
		points = [...points, `${resource_one.right}px ${resource_one.y + resource_one.height / 2}px`]; //! mitad de la figura.

		//Inicio del corte de la Flecha
		points = [...points, `${resource_two.left + 5}px ${resource_one.y + resource_one.height / 2}px`]; //! Desplazamiento
		points = [...points, `${resource_two.left + 10}px ${resource_one.y + resource_one.height / 2 + 10}px`]; //! Parte Inforior de la fecha

		//Inicio del corte para la tabla
		points = [...points, `${resource_two.left + 10}px ${resource_two.y + resource_one.height / 2}px`]; //! Desplazamiento

		points = [...points, `${resource_two.left + 10}px ${resource_two.y + resource_two.height}px`]; //! ABAJO IZQUIERDA.
		points = [...points, `${resource_two.right}px ${resource_two.y + resource_two.height}px`]; //! ABAJO DERECHA.

		points = [...points, `${resource_two.right}px ${resource_two.y + resource_two.height / 2}px`]; //! mitad de la figura.
		points = [...points, `${resource_two.right}px ${resource_two.y + resource_two.height / 2}px`]; //! Retorno de la figura.

		points = [...points, `${resource_two.right}px ${resource_two.top}px`]; //! TOP DERECHA.
		points = [...points, `${resource_two.left + 10}px ${resource_two.top}px`]; //! TOP IZQUIERDA.

		points = [...points, `${resource_two.left + 10}px ${resource_two.y + resource_one.height / 2}px`]; //! Fin Desplazamiento
		//Fin del corte para la tabla

		points = [...points, `${resource_two.left + 10}px ${resource_one.y + resource_one.height / 2 - 10}px`]; //! Parte Superior de la fecha
		points = [...points, `${resource_two.left + 5}px ${resource_one.y + resource_one.height / 2}px`]; //! Desplazamiento

		// Fin del corte de la Flecha

		points = [...points, `${resource_one.right}px ${resource_one.y + resource_one.height / 2}px`]; //! Retorno a mitad de la figura.
		points = [...points, `${resource_one.right}px ${resource_one.y}px`]; //! ARRIBA DERECHA
		points = [...points, `${resource_one.left}px ${resource_one.y}px`]; //! ARRIBA IZQUIEDA

		//Fin del corte del trigger nav
		points = [...points, `${resource_one.x}px ${resource_one.y + resource_one.height / 2}px`]; //! Fin Desplazamieto
		points = [...points, `0px ${resource_one.y + resource_one.height / 2}px`]; //! Fin

		return { points };
	}

	generatePointTriggerNavSubmenu({
		resource = {
			x: 0,
			y: 0,
			width: 0,
			height: 0,
			top: 0,
			right: 0,
			bottom: 0,
			left: 0,
		},
	}: {
		resource: { x: number; y: number; width: number; height: number; top: number; right: number; bottom: number; left: number };
	}) {
		let points: string[] = [];

		points = [...points, `0px ${resource.y + resource.height / 2}px`]; //! Inicio
		points = [...points, `${resource.x}px ${resource.y + resource.height / 2}px`]; //! Desplazamieto

		//Inicio del corte para el Trigger Nav
		points = [...points, `${resource.x}px ${resource.bottom}px`]; //! ABAJO IZQUIERDA.
		points = [...points, `${resource.right}px ${resource.bottom}px`]; //! ABAJO DERECHA.

		points = [...points, `${resource.right}px ${resource.y + resource.height / 2}px`]; //! mitad de la figura.
		points = [...points, `${resource.right}px ${resource.y + resource.height / 2}px`]; //! Retorno a mitad de la figura.

		points = [...points, `${resource.right}px ${resource.y}px`]; //! ARRIBA DERECHA
		points = [...points, `${resource.left}px ${resource.y}px`]; //! ARRIBA IZQUIEDA

		//Fin del corte para el Trigger Nav
		points = [...points, `${resource.x}px ${resource.y + resource.height / 2}px`]; //! Fin Desplazamieto
		points = [...points, `0px ${resource.y + resource.height / 2}px`]; //! Fin

		return { points };
	}
	generatePointTriggerNavSubmenuAccordeon({
		resource = {
			x: 0,
			y: 0,
			width: 0,
			height: 0,
			top: 0,
			right: 0,
			bottom: 0,
			left: 0,
		},
	}: {
		resource: { x: number; y: number; width: number; height: number; top: number; right: number; bottom: number; left: number };
	}) {
		let points: string[] = [];

		points = [...points, `0px ${resource.y + resource.height / 2}px`]; //! Inicio
		points = [...points, `${resource.x - 20}px ${resource.y + resource.height / 2}px`]; //! Desplazamieto

		//Inicio del corte para el Trigger Nav
		points = [...points, `${resource.left - 20}px ${resource.bottom + 10}px`]; //! ABAJO IZQUIERDA.
		points = [...points, `${resource.right + 50}px ${resource.bottom + 10}px`]; //! ABAJO DERECHA.

		points = [...points, `${resource.right + 50}px ${resource.y + resource.height / 2}px`]; //! mitad de la figura.
		points = [...points, `${resource.right + 50}px ${resource.y + resource.height / 2}px`]; //! Retorno a mitad de la figura.

		points = [...points, `${resource.right + 50}px ${resource.y - 10}px`]; //! ARRIBA DERECHA
		points = [...points, `${resource.left - 20}px ${resource.y - 10}px`]; //! ARRIBA IZQUIEDA

		//Fin del corte para el Trigger Nav
		points = [...points, `${resource.x - 20}px ${resource.y + resource.height / 2}px`]; //! Fin Desplazamieto
		points = [...points, `0px ${resource.y + resource.height / 2}px`]; //! Fin

		return { points };
	}
	generatePointTriggerNavSubmenuAccordeonTable({
		resource_one = {
			x: 0,
			y: 0,
			width: 0,
			height: 0,
			top: 0,
			right: 0,
			bottom: 0,
			left: 0,
		},
		resource_two = {
			x: 0,
			y: 0,
			width: 0,
			height: 0,
			top: 0,
			right: 0,
			bottom: 0,
			left: 0,
		},
	}: {
		resource_one: { x: number; y: number; width: number; height: number; top: number; right: number; bottom: number; left: number };
		resource_two: { x: number; y: number; width: number; height: number; top: number; right: number; bottom: number; left: number };
	}) {
		let points: string[] = [];

		points = [...points, `0px ${resource_one.y + resource_one.height / 2}px`]; //! Inicio
		points = [...points, `${resource_one.x - 20}px ${resource_one.y + resource_one.height / 2}px`]; //! Desplazamieto

		//Inicio del corte para el trigger Nav
		points = [...points, `${resource_one.left - 20}px ${resource_one.bottom + 10}px`]; //! ABAJO IZQUIERDA.
		points = [...points, `${resource_one.right + 50}px ${resource_one.bottom + 10}px`]; //! ABAJO DERECHA.
		points = [...points, `${resource_one.right + 50}px ${resource_one.y + resource_one.height / 2}px`]; //! mitad de la figura.

		//Inicio del corte de la Flecha
		points = [...points, `${resource_two.left + 15}px ${resource_one.y + resource_one.height / 2}px`]; //! Desplazamiento
		points = [...points, `${resource_two.left + 30}px ${resource_one.y + resource_one.height / 2 + 20}px`]; //! Parte Inforior de la fecha

		//Inicio del corte para la tabla
		points = [...points, `${resource_two.left + 30}px ${resource_two.y + resource_one.height / 2}px`]; //! Desplazamiento

		points = [...points, `${resource_two.left + 30}px ${resource_two.y + resource_two.height}px`]; //! ABAJO IZQUIERDA.
		points = [...points, `${resource_two.right - 30}px ${resource_two.y + resource_two.height}px`]; //! ABAJO DERECHA.

		points = [...points, `${resource_two.right - 30}px ${resource_two.y + resource_two.height / 2}px`]; //! mitad de la figura.
		points = [...points, `${resource_two.right - 30}px ${resource_two.y + resource_two.height / 2}px`]; //! Retorno de la figura.

		points = [...points, `${resource_two.right - 30}px ${resource_two.top + 50}px`]; //! TOP DERECHA.
		points = [...points, `${resource_two.left + 30}px ${resource_two.top + 50}px`]; //! TOP IZQUIERDA.

		points = [...points, `${resource_two.left + 30}px ${resource_two.y + resource_one.height / 2}px`]; //! Fin Desplazamiento
		//Fin del corte para la tabla

		points = [...points, `${resource_two.left + 30}px ${resource_one.y + resource_one.height / 2 - 20}px`]; //! Parte Superior de la fecha
		points = [...points, `${resource_two.left + 15}px ${resource_one.y + resource_one.height / 2}px`]; //! Desplazamiento

		// Fin del corte de la Flecha

		points = [...points, `${resource_one.right + 50}px ${resource_one.y + resource_one.height / 2}px`]; //! Retorno a mitad de la figura.
		points = [...points, `${resource_one.right + 50}px ${resource_one.y - 10}px`]; //! ARRIBA DERECHA
		points = [...points, `${resource_one.left - 20}px ${resource_one.y - 10}px`]; //! ARRIBA IZQUIEDA

		//Fin del corte del trigger nav
		points = [...points, `${resource_one.x - 20}px ${resource_one.y + resource_one.height / 2}px`]; //! Fin Desplazamieto
		points = [...points, `0px ${resource_one.y + resource_one.height / 2}px`]; //! Fin

		return { points };
	}

	generatePointBtn({
		resource = {
			x: 0,
			y: 0,
			width: 0,
			height: 0,
			top: 0,
			right: 0,
			bottom: 0,
			left: 0,
		},
	}: {
		resource: { x: number; y: number; width: number; height: number; top: number; right: number; bottom: number; left: number };
	}) {
		let points: string[] = [];

		points = [...points, `0px ${resource.y + resource.height / 2}px`]; //! Inicio
		points = [...points, `${resource.x - 5}px ${resource.y + resource.height / 2}px`]; //! Desplazamieto

		// Inicio del corte para el boton
		points = [...points, `${resource.x - 5}px ${resource.bottom + 5}px`]; //! ABAJO IZQUIERDA.
		points = [...points, `${resource.right + 5}px ${resource.bottom + 5}px`]; //! ABAJO DERECHA.

		points = [...points, `${resource.right + 5}px ${resource.y + resource.height / 2}px`]; //! mitad de la figura.
		points = [...points, `${resource.right + 5}px ${resource.y + resource.height / 2}px`]; //! Retorno a mitad de la figura.

		points = [...points, `${resource.right + 5}px ${resource.y - 5}px`]; //! ARRIBA DERECHA
		points = [...points, `${resource.left - 5}px ${resource.y - 5}px`]; //! ARRIBA IZQUIEDA

		// Fin del corte para el boton

		points = [...points, `${resource.x - 5}px ${resource.y + resource.height / 2}px`]; //! Fin Desplazamieto
		points = [...points, `0px ${resource.y + resource.height / 2}px`]; //! Fin

		return { points };
	}
	generatePointTriggerNavSubmenuTable({
		resource_one = {
			x: 0,
			y: 0,
			width: 0,
			height: 0,
			top: 0,
			right: 0,
			bottom: 0,
			left: 0,
		},
		resource_two = {
			x: 0,
			y: 0,
			width: 0,
			height: 0,
			top: 0,
			right: 0,
			bottom: 0,
			left: 0,
		},
	}: {
		resource_one: { x: number; y: number; width: number; height: number; top: number; right: number; bottom: number; left: number };
		resource_two: { x: number; y: number; width: number; height: number; top: number; right: number; bottom: number; left: number };
	}) {
		let points: string[] = [];

		points = [...points, `0px ${resource_one.y + resource_one.height / 2}px`]; //! Inicio
		points = [...points, `${resource_one.x}px ${resource_one.y + resource_one.height / 2}px`]; //! Desplazamieto

		//Inicio del corte para el trigger Nav
		points = [...points, `${resource_one.x}px ${resource_one.bottom}px`]; //! ABAJO IZQUIERDA.
		points = [...points, `${resource_one.right}px ${resource_one.bottom}px`]; //! ABAJO DERECHA.
		points = [...points, `${resource_one.right}px ${resource_one.y + resource_one.height / 2}px`]; //! mitad de la figura.

		//Inicio del corte de la Flecha
		points = [...points, `${resource_two.left + 15}px ${resource_one.y + resource_one.height / 2}px`]; //! Desplazamiento
		points = [...points, `${resource_two.left + 30}px ${resource_one.y + resource_one.height / 2 + 20}px`]; //! Parte Inforior de la fecha

		//Inicio del corte para la tabla
		points = [...points, `${resource_two.left + 30}px ${resource_two.y + resource_one.height / 2}px`]; //! Desplazamiento

		points = [...points, `${resource_two.left + 30}px ${resource_two.y + resource_two.height}px`]; //! ABAJO IZQUIERDA.
		points = [...points, `${resource_two.right - 30}px ${resource_two.y + resource_two.height}px`]; //! ABAJO DERECHA.

		points = [...points, `${resource_two.right - 30}px ${resource_two.y + resource_two.height / 2}px`]; //! mitad de la figura.
		points = [...points, `${resource_two.right - 30}px ${resource_two.y + resource_two.height / 2}px`]; //! Retorno de la figura.

		points = [...points, `${resource_two.right - 30}px ${resource_two.top + 50}px`]; //! TOP DERECHA.
		points = [...points, `${resource_two.left + 30}px ${resource_two.top + 50}px`]; //! TOP IZQUIERDA.

		points = [...points, `${resource_two.left + 30}px ${resource_two.y + resource_one.height / 2}px`]; //! Fin Desplazamiento
		//Fin del corte para la tabla

		points = [...points, `${resource_two.left + 30}px ${resource_one.y + resource_one.height / 2 - 20}px`]; //! Parte Superior de la fecha
		points = [...points, `${resource_two.left + 15}px ${resource_one.y + resource_one.height / 2}px`]; //! Desplazamiento

		// Fin del corte de la Flecha

		points = [...points, `${resource_one.right}px ${resource_one.y + resource_one.height / 2}px`]; //! Retorno a mitad de la figura.
		points = [...points, `${resource_one.right}px ${resource_one.y}px`]; //! ARRIBA DERECHA
		points = [...points, `${resource_one.left}px ${resource_one.y}px`]; //! ARRIBA IZQUIEDA

		//Fin del corte del trigger nav
		points = [...points, `${resource_one.x}px ${resource_one.y + resource_one.height / 2}px`]; //! Fin Desplazamieto
		points = [...points, `0px ${resource_one.y + resource_one.height / 2}px`]; //! Fin

		return { points };
	}

	generatePointDetails({
		resource = {
			x: 0,
			y: 0,
			width: 0,
			height: 0,
			top: 0,
			right: 0,
			bottom: 0,
			left: 0,
		},
	}: {
		resource: { x: number; y: number; width: number; height: number; top: number; right: number; bottom: number; left: number };
	}) {
		let points: string[] = [];

		points = [...points, `0px ${resource.y + resource.height / 2}px`]; //! Inicio
		points = [...points, `${resource.x + 30}px ${resource.y + resource.height / 2}px`]; //! Desplazamieto

		// Inicio del corte para el boton
		points = [...points, `${resource.left + 30}px ${resource.bottom}px`]; //! ABAJO IZQUIERDA.
		points = [...points, `${resource.right - 30}px ${resource.bottom}px`]; //! ABAJO DERECHA.

		points = [...points, `${resource.right - 30}px ${resource.y + resource.height / 2}px`]; //! mitad de la figura.
		points = [...points, `${resource.right - 30}px ${resource.y + resource.height / 2}px`]; //! Retorno a mitad de la figura.

		points = [...points, `${resource.right - 30}px ${resource.y + 40}px`]; //! ARRIBA DERECHA
		points = [...points, `${resource.left + 30}px ${resource.y + 40}px`]; //! ARRIBA IZQUIEDA

		// Fin del corte para el boton

		points = [...points, `${resource.x + 30}px ${resource.y + resource.height / 2}px`]; //! Fin Desplazamieto
		points = [...points, `0px ${resource.y + resource.height / 2}px`]; //! Fin

		return { points };
	}

	cutOverlay(points: string[] = []) {
		const overlay = document.getElementById('overlayToCut');

		if (overlay) {
			let base = '0 0, 100% 0, 100% 100%, 0 100%';

			if (points.length > 0 && !this.modalInView) {
				base += ',' + points.join(',');
			}

			overlay.style.clipPath = `polygon(${base})`;
		}
	}

	buildModalInfo() {
		this.modalInfo = {
			type: '',
			img: '',
			title: '',
			text: '',
			btnConfirm: '',
		};
	}

	showModal({ modalInfo }: any) {
		this.modalInfo = { ...this.modalInfo, ...modalInfo };
	}

	async closeModal({ step, exitTour = false }: any = {}) {
		if (this.modalInfo.type === 'start') {
			if (!step) {
				this._tourSrc.actions.emit({ action: 'hiddenModal', config: { notification: true } });
			} else {
				this._tourSrc.tourActive.next({ ...this._tourSrc.tourActive.getValue(), step });
				this._tourSrc.showTour();
				this._tourSrc.actions.emit({ action: 'hiddenModal', config: { notification: false } });
			}

			return;
		}

		if (this.modalInfo.type === 'finish') {
			this._tourSrc.actions.emit({ action: 'hiddenModal', config: { notification: true } });
			this._tourSrc.tourActive.next({ ...this._tourSrc.tourActive.getValue(), step: null });
			this._tourSrc.hiddenTour();

			this.exitTour();

			return;
		}

		if (this.modalInfo.type === 'exit') {
			if (!step && exitTour) {
				this._tourSrc.actions.emit({ action: 'hiddenModal', config: { notification: true } });
			} else {
				this._tourSrc.actions.emit({ action: 'hiddenModal', config: { notification: false } });
			}
			if (exitTour) {
				this.exitTour();
			}

			return;
		}

		if (this.modalInfo.type === 'restart') {
			if (!step) {
				this._tourSrc.actions.emit({ action: 'hiddenModal', config: { notification: true } });
			} else {
				this._tourSrc.tourActive.next({ ...this._tourSrc.tourActive.getValue(), step });
				await this._tourSrc.updatePreferences({ ...this._tourSrc.tourActive.getValue(), step });
				this._tourSrc.showTour();
				this._tourSrc.actions.emit({ action: 'hiddenModal', config: { notification: false } });
			}

			return;
		}
	}
}
