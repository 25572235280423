import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
	providedIn: 'root',
})
export class LocalstorageService {
	private storageChangeSubject = new Subject<string>();

	storageChange$ = this.storageChangeSubject.asObservable();

	emitStorageChange(key: string): void {
		this.storageChangeSubject.next(key);
	}
	constructor() {}

	set(key: string, value: string) {
		localStorage.setItem(key, value);
	}

	get(key: string) {
		return localStorage.getItem(key);
	}

	remove(key: string) {
		localStorage.removeItem(key);
	}
	clear() {
		localStorage.clear();
	}
}
