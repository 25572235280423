import { Component, HostListener, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { AppsService } from '@services/backend/apps.service';
import { ToastService } from '@services/backend/toast.service';
import { LoadingService } from '@services/components/loading.service';
import { lastValueFrom } from 'rxjs';
import { environment } from 'src/environments/environment';
@Component({
  selector: 'app-tickets-integration',
  templateUrl: './tickets-integration.component.html',
  styleUrls: ['./tickets-integration.component.scss']
})
export class TicketsIntegrationComponent implements OnInit {
  contentTitle: string = 'Tickets';
	contentDescription: string =
		'El Hook Tickets se instala en tu página web de manera sencilla, permite tener una atención fácil y rápida para tus clientes y/o usuarios.';
	panelOpenState = false;
  tuToken: string = "TU_TOKEN"
  assetsStyleProd: string = "https://s3.amazonaws.com/widget.videolink.app/hook/styles.css"
	assetsStyleQa: string = "https://widgetqa.s3.amazonaws.com/assets/css/style.css"
	assetsStyle: string = (environment.production) ? this.assetsStyleProd : this.assetsStyleQa
	assetsJsProd: string = "https://s3.amazonaws.com/widget.videolink.app/hook/videolink.js"
	assetsJsQa: string = "https://widgetqa.s3.amazonaws.com/assets/js/script.js"
	assetsJs: string = (environment.production) ? this.assetsJsProd : this.assetsJsQa
	contentFirst: string = `<!DOCTYPE html>
         <html lang="en">
           <body>
            <widget-videolink 
            token="`;
	contentFirstTwo: string = `"
            mail="no-reply@mail.com"
            position="left">
          </widget-videolink>
          <link
            rel="stylesheet"
            href="${this.assetsStyle}"
          />
            <script src="${this.assetsJs}"></script>
            <script>
              window.vlGlobal.start(
               "`;
	contentFirstThree: string = `",
               "no-reply@mail.com",
               "left"
              );
        </script>
      </body>
</html>`;
	contentSecond: string = `<widget-videolink token="`
  contentSecondTwo: string = `"></widget-videolink>`;
	contentThree: string = `<widget-videolink token="`;
  contentThreeTwo: string = `" mail="no-reply@mail.com"></widget-videolink>`;
	contentFour: string = `<widget-videolink token="`
  contentFourTwo: string = `" mail="no-reply@mail.com" position="left"></widget-videolink>`;
	contentFive: string = `<link rel="stylesheet" href="${this.assetsStyle}" />
<script src="${this.assetsJs}"></script>
<script>
	window.vlGlobal.start(
	  "`
    contentFiveTwo: string = `",
	  "no-reply@mail.com",
	  "left"
	);
</script>`;
contentSix: string = `<!DOCTYPE html>
<html lang="en">
  <body>
   <widget-videolink 
   token="`;
contentSixTwo: string = `"
   mail="no-reply@mail.com"
   position="left">
 </widget-videolink>
 <link
   rel="stylesheet"
   href="${this.assetsStyle}"
 />
   <script src="${this.assetsJs}"></script>
   <script>
     window.vlGlobal.start(
      "`;
contentSixThree: string = `",
      "no-reply@mail.com",
      "left"
     );
</script>
</body>
</html>`;
companyId: string = '';
listItemsApps: any[] = [];
formToken: FormGroup;
public getScreenWidth: any
public getScreenHeight: any
@HostListener('window:resize', ['$event'])
onWindowResize() {
  this.getScreenWidth = window.innerWidth
  this.getScreenHeight = window.innerHeight
}
constructor(private appsService: AppsService,private loadingService: LoadingService, private toastService: ToastService) {
  this.formToken = new FormGroup({
    tokenItem: new FormControl('0'),
  });
  this.initAll();
}

	ngOnInit(): void {
    this.getScreenWidth = window.innerWidth
		this.getScreenHeight = window.innerHeight
  }
  async initAll() {
		this.loadingService.initiate()
		await this.getInfoApps();
	}
	async getInfoApps() {
		try {
			let token = localStorage.getItem('tokenAuth') || '';
			let decode = JSON.parse(atob(token.split('.')[1]));
			this.companyId = decode.company;
			const result: any = await lastValueFrom(this.appsService.getPreferences(this.companyId));
			this.listItemsApps = result.data;
			this.loadingService.hide()
		} catch (e: any) {
			this.loadingService.hide()
			this.toastService.initiate({
				title: 'Error',
				content: 'Ha ocurrido un error inesperado.',
				type: 'error',
			});
			console.error(e);
		}
	}
	changeToken(id: string) {
		if (id != '0') {
			let data = this.listItemsApps.find((item) => item._id == id);
			this.tuToken = data.token;
		} else {

		}
	}
	textToClipboard(text: string) {
		if (navigator.clipboard && window.isSecureContext) {
			navigator.clipboard.writeText(text);
			this.notifyCopy()
		} else {
			let temp = document.createElement('textarea');
			temp.value = text;
			temp.style.position = 'fixed';
			temp.style.left = '-999999px';
			temp.style.top = '-999999px';
			document.body.appendChild(temp);
			temp.focus();
			temp.select();
			document.execCommand('copy');
			temp.remove();
			this.notifyCopy()
		}
	}

	notifyCopy() {
		this.toastService.initiate({
			title: 'Éxito',
			content: 'Código copiado en el corta papeles',
			type: 'success',
		});
	}
}
