import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { DimensionsScreenService } from '@services/components/dimensions-screen.service';
import { Subject, Subscription, takeUntil, tap } from 'rxjs';

@Component({
	selector: 'hk-attention-card',
	templateUrl: './preview-attention-card.component.html',
	styleUrls: ['./preview-attention-card.component.scss'],
})
export class PreviewAttentionCardComponent implements OnInit, OnDestroy {
	@Input() ability: any = {
		_id: '1',
		name: 'Attention',
		avatar: 'assets/icons/icon-message.svg',
		description: 'This is a test description for the attention card component.',
		icon: 'image.png',
		disabled: false,
		isHidden: false,
		status: 'active',
		company: 'Hook',
	};
	noAvatarUrl = 'assets/icons/icon-message.svg';
	desktopView = false;
	@Input() minimal: boolean = false;
	@Input() disabled: boolean = false;
	@Input() even: boolean = false;
	@Input() btnBGColor: string = '#FFFFFF';
	@Input() iconColor: string = '#FFFFFF';
	@Input() btnAbilityText: string = '';
	@Input() grid: boolean = false;
	subs: Array<Subscription> = [];

	public _unSuscribeAll: Subject<any> = new Subject();
	width!: number;
	constructor(private _dimensionsScreenSrc: DimensionsScreenService) {
		this._dimensionsScreenSrc.change
			.pipe(
				takeUntil(this._unSuscribeAll),
				tap(({ width }) => {
					this.width = width;
					this.viewFormat();
				})
			)
			.subscribe();
	}

	viewFormat() {
		if (this.width < 700) this.desktopView = false;
		else this.desktopView = true;
	}

	ngOnInit(): void {}

	ngOnDestroy() {
		this._unSuscribeAll.next({});
		this._unSuscribeAll.complete();
	}
}
