import { VIconModule } from './v-icon/v-icon.module';
import { LoadingComponent } from './loading/loading.component';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TitleSectionComponent } from './title-section/title-section.component';
import { TooltipComponent } from './tooltip/tooltip.component';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { SearchComponent } from './search/search.component';
import { CustomFiltersComponent } from './custom-filters/custom-filters.component';
import { MatSelectModule } from '@angular/material/select';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ModalPreviewerComponent } from './modal-previewer/modal-previewer.component';
import { MatDialogModule, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ModalPreviewerFormComponent } from './modal-previewer-form/modal-previewer-form.component';
import { VSelectComponent } from './v-select/v-select.component';
import { VOptionComponent } from './v-option/v-option.component';
import { GenericTooltipComponent } from './generic-tooltip/generic-tooltip.component';
import { CopyButtonComponent } from './copy-button/copy-button.component';
import { MatTableModule } from '@angular/material/table';
import { MatMenuModule } from '@angular/material/menu';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatSortModule } from '@angular/material/sort';
import { MatStepperModule } from '@angular/material/stepper';
import { MatChipsModule } from '@angular/material/chips';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatTooltipModule } from '@angular/material/tooltip';
// import { NgxLoadingModule } from 'ngx-loading';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { CustomToasterComponent } from 'src/app/@components/custom-toaster/custom-toaster.component';
import { TooltipHkComponent } from './tooltip-hk/tooltip-hk.component';
import { CopyBComponent } from './copy-b/copy-b.component';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatNativeDateModule, MAT_DATE_LOCALE } from '@angular/material/core';
import { PreviewAttentionCardComponent } from './preview-attention-card/preview-attention-card.component';
import { PreviewAttentionBtnComponent } from './preview-attention-btn/preview-attention-btn.component';
import { FormsContactFormsComponent } from './forms-contact-forms/forms-contact-forms.component';
import { FormsContactBtnComponent } from './forms-contact-btn/forms-contact-btn.component';
import { VlFormDynamicComponent } from './vl-form-dynamic/vl-form-dynamic.component';
import { VlSelectCustomComponent } from './vl-select-custom/vl-select-custom.component';
import { VlOptionCustomComponent } from './vl-option-custom/vl-option-custom.component';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { VlIconComponent } from './vl-icon/vl-icon.component';
import { VlIconModule } from './vl-icon/vl-icon.module';
import { DynamicFormFullComponent } from './dynamic-form-full/dynamic-form-full.component';
import { LiInputFileComponent } from './li-input-file/li-input-file.component';
import { BoxDetailComponent } from './box-detail/box-detail.component';
import { VlFormGroupS1Component } from './vl-form-group-s1/vl-form-group-s1.component';
import { FormInputTrimModule } from '../directives/form-input-trim/form-input-trim.module';
import { VlLayoutContentS2Component } from './vl-layout-content-s2/vl-layout-content-s2.component';
import { RouterModule } from '@angular/router';
import { VlStepperComponent } from './vl-stepper/vl-stepper.component';
import { VlSelectCustomS2Component } from './vl-select-custom-s2/vl-select-custom-s2.component';
import { VlOptionS2Component } from './vl-option-s2/vl-option-s2.component';
const components = [
	TitleSectionComponent,
	TooltipComponent,
	SearchComponent,
	VSelectComponent,
	VOptionComponent,
	LoadingComponent,
	CopyButtonComponent,
	ModalPreviewerComponent,
	ModalPreviewerFormComponent,
	GenericTooltipComponent,
	CustomToasterComponent,
	TooltipHkComponent,
	CopyBComponent,
	PreviewAttentionCardComponent,
	PreviewAttentionBtnComponent,
	FormsContactFormsComponent,
	FormsContactBtnComponent,
	VlFormDynamicComponent,
	VlSelectCustomComponent,
	VlOptionCustomComponent,
	DynamicFormFullComponent,
	LiInputFileComponent,
	BoxDetailComponent,
	VlFormGroupS1Component,
	VlLayoutContentS2Component,
	VlStepperComponent,
];

const material = [
	MatTableModule,
	MatPaginatorModule,
	MatSortModule,
	MatMenuModule,
	MatIconModule,
	MatStepperModule,
	MatFormFieldModule,
	MatInputModule,
	MatButtonModule,
	MatTooltipModule,
	MatChipsModule,
	MatDialogModule,
	MatSelectModule,
	MatSlideToggleModule,
	MatExpansionModule,
	MatCheckboxModule,
	MatDatepickerModule,
	MatNativeDateModule,
	MatProgressBarModule,
];

@NgModule({
	declarations: [components, VlSelectCustomS2Component, VlOptionS2Component],
	imports: [
		CommonModule,
		FormsModule,
		ReactiveFormsModule,
		material,
		// NgxLoadingModule.forRoot({}),
		VIconModule,
		VlIconModule,
		FormsModule,
		ReactiveFormsModule,
		MatIconModule,
		FormInputTrimModule,
		RouterModule,
	],
	providers: [
		ModalPreviewerComponent,
		ModalPreviewerFormComponent,
		{ provide: MAT_DIALOG_DATA, useValue: {} },
		{ provide: MAT_DATE_LOCALE, useValue: 'es-ES' },
	],
	exports: [components, material, FormsModule, ReactiveFormsModule, VIconModule, VlIconModule, RouterModule],
})
export class ComponentsModule {}
