<main class="personalize" style="padding-right: 32px; padding-bottom: 18px">
	<div class="service-container" *ngIf="!instagramMembership">
		<div class="one-line">
			<div class="img-container">
				<img src="../../../../assets/img/instagram_white.png" alt="logotipo de facebook" />
			</div>
			<div class="text-container">
				<p>Adquiere Instagram por tan solo 20 $ mensuales</p>
			</div>
		</div>

		<div class="button-container">
			<button [routerLink]="['/dashboard/profile/pricing']">Comprar</button>
		</div>
	</div>
	<div style="display: flex; align-items: center; justify-content: space-between">
		<div style="font-size: 30px; font-weight: 700">Instagram</div>
		<div>
			<div
				(click)="goToSupport()"
				style="
					cursor: pointer;
					border-radius: 21.44px;
					font-size: 16px;
					font-weight: 500;
					color: #fff;
					padding: 12px;
					padding-right: 16px;
					padding-left: 16px;
					background: #ea345f;
				"
			>
				Hablar con un asesor
			</div>
		</div>
	</div>
	<div style="margin-top: 20px; font-size: 20px; font-weight: 400px; text-align: justify">
		Para empezar a utilizar Instagram en Videolink, es necesario cumplir con los siguientes requisitos:
	</div>
	<div style="margin-top: 64px; margin-left: 32px">
		<div style="width: 133px">
			<div style="height: 90px; background-color: #fff; border-radius: 10px; display: flex; justify-content: center; align-items: center">
				<img src="/assets/img/logos_facebook.png" alt="" />
			</div>
			<div style="text-align: center">
				<div style="font-weight: 500; font-size: 15px">Cuenta comercial</div>
				<div style="font-weight: 500; font-size: 15px">de Facebook</div>
			</div>
			<div style="margin-top: 6px; text-align: center">(Paso 1)</div>
		</div>
	</div>
	<div style="margin-top: 54px; font-size: 20px; font-weight: 400">
		Debe tener una cuenta comercial de Facebook verificada y una cuenta de Instagram activa.
	</div>
	<div style="margin-top: 54px; font-size: 25px; font-weight: 700">Vincular Cuenta comercial de Facebook con Instagram</div>
	<div style="margin-top: 23px; font-size: 20px; font-weight: 400">
		Ingresa a tu cuenta en Facebook y seleccionas la cuenta comercial que deseas vincular con tu Instagram.
	</div>
	<div style="margin-top: 23px; font-size: 20px; font-weight: 400; display: flex; align-items: center">
		<img src="/assets/img/instagram_1.png" alt="" />
	</div>
	<div style="margin-top: 49px; font-size: 20px; font-weight: 400">Selecciona configuración en el menú de “Administrar página”</div>
	<div style="margin-top: 23px; font-size: 20px; font-weight: 400; display: flex; align-items: center">
		<img src="/assets/img/instagram_3.png" alt="" />
	</div>
	<div style="margin-top: 49px; font-size: 20px; font-weight: 400">Selecciona “Cuentas vinculadas” en la pagina de configuración</div>
	<div style="margin-top: 23px; font-size: 20px; font-weight: 400; display: flex; align-items: center">
		<img src="/assets/img/instagram_4.png" alt="" />
	</div>
	<div style="margin-top: 49px; font-size: 20px; font-weight: 400">Damos clic en el botón “Conectar cuenta”.</div>
	<div style="margin-top: 23px; font-size: 20px; font-weight: 400; display: flex; align-items: center">
		<img src="/assets/img/instagram_5.png" alt="" />
	</div>
	<div style="margin-top: 49px; font-size: 20px; font-weight: 400">Luego clic en el botón “Conectar”.</div>
	<div style="margin-top: 23px; font-size: 20px; font-weight: 400; display: flex; align-items: center">
		<img src="/assets/img/instagram_6.png" alt="" />
	</div>
	<div style="margin-top: 49px; font-size: 20px; font-weight: 400">
		Damos premisos para acceder a los mensajes de Instagram en la bandeja de entrada y pisamos el botón de “Confirmar”.
	</div>
	<div style="margin-top: 23px; font-size: 20px; font-weight: 400; display: flex; align-items: center">
		<img src="/assets/img/instagram_7.png" alt="" />
	</div>
	<div style="margin-top: 49px; font-size: 20px; font-weight: 400">
		Se dirige a una ventana para iniciar sesión en la cuenta de Instagram que se desea vincular.
	</div>
	<div style="margin-top: 23px; font-size: 20px; font-weight: 400; display: flex; align-items: center">
		<img src="/assets/img/instagram_8.png" alt="" />
	</div>
	<div style="margin-top: 49px; font-size: 20px; font-weight: 400">
		Seleccionamos cuenta “Empresa”, damos clic en el botón “Siguiente”. Veremos una información sobre los beneficios de la cuenta “Empresa”
		y volvemos a dar clic en el botón “Siguiente”.
	</div>
	<div style="margin-top: 23px; font-size: 20px; font-weight: 400; display: flex; align-items: center">
		<img src="/assets/img/instagram_9.png" alt="" />
	</div>
	<div style="margin-top: 49px; font-size: 20px; font-weight: 400">
		Seleccionamos una categoría y al darle siguiente procedemos a revisar información de contacto, ambos con la opción de mostrar en el
		perfil o no. Al finalizar todo el proceso damos por último al botón de “Guardar”.
	</div>
	<div style="margin-top: 23px; font-size: 20px; font-weight: 400; display: flex; align-items: center">
		<img src="/assets/img/instagram_10.png" alt="" />
	</div>
	<div style="margin-top: 49px; font-size: 20px; font-weight: 400">
		Vemos el mensaje de que la cuenta empresarial de instagram está lista, damos clic al botón “Listo”.
	</div>
	<div style="margin-top: 23px; font-size: 20px; font-weight: 400; display: flex; align-items: center">
		<img src="/assets/img/instagram_11.png" alt="" />
	</div>
	<div style="margin-top: 49px; font-size: 20px; font-weight: 400">
		Y volvemos a la ventana anterior con una modal de confirmación. Y ya podrás vincular Videolink con tu cuenta en instagram.
	</div>
	<div style="margin-top: 23px; font-size: 20px; font-weight: 400; display: flex; align-items: center">
		<img src="/assets/img/instagram_12.png" alt="" />
	</div>
</main>
