import { MatIconModule } from '@angular/material/icon';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { VIconComponent } from './v-icon.component';

@NgModule({
	declarations: [VIconComponent],
	imports: [CommonModule, MatIconModule],
	exports: [VIconComponent],
})
export class VIconModule {}
