<div class="dashboard-container" *ngIf="getScreenWidth > 1024">
	<div class="dashboard-container-title">Analiza tu centro de atención omnicanal</div>
	<div class="dashboard-filter-container">
		<div class="dashboard-filter-group" style="display: flex; align-items: center">
			<button (click)="handleData('day')" [ngClass]="[filterType === 'day' ? 'btn-filter-active' : 'btn-filter-not-active']">Hoy</button>
			<button (click)="handleData('weekly')" [ngClass]="[filterType === 'weekly' ? 'btn-filter-active' : 'btn-filter-not-active']">
				Semana
			</button>
			<button (click)="handleData('mounth')" [ngClass]="[filterType === 'mounth' ? 'btn-filter-active' : 'btn-filter-not-active']">
				Mes
			</button>
			<button (click)="handleData('year')" [ngClass]="[filterType === 'year' ? 'btn-filter-active' : 'btn-filter-not-active']">Año</button>
			<button (click)="handleData('custom')" [ngClass]="[filterType === 'custom' ? 'btn-filter-active' : 'btn-filter-not-active']">
				Personalizar
			</button>
			<form class="analytics__filter__form" [formGroup]="form">
				<label class="analytics__filter__group" *ngIf="show">
					<span style="margin-left: 12px; font-size: 12px" class="analytics__filter__group__label">Desde: </span>

					<div (click)="picker.open()" class="analytics__filter__group__inputDate">
						<input
							type="text"
							[matDatepicker]="picker"
							formControlName="dateInit"
							placeholder="dd/mm/aaaa"
							[disabled]="true"
							[min]="minDate"
							[max]="today"
						/>
						<v-icon class="btnDatepicker" name="calendar"></v-icon>

						<mat-datepicker #picker [disabled]="false"></mat-datepicker>
					</div>
				</label>
				<label class="analytics__filter__group" *ngIf="show">
					<span style="margin-left: 12px; font-size: 12px" class="analytics__filter__group__label">Hasta: </span>
					<div (click)="picker.open()" class="analytics__filter__group__inputDate">
						<input
							type="text"
							formControlName="dateEnd"
							placeholder="dd/mm/aaaa"
							[matDatepicker]="picker"
							[disabled]="true"
							[min]="form.get('dateInit')?.value"
							[max]="today"
						/>
						<v-icon class="btnDatepicker" name="calendar"></v-icon>

						<mat-datepicker #picker [disabled]="false"></mat-datepicker>
					</div>
					<!-- <input
						style="margin-left: 12px"
						type="date"
						class="listConversations__filter__group__input date"
						placeholder="dd-mm-yyyy"
						formControlName="dateEnd"
						[min]="form.get('dateInit')?.value"
					/> -->
				</label>
			</form>
		</div>
	</div>
	<div class="dashboard-general-chart-container">
		<div class="dashboard-general-chat-container" style="margin-right: 31px">
			<div class="title-item-dashboard">Atenciones</div>
			<div class="fullScreem" *ngIf="isData">
				<google-chart
					(select)="onSelect($event)"
					[type]="typeChartBar"
					[data]="myData"
					style="width: 100%; height: 330px"
					[dynamicResize]="true"
					[options]="myOptions"
				></google-chart>
			</div>

			<div class="fullScreem" *ngIf="!isData">
				<div style="display: flex; align-items: center; justify-content: center; height: 280px">Sin atenciones</div>
			</div>
		</div>
		<div class="dashboard-channel-container">
			<div class="title-item-dashboard">Canales</div>
			<div class="flex-container-bottom" *ngFor="let channel of totalChannel">
				<div class="img-channel" [ngClass]="channel.name === 'Facebook' ? 'fb-channel' : ''">
					<img [src]="channel.icon" />
				</div>
				<div>
					<div class="item-channels">{{ channel.quantity }}</div>
					<div class="item-channels-sub">{{ channel.name }}</div>
				</div>
			</div>
		</div>
	</div>
	<div class="dashboard-general-chart-container" style="overflow-x: auto">
		<div class="metrics-containers" style="min-width: 500px">
			<div class="flex-container-bottom">
				<div class="flex-item-margin">
					<div class="title-item-dashboard">Atenciones totales</div>
					<div class="item-flex-container">
						<div class="item-text-one">{{ totalAttentions.attentionsTotal }}</div>
						<!-- <div *ngIf="!isCustom" class="item-up-stadistic">({{ totalAttentions?.percentage }}%)</div> -->
					</div>
					<!-- <div *ngIf="!isCustom" class="item-date-text">{{ filterText }} anterior: {{ totalAttentions.attentionsTotalOld }}</div> -->
				</div>
				<div class="flex-item">
					<div class="title-item-dashboard">Satisfacción</div>
					<div class="item-flex-container">
						<div class="item-text-two">{{ totalSatisfaction.average?.toFixed(2) }}</div>
						<!-- <div *ngIf="!isCustom" class="item-up-stadistic">( {{ totalSatisfaction?.percentage }}%)</div> -->
					</div>
					<!-- <div *ngIf="!isCustom" class="item-date-text">{{ filterText }} anterior: {{ totalSatisfaction.averageOld?.toFixed(2) }}</div> -->
				</div>
			</div>
			<div class="flex-container">
				<div class="flex-item-margin">
					<div class="title-item-dashboard">Resolutividad</div>
					<div class="item-flex-container">
						<div class="item-text-three">{{ totalResolution.resolution }}%</div>
					</div>
					<!-- <div *ngIf="!isCustom" class="item-date-text">{{ filterText }} anterior: {{ totalResolution.resolutionOld }}%</div> -->
				</div>
				<div class="flex-item">
					<div class="title-item-dashboard">Tiempo promedio de atención</div>
					<div class="item-flex-container">
						<div class="item-text-four">{{ averageTime }}</div>
						<!-- <div *ngIf="!isCustom" class="item-up-stadistic">( {{ averageTimePercentage }}%)</div> -->
					</div>
					<!-- <div *ngIf="!isCustom" class="item-date-text">{{ filterText }} anterior: {{ averageTimeOld }}</div> -->
				</div>
			</div>
		</div>
		<div
			class="charts-containers"
			style="min-width: 250px; padding-left: 20px; padding-right: 24px"
			[ngStyle]="{ width: dataGroups.length > 1 ? '25%' : '50%', 'margin-right': dataGroups.length > 1 ? '31px' : '0px' }"
		>
			<div class="title-item-dashboard" style="margin-bottom: 0px">Tipos de atenciones</div>
			<google-chart
				[type]="typePieChart"
				[data]="dataChartAbilities"
				style="width: 100%; height: 270px"
				[dynamicResize]="true"
				[options]="myOptions1"
			></google-chart>
			<div style="overflow-y: auto; max-height: 70px; margin-top: -25px; position: relative; padding-right: 8px">
				<div *ngFor="let chart of dataAbilities" style="display: flex; align-items: center; padding-bottom: 12px">
					<div class="content_icon" style="margin-right: 17px">
						<v-icon name="{{ chart.icon }}" class="icon"></v-icon>

						<!-- <mat-icon class="icon">{{ chart.icon }}</mat-icon> -->
						<!-- <v-icon [name]="chart.icon" class="icon" style="font-size: 12px !important"></v-icon> -->
					</div>
					<div style="font-size: 12px">{{ chart.name }}</div>
					<div style="margin-left: auto; font-size: 12px">{{ chart.total }}%</div>
				</div>
			</div>
		</div>
		<div
			class="charts-containers"
			style="min-width: 250px; padding-left: 20px; padding-right: 24px"
			[ngStyle]="{ width: getScreenWidth < 1305 ? '25%' : '25%' }"
			*ngIf="this.dataGroups.length > 1"
		>
			<div class="title-item-dashboard" style="margin-bottom: 0px">Grupos</div>
			<google-chart
				[type]="typePieChart"
				[data]="dataChartGroups"
				style="width: 100%; height: 270px"
				[dynamicResize]="true"
				[options]="myOptions2"
			></google-chart>
			<div style="overflow-y: auto; max-height: 70px; margin-top: -25px; position: relative; padding-right: 8px">
				<div *ngFor="let chart of dataGroups" style="display: flex; align-items: center; padding-bottom: 8px">
					<div class="content_icon" style="margin-right: 17px">
						<v-icon name="arrow_path" class="icon"></v-icon>
					</div>
					<div style="font-size: 12px">{{ chart.name }}</div>
					<div style="margin-left: auto; font-size: 12px">{{ chart.total }}%</div>
				</div>
			</div>
		</div>
	</div>
</div>
<div
	style="padding-top: 27px; padding-left: 30px; padding-right: 30px; padding-bottom: 27px; overflow-x: hidden"
	*ngIf="getScreenWidth < 1025"
>
	<div class="dashboard-container-title">Analiza tu centro de atención omnicanal</div>
	<div class="dashboard-filter-container" style="margin-top: 27px">
		<div class="dashboard-filter-group" style="margin-top: 27px; width: 100%; display: flex; align-items: center; justify-content: center">
			<button
				(click)="handleData('day')"
				[ngClass]="[filterType === 'day' ? 'btn-filter-active' : 'btn-filter-not-active']"
				style="height: 45.33px; width: 100px; font-size: 20px; margin-right: 13px"
			>
				Hoy
			</button>
			<button
				style="height: 45.33px; width: 100px; font-size: 20px; margin-right: 13px"
				(click)="handleData('weekly')"
				[ngClass]="[filterType === 'weekly' ? 'btn-filter-active' : 'btn-filter-not-active']"
			>
				Semana
			</button>
			<button
				style="height: 45.33px; width: 100px; font-size: 20px"
				(click)="handleData('mounth')"
				[ngClass]="[filterType === 'mounth' ? 'btn-filter-active' : 'btn-filter-not-active']"
			>
				Mes
			</button>
		</div>
		<div class="dashboard-filter-group" style="margin-top: 27px; width: 100%; display: flex; align-items: center; justify-content: center">
			<button
				style="height: 45.33px; width: 100px; font-size: 20px; margin-right: 13px"
				(click)="handleData('year')"
				[ngClass]="[filterType === 'year' ? 'btn-filter-active' : 'btn-filter-not-active']"
			>
				Año
			</button>
			<button
				style="height: 45.33px; width: 160px; font-size: 20px"
				(click)="handleData('custom')"
				[ngClass]="[filterType === 'custom' ? 'btn-filter-active' : 'btn-filter-not-active']"
			>
				Personalizar
			</button>
		</div>
		<form class="analytics__filter__form" [formGroup]="form" style="justify-content: center; margin-top: 8px">
			<label class="analytics__filter__group" *ngIf="show" style="flex-direction: column; align-items: unset">
				<span style="font-size: 20px; color: #394154" class="analytics__filter__group__label">Desde: </span>

				<div (click)="picker.open()" class="analytics__filter__group__inputDate">
					<input type="text" [matDatepicker]="picker" formControlName="dateInit" placeholder="dd/mm/aaaa" [disabled]="true" [max]="today" />
					<v-icon class="btnDatepicker" name="calendar"></v-icon>

					<mat-datepicker #picker [disabled]="false"></mat-datepicker>
				</div>
			</label>
			<label class="analytics__filter__group" *ngIf="show" style="flex-direction: column; align-items: unset">
				<span style="font-size: 20px; color: #394154" class="analytics__filter__group__label">Hasta: </span>
				<div (click)="picker.open()" class="analytics__filter__group__inputDate">
					<input
						type="text"
						formControlName="dateEnd"
						placeholder="dd/mm/aaaa"
						[matDatepicker]="picker"
						[disabled]="true"
						[min]="form.get('dateInit')?.value"
						[max]="today"
					/>
					<v-icon class="btnDatepicker" name="calendar"></v-icon>

					<mat-datepicker #picker [disabled]="false"></mat-datepicker>
				</div>
				<!-- <input
					style="margin-left: 12px"
					type="date"
					class="listConversations__filter__group__input date"
					placeholder="dd-mm-yyyy"
					formControlName="dateEnd"
					[min]="form.get('dateInit')?.value"
				/> -->
			</label>
		</form>
	</div>
	<div>
		<div class="dashboard-general-chat-container" style="width: 100%; height: 330px">
			<div class="title-item-dashboard">Atenciones</div>
			<div class="fullScreem" *ngIf="isData">
				<google-chart
					[type]="typeChartBar"
					[data]="myData"
					style="width: 100%; height: 250px"
					[dynamicResize]="true"
					[options]="myOptions"
				></google-chart>
			</div>
			<div class="fullScreem" *ngIf="!isData">
				<div style="display: flex; align-items: center; justify-content: center; height: 280px">Sin atenciones</div>
			</div>
		</div>
		<div class="dashboard-channel-container" style="margin-top: 22px; width: 100%; min-height: 141px; height: auto !important">
			<div class="title-item-dashboard">Canales</div>
			<div style="display: flex; overflow-x: auto">
				<div class="flex-container-bottom" *ngFor="let channel of totalChannel">
					<div class="img-channel" style="width: 42px"><img [src]="channel.icon" /></div>
					<div style="margin-right: 32px">
						<div class="item-channels" style="font-size: 18px">{{ channel.quantity }}</div>
						<div class="item-channels-sub">{{ channel.name }}</div>
					</div>
				</div>
			</div>
		</div>
	</div>
	<div>
		<div class="metrics-containers" style="width: 100%; margin-top: 22px; overflow-x: auto; height: auto; padding-bottom: 8px">
			<div class="flex-container" style="display: -webkit-box">
				<div class="flex-item-margin" style="width: 241px">
					<div class="title-item-dashboard">Atenciones totales</div>
					<div class="item-flex-container">
						<div class="item-text-one">{{ totalAttentions.attentionsTotal }}</div>
						<div class="item-up-stadistic" *ngIf="!isCustom">({{ totalAttentions?.percentage }}%)</div>
					</div>
					<div class="item-date-text" *ngIf="!isCustom">{{ filterText }} anterior: {{ totalAttentions.attentionsTotalOld }}</div>
				</div>
				<div class="flex-item-margin" style="width: 241px">
					<div class="title-item-dashboard">Satisfacción</div>
					<div class="item-flex-container">
						<div class="item-text-two">{{ totalSatisfaction.average?.toFixed(2) }}</div>
						<div class="item-up-stadistic" *ngIf="!isCustom">( {{ totalSatisfaction?.percentage }}%)</div>
					</div>
					<div class="item-date-text" *ngIf="!isCustom">{{ filterText }} anterior: 6.8</div>
				</div>
				<div class="flex-item-margin" style="width: 241px">
					<div class="title-item-dashboard">Resolutividad</div>
					<div class="item-flex-container">
						<div class="item-text-three">{{ totalResolution.resolution }}%</div>
					</div>
					<div class="item-date-text" *ngIf="!isCustom">{{ filterText }} anterior: {{ totalResolution.resolutionOld }}%</div>
				</div>
				<div class="flex-item" style="width: 241px">
					<div class="title-item-dashboard">Tiempo promedio de atención</div>
					<div class="item-flex-container">
						<div class="item-text-four">{{ averageTime }}</div>
						<div class="item-up-stadistic" *ngIf="!isCustom">( {{ averageTimePercentage }}%)</div>
					</div>
					<div class="item-date-text" *ngIf="!isCustom">{{ filterText }} anterior: {{ averageTimeOld }}</div>
				</div>
			</div>
		</div>
	</div>
	<div style="display: -webkit-box; margin-top: 23px; overflow-x: auto; padding-bottom: 8px">
		<div
			class="charts-containers"
			style="margin-right: 20px; min-width: 230px"
			[ngStyle]="{ 'min-width': dataGroups.length > 1 ? '230px' : '100%', 'margin-right': dataGroups.length > 1 ? '20px' : '0px' }"
		>
			<div class="title-item-dashboard" style="margin-bottom: 0px">Tipos de atenciones</div>
			<google-chart
				[type]="typePieChart"
				[data]="dataChartAbilities"
				style="width: 100%; height: 270px"
				[dynamicResize]="true"
				[options]="myOptions1"
			></google-chart>
			<div style="overflow-y: auto; max-height: 70px; margin-top: -25px; position: relative; padding-right: 8px">
				<div
					*ngFor="let chart of dataAbilities"
					style="display: flex; align-items: center; padding-left: 24px; padding-right: 12px; padding-bottom: 12px"
				>
					<div class="content_icon" style="margin-right: 17px">
						<v-icon name="{{ chart.icon }}" class="icon"></v-icon>
						<!-- <v-icon [name]="chart.icon" class="icon"></v-icon> -->
					</div>
					<div style="font-size: 12px">{{ chart.name }}</div>
					<div style="margin-left: auto; font-size: 12px">{{ chart.total }}%</div>
				</div>
			</div>
		</div>
		<div class="charts-containers" style="min-width: 230px" *ngIf="dataGroups.length > 1">
			<div class="title-item-dashboard" style="margin-bottom: 0px">Grupos</div>
			<google-chart
				[type]="typePieChart"
				[data]="dataChartGroups"
				style="width: 100%; height: 270px"
				[dynamicResize]="true"
				[options]="myOptions2"
			></google-chart>
			<div style="overflow-y: auto; max-height: 70px; margin-top: -25px; position: relative; padding-right: 8px">
				<div
					*ngFor="let chart of dataGroups"
					style="display: flex; align-items: center; padding-left: 24px; padding-right: 12px; padding-bottom: 12px"
				>
					<div class="content_icon" style="margin-right: 17px">
						<v-icon name="arrow_path" class="icon"></v-icon>
					</div>
					<div style="font-size: 12px">{{ chart.name }}</div>
					<div style="margin-left: auto; font-size: 12px">{{ chart.total }}%</div>
				</div>
			</div>
		</div>
	</div>
</div>
