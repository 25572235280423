import { Component, HostListener, OnInit, ViewChild, ElementRef, AfterViewInit } from '@angular/core';

@Component({
	selector: 'generic-tooltip',
	templateUrl: './generic-tooltip.component.html',
	styleUrls: ['./generic-tooltip.component.scss'],
})
export class GenericTooltipComponent implements OnInit, AfterViewInit {
	@HostListener('mouseover', ['$event'])
	onMouseEnter($event: MouseEvent) {
		this.isHover = true;
		const x = this.trigger.nativeElement.clientWidth;
		const y = this.trigger.nativeElement.offsetTop;

		const witdh = this.wrapper.nativeElement.clientWidth;
		const height = this.wrapper.nativeElement.clientHeight;

		const top = y - height - 20;
		const left = x / 2 - witdh / 2 - 10;

		this.wrapper.nativeElement.style.setProperty('--top', `${top}px`);
		this.wrapper.nativeElement.style.setProperty('--left', `${left}px`);
	}

	@HostListener('mouseout', ['$event'])
	onMouseOut($event: MouseEvent) {
		this.isHover = false;
	}

	@ViewChild('trigger') trigger!: ElementRef<HTMLDivElement>;
	@ViewChild('wrapper') wrapper!: ElementRef<HTMLDivElement>;

	public isHover: boolean = false;

	// iconTooltip: any;
	// textTooltip: string = 'Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Aenean commodo ligula eget dolor.';

	constructor() {}

	ngOnInit(): void {}

	ngAfterViewInit(): void {}

	setCoordenates() {}

	test(algo?: any) {
		console.log(algo);
	}
}
