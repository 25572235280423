<main>
    <div class="space-container"></div>
    <div class="principal-container">
        <div class="first">

        </div>
        <div class="second">
            <h1>Próximamente</h1>
        </div>
    </div>
</main>