import { Injectable, EventEmitter } from '@angular/core';
import { ApiService } from './api.service';

import { BehaviorSubject, tap } from 'rxjs';
import { AuthService } from '@services/storage/auth.service';

@Injectable({
	providedIn: 'root',
})
export class MembershipService {
public membershipOfCompany: BehaviorSubject<any> = new BehaviorSubject({});

constructor(private api: ApiService, private _authSrc: AuthService) {}

getMembership() {
    return this.api.get(`/memberships`).pipe(
        tap((preferences) => {
            this.membershipOfCompany.next(preferences);
        })
    );
}
}