import { Component, HostListener, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog'
@Component({
  selector: 'app-modal-previewer',
  templateUrl: './modal-previewer.component.html',
  styleUrls: ['./modal-previewer.component.scss']
})
export class ModalPreviewerComponent implements OnInit {

  dataPreviewer: any = {
    file: '',
    title: '',
    description: '',
    buttonTitle: '',
    buttonAttentionsColor: '',
    colorButton: '',
    color: '',
    colorBackground: '',
    nameStyle: ''  
  }
  typeOfAttentions: any[] = []
  public getScreenWidth: any;
  public getScreenHeight: any;
  @HostListener('window:resize', ['$event'])
  onWindowResize() {
    this.getScreenWidth = window.innerWidth
    this.getScreenHeight = window.innerHeight
  }
  typeOfAttentionsMobile: any[] = []
  constructor(  private dialogRef: MatDialogRef<ModalPreviewerComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) {
      let datosTemp = this.dataPreviewer
      this.dataPreviewer = {
        ...datosTemp,
        ...data
      }
      this.typeOfAttentions = data.typeOfAttentionsL
      this.typeOfAttentionsMobile = data.typeOfAttentionsL.slice(0, 3)
     }

     ngOnInit(): void {
      this.getScreenWidth = window.innerWidth
      this.getScreenHeight = window.innerHeight
     }
  closeDialog() {
    this.dialogRef.close()
  }
}
