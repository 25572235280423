import { Component, Inject, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ApiService } from '@services/backend/api.service';
import { ToastService } from '@services/backend/toast.service';
import { LoadingService } from '@services/components/loading.service';
import { invalid } from 'moment';
import { DropzoneComponent, DropzoneConfigInterface } from 'ngx-dropzone-wrapper';
import { lastValueFrom } from 'rxjs';
import * as XLSX from 'xlsx';

@Component({
	selector: 'app-confirm-dialog',
	templateUrl: './confirm-dialog.component.html',
	styleUrls: ['./confirm-dialog.component.scss'],
	// encapsulation: ViewEncapsulation.None,
})
export class ConfirmDialogComponent implements OnInit {
	constructor(
		private _loadingSrc: LoadingService,
		private _notification: ToastService,
		private api: ApiService,

		private dialogRef: MatDialogRef<ConfirmDialogComponent>,
		@Inject(MAT_DIALOG_DATA)
		public data: {
			item: {
				img?: string;
				name?: string;
			};
			action: 'create' | 'edit' | 'delete' | 'notification';
			title: string;
			message?: string;
			subTitle?: string;
			textConfirmButton?: string;
			textCancelButton?: string;
			currentMembership?: string;
			nextMembership?: string;
			currentPrice?: string;
			nextPrice?: string;
			firstTitle?: string;
			currentMembershipData?: any;
			newMembershipData?: any;
			newPrice?: any;
			oldPrice?: any;
			membershipTime?: any;
			baseMount?: any;
			endDate?: any;
			membershipType?: any;
			isRenew?: any;
			isImport?: any;
		}
	) {
		this.imgSrc = {
			user: this.type == 'create' ? 'assets/logos/user-created.svg' : 'assets/logos/user-edited.svg',
			userCreate: 'assets/logos/user-created.svg',
			userEdit: 'assets/logos/user-edited.svg',
			'channel-whatsapp': '/assets/img/img-channels-whatsapp.svg',
			'channel-facebook': '/assets/img/img-channels-facebook.svg',
			'channel-instagram': '/assets/img/create_ig.png',
			'channel-widget': '/assets/img/img-channels-widget.svg',
			'channel-contactcenter': '/assets/img/img-channels-contactcenter.svg',
			'channel-whatsapp-acquire': '/assets/img/whatsapp-channel-acquire.png',
			'channel-facebook-acquire': '/assets/img/facebook-channel-acquire.png',
			'channel-instagram-acquire': '/assets/img/agg_ig.png',
		};
	}

	type: 'create' | 'edit' | 'delete' | 'notification' | 'copy' | 'pricing' | 'pricing-membership' | 'pricingDetails' | 'import' =
		'notification';
	titleBanner: string = '';
	textConfirmButton: string = '';
	textCancelButton: string = '';
	message: string = '';
	subTitle: string = '';
	item: any = {
		img: '',
		name: '',
	};
	currentMembership: any = '';
	nextMembership: any = '';
	currentPrice: any = '';
	nextPrice: any = '';
	firstTitle: string = '';
	currentMembershipData: any = [];
	newMembershipData: any = [];
	newPrice: any;
	oldPrice: any;
	membershipTime: any;
	baseMount: any;
	endDate: any;
	membershipType: any;
	isRenew: any;
	isImport: any = false;
	errorFormat: string = '';
	dataErrorFormat: any = [];
	imgSrc: any = {
		user: this.type == 'create' ? 'assets/logos/user-created.svg' : 'assets/logos/user-edited.svg',
		userCreate: 'assets/logos/user-created.svg',
		userEdit: 'assets/logos/user-edited.svg',
		'channel-whatsapp': '/assets/img/img-channels-whatsapp.svg',
		'channel-facebook': '/assets/img/img-channels-facebook.svg',
		'channel-instagram': '/assets/img/create_ig.png',
		'channel-widget': '/assets/img/img-channels-widget.svg',
		'channel-contactcenter': '/assets/img/img-channels-contactcenter.svg',
		'channel-whatsapp-acquire': '/assets/img/whatsapp-channel-acquire.png',
		'channel-facebook-acquire': '/assets/img/facebook-channel-acquire.png',
		'channel-instagram-acquire': '/assets/img/agg_ig.png',
	};
	public tooltip = { icon: false };
	files: File[] = [];
	nameFile: string = '';
	totalItems = 80;
	itemsPerPage = 10;
	currentPage = 1;
	pageSizeOptions: number[] = [5, 10, 25, 100];
	config: DropzoneConfigInterface = {
		autoQueue: false,
		// only excels files
		acceptedFiles: '.xlsx',
		// max file size
		maxFilesize: 10,
		// max files
		maxFiles: 1,
		// auto add files

		autoProcessQueue: false,
		// addRemoveLinks: true,
		url: 'https://httpbin.org/post',
		clickable: true,
		//previewTemplate: `<div class="main"> <div><img src="../../../../../assets/icons/xls.png" class="img"/> <div class="name" style="margin-bottom:12px">${this.nameFile}</div><div></div>`,
	};
	@ViewChild('filesDrop') filesDrop!: DropzoneComponent;

	uploadFile(e: any) {
		const file = this.filesDrop.directiveRef?.dropzone().files[0];

		if (file) {
			const size: number = Number((file.size / (1024 * 1024)).toFixed(2));
			if (size > 10) {
				alert('Muy grande el archivo');
				this.filesDrop.directiveRef?.dropzone().removeAllFiles(true);
			} else {
				this.readFile(file);
			}
		} else {
			this._notification.initiate({
				type: 'warning',
				title: '¡Por favor seleccione un archivo!',
				content: '',
			});
		}
	}

	processData(data: any) {
		const headers: string[] = ['Nombre', 'Apellido', 'Correo electrónico', 'Teléfono'];
		const fileHeaders: string[] = data[0];
		const isValidStructure: boolean = headers.every((header, index) => header === fileHeaders[index]);
		if (data.length > 5001) {
			return {
				response: null,
				errorFormat: 'size',
				errors: [
					{
						row: '',
						msg: 'Se pueden cargar maximo 5000 contactos',
					},
				],
			};
		}
		if (isValidStructure) {
			data.shift();
			const emailRegex = /^[A-Za-z0-9_.]+([-_.]?[A-Za-z0-9_]+)*@[A-Za-z0-9-]+(\.[A-Za-z0-9-]+)*(\.[A-Za-z]{2,15})$/;
			const phoneRegex = /^\d+$/;
			const minPhoneLength = 11;
			const maxPhoneLength = 30;
			const invalidData: any[] = [];
			const emailSet = new Set();
			const phoneSet = new Set();
			data.forEach((row: any, index: number) => {
				const name = row[0];
				const lastname = row[1];
				const email = row[2];
				const phone = row[3];
				if (email && phone && name && lastname) {
					let hasError = false;
					let hasErrorDuplicated = false;
					if (emailSet.has(email)) {
						hasErrorDuplicated = true;
					} else {
						emailSet.add(email);
					}

					// Verificar duplicados de phone
					if (phoneSet.has(phone)) {
						hasErrorDuplicated = true;
					} else {
						phoneSet.add(phone);
					}

					if (!emailRegex.test(email)) {
						hasError = true;
					}

					if (!phoneRegex.test(phone) || phone.length < minPhoneLength || phone.length > maxPhoneLength) {
						hasError = true;
					}

					// Hacer push solo si hay algún error
					if (hasError) {
						invalidData.push({
							row: index + 1,
							msg: 'Correo o teléfono inválido',
						});
					}

					if (hasErrorDuplicated) {
						invalidData.push({
							row: index + 1,
							msg: 'Correo o teléfono duplicado',
						});
					}
				} else {
					invalidData.push({
						row: index + 1,
						msg: 'Todos los campos son obligatorios',
					});
				}
			});

			return {
				response: invalidData.length > 0 ? null : data,
				errorFormat: invalidData.length > 0 ? 'data' : '',
				errors: invalidData,
			};
		} else {
			return {
				response: null,
				errorFormat: 'headers',
				errors: [],
			};
		}
	}

	async sendData(data: any) {
		const batchSize = 1000; // Tamaño máximo de cada lote
		const totalBatches = Math.ceil(data.length / batchSize); // Número total de lotes

		for (let i = 0; i < totalBatches; i++) {
			const batch = data.slice(i * batchSize, (i + 1) * batchSize); // Obtener el lote actual

			try {
				const response = await lastValueFrom(
					this.api.post(`/v2/contacts/import`, {
						data: batch,
					})
				);

				console.log(`Lote ${i + 1} enviado correctamente.`, response);

				// Puedes realizar alguna lógica adicional con la respuesta si es necesario
			} catch (error) {
				console.error(`Error al enviar el lote ${i + 1}.`, error);

				// Puedes manejar el error de alguna manera si es necesario
			}
		}
	}

	readFile(file: any) {
		try {
			const reader = new FileReader();
			reader.onload = async (e: any) => {
				this._loadingSrc.initiate();

				const bstr: string = e.target.result;
				const workbook: XLSX.WorkBook = XLSX.read(bstr, { type: 'binary' });
				const sheetName: string = workbook.SheetNames[0];
				const worksheet: XLSX.WorkSheet = workbook.Sheets[sheetName];
				const data: any[] = XLSX.utils.sheet_to_json(worksheet, { header: 1 });
				const filterData: any[] = data.filter((element) => element.length > 0);
				const process = this.processData(filterData);
				if (process.response === null && process.errors) {
					this.errorFormat = process.errorFormat;
					this.dataErrorFormat = process.errors;
					this._notification.initiate({
						type: 'error',
						title: process.errorFormat === 'size' ? '¡Error en número máximo de contactos !' : '¡Error en formato!',
						content: '',
					});
					this.filesDrop.directiveRef?.dropzone().removeAllFiles(true);
				} else {
					await this.sendData(process.response);
					this._notification.initiate({
						type: 'success',
						title: '¡Importación realizada con éxito!',
						content: '',
					});
					this.dialogRef.close();
				}
				this._loadingSrc.hide();
			};
			reader.readAsBinaryString(file);
		} catch (err) {
			this._notification.initiate({
				type: 'error',
				title: '¡Ha ocurrido un error!',
				content: '',
			});
			console.log({ err });
		}
	}

	ngOnInit() {
		this.type = this.data.action;
		const cases: any = {
			create: () => {
				this.titleBanner = this.data.title;
				this.textConfirmButton = 'Aceptar';
				if (this.data.item.img) {
					this.item = { ...this.item, img: this.imgSrc[this.data.item.img] ? this.imgSrc[this.data.item.img] : '' };
				}
			},
			edit: () => {
				this.titleBanner = this.data.title;
				this.textConfirmButton = 'Aceptar';
				if (this.data.item.img) {
					this.item = { ...this.item, img: this.imgSrc[this.data.item.img] ? this.imgSrc[this.data.item.img] : '' };
				}
			},
			delete: () => {
				this.titleBanner = this.data.title;
				this.message = this.data.message || '';
				this.textConfirmButton = this.data.textConfirmButton || 'Eliminar';
				this.textCancelButton = this.data.textCancelButton || 'Cancelar';
				this.item = this.data.item;
			},
			notification: () => {
				this.titleBanner = this.data.title;
				this.textConfirmButton = 'Aceptar';
				this.message = this.data.message || '';
			},
			pricing: () => {
				this.titleBanner = this.data.title;
				this.message = this.data.message || '';
				this.subTitle = this.data.subTitle || '';
				this.firstTitle = this.data.firstTitle || '';
				this.item = this.data.item;
				this.textConfirmButton = this.data.textConfirmButton || 'Aceptar';
				this.isImport = this.data.isImport;
			},

			pricingDetails: () => {
				this.titleBanner = this.data.title;
				this.message = this.data.message || '';
				this.subTitle = this.data.subTitle || '';
				this.firstTitle = this.data.firstTitle || '';
				this.currentMembershipData = this.data.currentMembershipData;
				this.item = this.data.item;
				this.newPrice = this.data.newPrice;
				this.oldPrice = this.data.oldPrice;
				this.membershipTime = this.data.membershipTime === 'month' ? 'mensual' : 'anual';
				this.textConfirmButton = this.data.textConfirmButton || 'Aceptar';
				this.newMembershipData = this.data.newMembershipData;
				this.baseMount = this.data.baseMount;
				this.endDate = this.data.endDate;
				this.membershipType = this.data.membershipType;
				this.isRenew = this.data.isRenew;
			},
			'pricing-membership': () => {
				this.currentMembership = this.data.currentMembership;
				this.nextMembership = this.data.nextMembership;
				this.currentPrice = this.data.currentPrice;
				this.nextPrice = this.data.nextPrice;
				this.textConfirmButton = 'Aceptar';
				this.textCancelButton = 'Cancelar';
			},
			copy: () => {
				this.titleBanner = this.data.title;
				this.message = this.data.message || '';
				this.item = this.data.item;
				this.textConfirmButton = this.data.textConfirmButton || 'Aceptar';
			},
			import: () => {
				this.titleBanner = this.data.title;
				this.subTitle = this.data.subTitle || '';
			},
		};

		cases[this.data.action] ? cases[this.data.action]() : cases['notification']();
	}

	copyClipboard(link: string) {
		if (navigator.clipboard && window.isSecureContext) {
			navigator.clipboard.writeText(link);
		} else {
			let temp = document.createElement('textarea');
			temp.value = link;
			temp.style.position = 'fixed';
			temp.style.left = '-999999px';
			temp.style.top = '-999999px';
			document.body.appendChild(temp);
			temp.focus();
			temp.select();
			document.execCommand('copy');
			temp.remove();
		}
		this._notification.initiate({
			type: 'success',
			title: '¡Ruta copiada con éxito!',
			content: '',
		});
	}

	closeDialog(event: any, confirm: boolean) {
		this.dialogRef.close({ confirm });
	}
}
