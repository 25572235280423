import { AfterViewInit, Component, HostListener, OnInit, ViewChild } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatStepper } from '@angular/material/stepper';
import { ConfirmDialogComponent } from '@components/confirm-dialog/confirm-dialog.component';
import { ApiService } from '@services/backend/api.service';
import { MembershipService } from '@services/backend/membership.service';
import { PreferencesService } from '@services/backend/preferences.service';
import { LoadingService } from '@services/components/loading.service';
import { lastValueFrom, Subject, takeUntil, tap } from 'rxjs';

@Component({
	selector: 'app-first-whatsapp-steps',
	templateUrl: './first-whatsapp-steps.component.html',
	styleUrls: ['./first-whatsapp-steps.component.scss'],
})
export class FirstWhatsappStepsComponent implements OnInit, AfterViewInit {
	isLoaded: boolean = true;
	contentTitle: string = 'Canal WhatsApp';
	contentDescription: string = 'Para empezar a configurar el canal de WhatsApp a su cuenta, deberá tener a la mano:';
	steps: any[] = [
		{
			id: 0,
			title: 'Cuenta comercial de Meta Business',
			step: '(Paso 1)',
			imgUrl: '../../../../assets/img/meta-account.png',
			imgAlt: 'imágen de meta business',
			tooltipText: `El Administrador comercial de Facebook, que actualmente se llama Meta Business Suite, es una plataforma para
			gestionar los activos creados en Facebook, como una página (o Fan Page), un catálogo de productos o un pixel en un solo lugar.`,
			tooltipPosition: 'left'
		},
		
		{
			id: 2,
			title: 'Número de teléfono',
			step: '(Paso 2)',
			imgUrl: '../../../../assets/img/whatsapp-phone.png',
			imgAlt: 'imágen de un teléfono azul',
			tooltipText: 'No debe tener cuenta activa de WhatsApp ni WhatsApp Business. Te recomendamos adquirir un número nuevo.',
			tooltipPosition: 'left'
		},
		{
			id: 3,
			title: 'URL Página Web',
			step: '(Paso 3)',
			imgUrl: '../../../../assets/img/whatsapp-world.png',
			imgAlt: 'imágen aluciva a un mundo',
			tooltipText: 'Debes proporcionar un sitio web válido para verificar tu negocio.',
			tooltipPosition: 'left'
		},
		{
			id: 4,
			title: 'Documentos de la empresa',
			step: '(Paso 4)',
			imgUrl: '../../../../assets/img/whatsapp-document.png',
			imgAlt: 'imagen aluciva a documentos',
			tooltipText: 'Debes tener a la mano la factura y el RIF de tu negocio en caso de que soliciten documentos de acreditación. Puedes subir el mismo documento más de una vez en formatos .jpg, .png y/o .pdf',
			tooltipPosition: 'left'
			},
	];
	tooltip: any[] = [
		{
			icon: false,
		},
		{
			icon: false,
		},
		{
			icon: false,
		},
		{
			icon: false,
		},
	];

	tooltipStep: any[] = [
		{
			icon: false,
		},
		{
			icon: false,
		},
	];
	contentTrigger: any = {
		one: true,
		two: false,
		three: false,
	};
	public getScreenWidth: any;
	public getScreenHeight: any;
	whatsappIdentificator: UntypedFormGroup;
	whatsappMetaToken: UntypedFormGroup;
	formStep: UntypedFormGroup;
	@ViewChild(MatStepper, { static: false }) stepper!: MatStepper;

	finishItemStep: boolean = false;
	tabActive: number = 0;
	companyId: string = '';
	whatsappMembership: boolean = false;
	public _unSuscribeAll: Subject<any> = new Subject();
	@HostListener('window:resize', ['$event'])
	onWindowResize() {
		this.getScreenWidth = window.innerWidth;
		this.getScreenHeight = window.innerHeight;
	}
	constructor(
		private loadingService: LoadingService,
		private api: ApiService,
		private preferenceService: PreferencesService,
		private _membershipService: MembershipService,
		public _matDialog: MatDialog,
	) {
		this.formStep = new UntypedFormGroup({
			campito: new UntypedFormControl(''),
		});
		this.whatsappIdentificator = new UntypedFormGroup({
			identificationNumberWhatsApp: new UntypedFormControl('', [
				Validators.required,
				Validators.minLength(10),
				Validators.maxLength(17),
				Validators.pattern(/^[0-9]\d*$/),
			]),
		});
		this.whatsappMetaToken = new UntypedFormGroup({
			whatsappMetaToken: new UntypedFormControl('', [Validators.required, Validators.maxLength(250)]),
		});
		this.initAll()
		this._membershipService.membershipOfCompany
		.pipe(
			takeUntil(this._unSuscribeAll),
			tap((membershipData) => {
				if (Object.keys(membershipData).length === 0) return membershipData;
				this.whatsappMembership = membershipData.membership.current.WA || false
			})
		)
		.subscribe();
	}
	async initAll() {
		this.initializeData()
		await this.downData()
		await this.downPreferences()
	}
	initializeData() {
		let token = localStorage.getItem('tokenAuth') || '';
		let decode = JSON.parse(atob(token.split('.')[1]));
		this.companyId = decode.company;
	}
	ngOnInit(): void {
		this.getScreenWidth = window.innerWidth;
		this.getScreenHeight = window.innerHeight;
	}

	getHeightTooltip(index: number): string {
		if (this.tooltip[index].icon) {
			switch (index) {
				case 0:
					return '110px';
					break;
				case 1:
					return '60px';
					break;
				case 2:
					return '50px';
					break;
				case 3:
					return '78px';
					break;
				default:
					return '0';
					break;
			}
		} else {
			return '0';
		}
	}
	async saveStep(item: number) {
		if(item == 4) {
			this.finishItemStep = true
		}
		await this.upData(item, this.finishItemStep)
	}
	async upData(item: number, finish: boolean) {
		try {
			if(finish) {
				let up = await lastValueFrom(this.api.put(`/c/${this.companyId}/pref/user`, { whatsappPosition: item, whatsappStep: finish  }))
				console.log(up)
			
			} else {
				let up = await lastValueFrom(this.api.put(`/c/${this.companyId}/pref/user`, { whatsappPosition: item, whatsappStep: finish }))
				console.log(up)
			}
		} catch (e) {
			console.error(e)
		}
	}
	async downData(){
		try {
			let down = await lastValueFrom(this.api.get(`/c/${this.companyId}/pref/user`))
			let stepIndex = down.preferences.whatsappPosition || 0;
				while (stepIndex) {
					stepIndex--;
					this.stepper.next();
				}
			this.finishItemStep = down.preferences.whatsappStep || false;
		} catch (e) {
			console.error(e)
		}
	}
	async downPreferences(){
		try {
			const result: any = await lastValueFrom(this.preferenceService.getPreferences(this.companyId));
			let pref = result
			this.loadingService.hide();
			this.whatsappMetaToken.controls['whatsappMetaToken'].setValue(pref.whatsappMetaToken || '')
			this.whatsappIdentificator.controls['identificationNumberWhatsApp'].setValue(pref.whatsappPhoneNumberId || '')
			
		} catch (e) {
			this.loadingService.hide();
		}
	}
	async savePreferences(whatForm: boolean) {
		try {
			if(this.whatsappMembership) {
				if(whatForm) {
					if(this.whatsappMetaToken.valid) {
						let formValue = {
							whatsappMetaToken: this.whatsappMetaToken.controls['whatsappMetaToken'].value
						}
						let data = await lastValueFrom(this.preferenceService.updatePreferences(this.companyId, formValue));	
					}
				} else {
					if(this.whatsappIdentificator.valid) {
						let formValue = {
							whatsappPhoneNumberId: this.whatsappIdentificator.controls['identificationNumberWhatsApp'].value
						}
						let data = await lastValueFrom(this.preferenceService.updatePreferences(this.companyId, formValue));	
					}
				}
			} else {
				this.openModal()
				this.whatsappMetaToken.controls['whatsappMetaToken'].setValue('')
				this.whatsappIdentificator.controls['identificationNumberWhatsApp'].setValue('')
			}
		} catch (e){
			console.error(e)
		}
	}
	
	ngAfterViewInit() {
		this.stepper.previous();
		console.log('stepper', this.stepper.selectedIndex);
		
	}
	ngOnDestroy() {
		this._unSuscribeAll.next({});
		this._unSuscribeAll.complete();
	}
	setContentTrigger(item: number) {
		switch (item) {
			case 1:
				this.contentTrigger.one = true;
				this.contentTrigger.two = false;
				this.contentTrigger.three = false;
				break;
			case 2:
				this.contentTrigger.one = false;
				this.contentTrigger.two = true;
				this.contentTrigger.three = false;
				break;
			case 3:
				if (this.whatsappIdentificator.controls['identificationNumberWhatsApp'].valid) {
					this.contentTrigger.one = false;
					this.contentTrigger.two = false;
					this.contentTrigger.three = true;
				} else {
					this.whatsappIdentificator.markAllAsTouched();
				}
				break;
			default:
				break;
		}
	}
	finishStep() {
		this.finishItemStep = true;
		const stepElement = document.getElementsByClassName('happy-container')[0];
		if (stepElement) {
			setTimeout(() => {
				stepElement.scrollIntoView({ block: 'end', inline: 'nearest', behavior: 'smooth' });
			}, 400);
		}
	}
	markAsTouchedForm() {
		this.whatsappMetaToken.markAllAsTouched()
	}
	setTabActive() {
		if (this.getScreenWidth >= 600) {
			const stepId = this.stepper._getStepLabelId(this.stepper.selectedIndex);
			const stepElement = document.getElementById(stepId);
			if (stepElement) {
				setTimeout(() => {
					stepElement.scrollIntoView({ block: 'start', inline: 'nearest', behavior: 'smooth' });
				}, 500);
			}
		}
	}
	changeStepper(index: any) {
		const items = document.querySelectorAll('.mat-step-icon-state-number');
		const ind = index.selectedIndex;

		setTimeout(() => {
			for (let i = 0; i <= items.length; i++) {
				if (i <= ind) items[i].classList.add('mat-step-icon-selected');
				else items[i].classList.remove('mat-step-icon-selected');
			}
		}, 100);
	}

	openModal() {
		const confirmDialogRef = this._matDialog.open(ConfirmDialogComponent, {
			data: {
				item: {
					img: 'channel-whatsapp-acquire',
				},
				action: 'create',
				title: 'Adquirir',
			},
			disableClose: false,
			panelClass: 'v-dialog',
			backdropClass: 'v-backdrop',
		});

		confirmDialogRef.afterClosed().subscribe(async (data: any) => {
		});
	}
}
