import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';

@Component({
	selector: 'app-comming-soon',
	standalone: true,
	imports: [CommonModule],
	templateUrl: './comming-soon.component.html',
	styleUrls: ['./comming-soon.component.scss'],
})
export class CommingSoonComponent {}
