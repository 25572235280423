<main
	[ngClass]="instagramMembership ? (instagram ? 'minimal' : 'limited') : instagramMembership == false && instagram == true ? 'anormal' : ''"
>
	<app-title-section
		[title]="contentTitle"
		[description]="contentDescription"
		[titleAlign]="titleAlign"
		[descriptionAlign]="descriptionAlign"
	></app-title-section>

	<form [formGroup]="formFacebookActivation" class="form" [ngClass]="!isChatbotActive ? 'full-size' : ''">
		<h3 class="step_title" *ngIf="!instagram">1. Inicia sesión con Facebook.</h3>
		<div class="form_control__socialmedia" *ngIf="!instagram">
			<div class="form_control__socialmedia___input">
				<button class="facebook_button" (click)="loginConFacebook()" *ngIf="!fbSdk.isLoggedIn">
					<img
						src="../../../../assets/img/facebook-logo-white.png"
						alt="Logo de facebook en color blanco"
						class="facebook_button__img-logo"
					/>
					Iniciar sesión con Facebook
				</button>
				<button class="facebook_button" (click)="cerrarSessionFacebook()" *ngIf="fbSdk.isLoggedIn">
					<img
						src="../../../../assets/img/facebook-logo-white.png"
						alt="Logo de facebook en color blanco"
						class="facebook_button__img-logo"
					/>
					Cerrar sesión con Facebook
				</button>
			</div>
		</div>
		<h4 class="step_title" *ngIf="!instagram">2. Selecciona tu cuenta de negocio.</h4>
		<div class="form_control__select blackArrowDD" *ngIf="!instagram">
			<label for="">Cuenta de negocios</label>
			<mat-form-field appearence="none" style="height: 39px !important">
				<div class="input-context" style="margin-top: -20px !important">
					<mat-select formControlName="number">
						<mat-option value="0"> Seleccionar </mat-option>
						<mat-option
							*ngFor="let page of pages"
							value="{{ page.id }}"
							(click)="onChangeSelect(page)"
							[disabled]="!page.instagram_business_account"
						>
							{{ page.name }}
						</mat-option>
					</mat-select>
				</div>
			</mat-form-field>
		</div>
		<div class="fb-activation-container" *ngIf="instagram">
			<img src="../../../../assets/img/facebook-activation.svg" alt="el canal ya ha sido activado" />
		</div>
		<div class="fb-text-container" *ngIf="!isChatbotActive">
			<p>Recuerda configurar la sección de Chatbot para poder recibir mensajes.</p>
		</div>
		<div class="button-container" *ngIf="instagram && level" [ngClass]="instagram ? 'center-button' : ''">
			<button class="button-chatbot" *ngIf="!isChatbotActive" [routerLink]="['../personalize']">Configurar chatbot</button>
			<button class="button-disconnect" (click)="disconnectChannel()">Desconectar canal de Instagram</button>
		</div>
	</form>
</main>
