<div class="content-main-menu">
	<div class="menu-items">
		<div
			id="menuLateralAttentions"
			class="action-menu-item"
			[routerLink]="['/dashboard/analytics']"
			routerLinkActive="isActive"
			[routerLinkActiveOptions]="{ exact: false }"
		>
			<div class="menu-item-container">
				<div routerLinkActive="isActive" class="left-indicator"></div>
				<v-icon name="vl-esp-home" class="menu-item-container__icon" style="margin-right: 2px;"></v-icon>
			</div>
			<span routerLinkActive="isActive" class="title-item-menu">Inicio</span>
		</div>	
		<div
			id="menuLateralAttentions"
			class="action-menu-item"
			[routerLink]="['/dashboard/attentions']"
			routerLinkActive="isActive"
			[routerLinkActiveOptions]="{ exact: false }"
		>
			<div class="menu-item-container">
				<div routerLinkActive="isActive" class="left-indicator"></div>
				<v-icon name="atencion" class="menu-item-container__icon"></v-icon>
			</div>
			<span routerLinkActive="isActive" class="title-item-menu">Atención</span>
		</div>
		<div
			class="action-menu-item"
			[routerLink]="['/dashboard/channels']"
			routerLinkActive="isActive"
			[routerLinkActiveOptions]="{ exact: false }"
		>
			<div class="menu-item-container">
				<div routerLinkActive="isActive" class="left-indicator"></div>
				<v-icon name="canal" class="menu-item-container__icon"></v-icon>
			</div>
			<span routerLinkActive="isActive" class="title-item-menu">Canales</span>
		</div>
		<div
			class="action-menu-item"
			[routerLink]="['/dashboard/configurations']"
			routerLinkActive="isActive"
			[routerLinkActiveOptions]="{ exact: false }"
		>
			<div class="menu-item-container">
				<div routerLinkActive="isActive" class="left-indicator"></div>
				<v-icon name="ajuste" class="menu-item-container__icon"></v-icon>
			</div>
			<span routerLinkActive="isActive" class="title-item-menu">Ajustes</span>
		</div>
		<div
		class="action-menu-item"
		[routerLink]="['/dashboard/profile']"
		routerLinkActive="isActive"
		[routerLinkActiveOptions]="{ exact: false }"
		>
		<div class="menu-item-container">
			<div routerLinkActive="isActive" class="left-indicator"></div>
			<img [src]="company.imgSrc || 'assets/logos/logo_alternativo.png'" sizes="cover" class="logo-menu" />
		</div>
		<span routerLinkActive="isActive" class="title-item-menu">Perfil</span>
	</div>

	
	</div>
</div>
