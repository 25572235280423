import { Component, HostListener, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { ApiService } from '@services/backend/api.service';
import { AppsService } from '@services/backend/apps.service';
import { ToastService } from '@services/backend/toast.service';
import { LoadingService } from '@services/components/loading.service';
import { lastValueFrom } from 'rxjs';
import { environment } from 'src/environments/environment';

@Component({
	selector: 'app-widget',
	templateUrl: './widget.component.html',
	styleUrls: ['./widget.component.scss'],
})
export class WidgetComponent implements OnInit {
	contentTitle: string = ' Integración Web y Móvil - Widget ';
	contentDescription: string =
		'El widget se instala en tu página web o aplicación móvil de manera sencilla, permite tener una atención fácil y rápida para tus clientes y/o usuarios. Te explicamos cómo integrarlo dentro de tus sistemas.';
	panelOpenState = false;
	tuToken: string = 'TU_TOKEN';
	assetsStyleProd: string = 'https://s3.amazonaws.com/widget.videolink.app/hook/styles.css';
	assetsStyleQa: string = 'https://widgetqa.s3.amazonaws.com/assets/css/style.css';
	assetsStyle: string = environment.production ? this.assetsStyleProd : this.assetsStyleQa;
	assetsJsProd: string = 'https://s3.amazonaws.com/widget.videolink.app/hook/videolink.js';
	assetsJsQa: string = 'https://widgetqa.s3.amazonaws.com/assets/js/script.js';
	assetsJs: string = environment.production ? this.assetsJsProd : this.assetsJsQa;
	contentFirst: string = `<!DOCTYPE html>
         <html lang="en">
           <body>
            <widget-videolink
            token="`;
	contentFirstTwo: string = `"
            mail="no-reply@mail.com"
            position="left">
          </widget-videolink>
          <link
            rel="stylesheet"
            href="${this.assetsStyle}"
          />
            <script src="${this.assetsJs}"></script>
            <script>
              window.vlGlobal.start(
               "`;
	contentFirstThree: string = `",
               "no-reply@mail.com",
               "left"
              );
        </script>
      </body>
</html>`;
	contentSecond: string = `<widget-videolink token="`;
	contentSecondTwo: string = `"></widget-videolink>`;
	contentThree: string = `<widget-videolink token="`;
	contentThreeTwo: string = `" mail="no-reply@mail.com"></widget-videolink>`;
	contentFour: string = `<widget-videolink token="`;
	contentFourTwo: string = `" mail="no-reply@mail.com" position="left"></widget-videolink>`;
	contentFive: string = `<link rel="stylesheet" href="${this.assetsStyle}" />
<script src="${this.assetsJs}"></script>
<script>
	window.vlGlobal.start(
	  "`;
	contentFiveTwo: string = `",
	  "no-reply@mail.com",
	  "left"
	);
</script>`;
	contentSix: string = `<!DOCTYPE html>
<html lang="en">
  <body>
   <widget-videolink
   token="`;
	contentSixTwo: string = `"
   mail="no-reply@mail.com"
   position="left">
 </widget-videolink>
 <link
   rel="stylesheet"
   href="${this.assetsStyle}"
 />
   <script src="${this.assetsJs}"></script>
   <script>
     window.vlGlobal.start(
      "`;
	contentSixThree: string = `",
      "no-reply@mail.com",
      "left"
     );
</script>
</body>
</html>`;

	public cdnContactCenter: string = environment.cdnWidget + '/autoload.js';
	public codes: string[] = [
		`<script src ="${this.cdnContactCenter}"></script>`,
		`<videolink-wid token="APP_TOKEN data="JSON_Data_VideoLink"> </videolink-wid>`,
		`'{
      "clientId" : "example@example.com"
      "name" : "John Dave"
      "email" : "example@example.com"
      "phone" : "12345678900"
      "data" : {
      "DNI" : "12345676"
      "Licence" : "Free"
      //...
      }'
     }'`,
		`'{
      "clientId" : "unknown@unknown.com",
      "name" : "unknown",
      "email" : "unknown@unknown.com",
      "phone" : "00000000000"
     }'`,
		`
     <!DOCTYPE html >
      <html>
      <head>
      <title> Página de ejemplo </title>
      </head>
      <body>
      <videolink-wid token="APP_TOKEN data=" '{
      "clientId" : "unknown@unknown.com",
      "name" : "unknown",
      "email" : "unknown@unknown.com",
      "phone" : "00000000000"
      }'"> </videolink-wid>

      <script src ="${this.cdnContactCenter}"></script>
      </body>
      </html>
     `,
	];

	public getScreenWidth: any;
	public getScreenHeight: any;
	companyId: string = '';
	listItemsApps: any[] = [];
	formToken: FormGroup;
	selectAppForm: FormGroup;
	appsList: Array<any> = [];
	urlApp: URL = new URL('https://videolink.app');
	@HostListener('window:resize', ['$event'])
	onWindowResize() {
		this.getScreenWidth = window.innerWidth;
		this.getScreenHeight = window.innerHeight;
	}
	constructor(
		private appsService: AppsService,
		private loadingService: LoadingService,
		private toastService: ToastService,
		private router: Router,
		private _apiService: ApiService
	) {
		this.formToken = new FormGroup({
			tokenItem: new FormControl('0'),
		});
		this.initAll();
		this.selectAppForm = new FormGroup({
			app: new FormControl('0'),
		});
	}

	ngOnInit(): void {
		this.getScreenWidth = window.innerWidth;
		this.getScreenHeight = window.innerHeight;
	}
	async initAll() {
		this.loadingService.initiate();
		await this.getInfoApps();
		await this.getAppsData();
	}
	async getInfoApps() {
		try {
			let token = localStorage.getItem('tokenAuth') || '';
			let decode = JSON.parse(atob(token.split('.')[1]));
			this.companyId = decode.company;
			const result: any = await lastValueFrom(this.appsService.getPreferences(this.companyId));
			this.listItemsApps = result.data.filter(({ urlCanUpdate }: any) => urlCanUpdate === true);
			this.loadingService.hide();
		} catch (e: any) {
			this.loadingService.hide();
			this.toastService.initiate({
				title: 'Error',
				content: 'Ha ocurrido un error inesperado.',
				type: 'error',
			});
			console.error(e);
		}
	}
	changeToken(id: string) {
		if (id != '0') {
			let data = this.listItemsApps.find((item) => item._id == id);
			this.tuToken = data.token;
		} else {
		}
	}
	textToClipboard(text: string) {
		if (navigator.clipboard && window.isSecureContext) {
			navigator.clipboard.writeText(text);
			this.notifyCopy();
		} else {
			let temp = document.createElement('textarea');
			temp.value = text;
			temp.style.position = 'fixed';
			temp.style.left = '-999999px';
			temp.style.top = '-999999px';
			document.body.appendChild(temp);
			temp.focus();
			temp.select();
			document.execCommand('copy');
			temp.remove();
			this.notifyCopy();
		}
	}
	changeApp(item: string) {
		this.router.navigate(['dashboard', 'channels', 'widget'], {
			queryParams: { app: item },
		});
		this.selectAppForm.controls['app'].setValue(item);
		// console.log(this.selectAppForm.value, ' valuito');
		this.urlApp = this.validarURL('https://videolink.app');
		if (item == '0') {
			this.tuToken = 'TU_TOKEN';
			// console.log('sisoy');
			return;
		}

		this.urlApp = this.validarURL(this.appsList.find((data: any) => data._id == item).url);
		// console.log('now url ', this.urlApp);
		this.tuToken = this.listItemsApps.find((data) => data._id == item).token || 'TU_TOKEN';
		// console.log(this.tuToken, ' caramba ñero');
	}
	notifyCopy() {
		this.toastService.initiate({
			title: 'Éxito',
			content: 'Código copiado en el porta papeles',
			type: 'success',
		});
	}
	validarURL(miurl: string) {
		try {
			new URL(miurl);
			return new URL(miurl);
		} catch (err) {
			return new URL('https://' + miurl);
		}
	}

	async getAppsData() {
		try {
			let result = await lastValueFrom(this._apiService.get(`/apps/v2/list`));
			this.appsList = result.data.filter(({ urlCanUpdate }: any) => urlCanUpdate === true);
			this.listItemsApps = this.appsList.filter(({ urlCanUpdate }: any) => urlCanUpdate === true);

			// let theApp = this.appsList.find((data: any) => data.isContactCenter == true);

			if (this.selectAppForm.controls['app'].value == '') {
				this.selectAppForm.controls['app'].setValue('0');
				this.urlApp = this.validarURL('https://videolink.app');
			} else {
				this.urlApp = this.validarURL(result.data.find((data: any) => data._id == this.selectAppForm.controls['app'].value).url);
			}
		} catch (e: any) {
			console.error(e);
		}
	}
}
