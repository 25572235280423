<main class="personalize" *ngIf="isLoaded && whastappActivateState">
	<app-title-section [title]="contentTitle" [description]="contentDescription"></app-title-section>
	<section class="header__">
		<h3 class="header___title">Personalización del canal de WhatsApp</h3>
	</section>
	<div class="limiter">
		<form class="form-container" [formGroup]="formWhatsappPersonalize">
			<div class="form-control">
				<div class="form-control_input-container" *ngFor="let item of littleInputs">
					<label [for]="item.id">{{ item.title }}</label>
					<input
						type="text"
						[formControlName]="item.name"
						class="form-control_input-container_input"
						[id]="item.id"
						maxlength="{{ item.maxLength }}"
						minlength="{{ item.minLength }}"
					/>
					<span
						class="text-input-warning"
						*ngIf="formWhatsappPersonalize.controls[item.name].getError('required') && formWhatsappPersonalize.controls[item.name].touched"
					>
						{{ item.textValidationRequired }}
					</span>
					<span
						class="text-input-warning"
						*ngIf="
							formWhatsappPersonalize.controls[item.name].getError('minlength') &&
							(formWhatsappPersonalize.controls[item.name].dirty || formWhatsappPersonalize.controls[item.name].touched)
						"
					>
						{{ item.textValidationMin }}
					</span>
					<span
						class="text-input-warning"
						*ngIf="
							formWhatsappPersonalize.controls[item.name].getError('maxlength') &&
							(formWhatsappPersonalize.controls[item.name].touched || formWhatsappPersonalize.controls[item.name].dirty)
						"
					>
						{{ item.textValidationMax }}
					</span>
				</div>
			</div>
			<div class="form-control-b">
				<div class="form-control-b-data-field" *ngFor="let itemB of bigInputs">
					<label [for]="itemB.id" class="message-label"> {{ itemB.title }}</label>
					<textarea
						[id]="itemB.id"
						[formControlName]="itemB.name"
						[attr.maxlength]="itemB.maxLength"
						[attr.minLength]="itemB.minLength"
						[placeholder]="itemB.placeholder"
					></textarea>
					<span
						class="text-input-warning"
						*ngIf="
							formWhatsappPersonalize.controls[itemB.name].getError('required') &&
							(formWhatsappPersonalize.controls[itemB.name].touched || formWhatsappPersonalize.controls[itemB.name].dirty)
						"
					>
						{{ itemB.textValidationRequired }}
					</span>
					<span
						class="text-input-warning"
						*ngIf="
							formWhatsappPersonalize.controls[itemB.name].getError('minlength') &&
							(formWhatsappPersonalize.controls[itemB.name].touched || formWhatsappPersonalize.controls[itemB.name].dirty)
						"
					>
						{{ itemB.textValidationMin }}
					</span>
					<span
						class="text-input-warning"
						*ngIf="
							formWhatsappPersonalize.controls[itemB.name].getError('maxlength') &&
							(formWhatsappPersonalize.controls[itemB.name].touched || formWhatsappPersonalize.controls[itemB.name].dirty)
						"
					>
						{{ itemB.textValidationMax }}
					</span>
				</div>
			</div>

			<div class="button-container">
				<button class="button-form" (click)="submitForm()">Guardar cambios</button>
			</div>
		</form>
		<div class="phone-container">
			<div class="phone-container__img">
				<img src="../../../../assets/img/whatsapp-personalize-img.png" alt="" />
			</div>
		</div>
	</div>
</main>

<main *ngIf="!whastappActivateState && isLoaded" class="no-loaded">
	<app-title-section [title]="contentTitle" [description]="contentWhastappDescription"></app-title-section>
	<div class="limiter-container">
		<div class="img-container">
			<img src="../../../../assets/img/activation.svg" alt="" />
		</div>
	</div>
</main>
