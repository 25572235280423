<form [formGroup]="form" class="vl-contact">
	<div class="vl-contact-form" *ngIf="done">
		<div class="hk-form-fld" *ngFor="let item of fields; let i = index" [ngStyle]="{ order: item.order }">
			<ng-container>
				<label class="hk-form-fld-label" [for]="'fld-' + i">{{ item.label }} {{ item.required ? '*' : '' }}</label>

				<ng-container [ngSwitch]="item._id">
					<ng-container *ngSwitchCase="'regex'">
						<input
							class="hk-form-fld-input"
							type="text"
							[name]="'fld-' + i"
							[id]="'fld-' + i"
							[placeholder]="item.example ? item.example : ''"
							[formControlName]="item.label + i"
							[attr.disabled]="true"
						/>
					</ng-container>
					<ng-container *ngSwitchCase="'select'">
						<select
							class="hk-form-fld-select"
							[name]="'fld-' + i"
							[id]="'fld-' + i"
							[formControlName]="item.label + i"
							[attr.disabled]="true"
						>
							<option value="" hidden default>Selecione:</option>
							<option *ngFor="let option of item.options" [value]="option.value">{{ option }}</option>
						</select>
					</ng-container>
					<ng-container *ngSwitchCase="'yes-no'">
						<div class="hk-form-fld-yes-no">
							<div class="hk-form-fld-yes-no__item">
								<input
									type="radio"
									[name]="item._id + i"
									[id]="'fld-' + i"
									[formControlName]="item.label + i"
									value="si"
									[attr.disabled]="true"
								/>
								<label for="fld-0-yes">Si</label>
							</div>
							<div class="hk-form-fld-yes-no__item">
								<input
									type="radio"
									[name]="item._id + i"
									[id]="'fld-' + i"
									[formControlName]="item.label + i"
									value="no"
									[attr.disabled]="true"
								/>
								<label for="fld-0-no">No</label>
							</div>
						</div>
					</ng-container>
					<ng-container *ngSwitchCase="'download'">
						<p class="hk-form-fld-download">
							<a href="javascript:void(0)">{{ item.label }}</a>
						</p>
						<li-input-file
							[multiple]="false"
							style="width: 100%"
							(changesValue)="onChangesFile($event, item.label, item.label + i)"
						></li-input-file>
						<!-- -->
					</ng-container>
					<ng-container *ngSwitchCase="'upload'">
						<li-input-file
							[multiple]="false"
							style="width: 100%"
							(changesValue)="onChangesFile($event, item.label, item.label + i)"
						></li-input-file>
						<!--  -->
					</ng-container>
					<ng-container *ngSwitchCase="'email'">
						<input
							class="hk-form-fld-input"
							type="email"
							placeholder="email@empresa.com"
							[name]="'fld-' + i"
							[id]="'fld-' + i"
							[placeholder]="item.example ? item.example : ''"
							[formControlName]="item.label + i"
							[attr.disabled]="true"
						/>
					</ng-container>
					<ng-container *ngSwitchCase="'phone'">
						<input
							class="hk-form-fld-input"
							type="text"
							placeholder="Ejemplo: 4121234578"
							[name]="'fld-' + i"
							[id]="'fld-' + i"
							[placeholder]="item.example ? item.example : ''"
							[formControlName]="item.label + i"
							[attr.disabled]="true"
						/>
					</ng-container>
					<ng-container *ngSwitchCase="'textarea'">
						<textarea
							class="hk-form-fld-textarea"
							type="text"
							placeholder="Ingrese un texto"
							[name]="'fld-' + i"
							rows="2"
							[id]="'fld-' + i"
							[placeholder]="item.example ? item.example : ''"
							[formControlName]="item.label + i"
							[attr.disabled]="true"
						></textarea>
					</ng-container>
					<ng-container *ngSwitchDefault>
						<input
							class="hk-form-fld-input"
							type="text"
							[name]="'fld-' + i"
							[id]="'fld-' + i"
							[placeholder]="item.example ? item.example : ''"
							[formControlName]="item.label + i"
							[attr.disabled]="true"
						/>
					</ng-container>
				</ng-container>
			</ng-container>
		</div>
	</div>
	<div class="vl-button-row">
		<app-forms-contact-btn [background]="btnColor">Volver</app-forms-contact-btn>
		<app-forms-contact-btn [background]="btnColor" [disabled]="true">Enviar</app-forms-contact-btn>
	</div>
	<div class="vl-contact-divider"></div>
</form>
