import { MatSelect } from '@angular/material/select';
import {
	Component,
	OnInit,
	Input,
	Output,
	EventEmitter,
	AfterViewInit,
	OnChanges,
	SimpleChanges,
	ViewChild,
	HostListener,
} from '@angular/core';

@Component({
	selector: 'v-select',
	templateUrl: './v-select.component.html',
	styleUrls: ['./v-select.component.scss'],
})
export class VSelectComponent implements OnInit, AfterViewInit, OnChanges {
	@HostListener('click')
	onClick() {
		if (this.select && !this.select.panelOpen) {
			this.select.toggle();
		}
	}

	@ViewChild('select') select!: MatSelect;

	@Input('value') value: any = '';
	@Input('listDisabledTitle') listDisabledTitle: string = '';
	@Input('customClass') customClass: any = '';
	@Input('optionCustomClass') optionCustomClass: any = '';
	@Input('options') options: any = [];
	@Input('multiple') multiple: boolean = false;
	@Input('disabled') disabled: boolean = false;
	@Input('placeholder') placeholder: string = '';
	@Input('config') config: {
		multiple?: boolean;
		withIcon?: boolean;
		onlyIcon?: boolean;
		tofilter?: boolean;
	} = {
		multiple: false,
		withIcon: false,
		onlyIcon: false,
		tofilter: false,
	};
	@Input() listDisabled: string[] = [];
	@Output('changeSelection') changeSelection: EventEmitter<any> = new EventEmitter();

	public inView: any = [];
	public infoViewMulti: any = [];
	public infoSelected: any = [];
	public isOpenPanel: boolean = false;

	constructor() {}

	ngOnInit(): void {}

	ngAfterViewInit(): void {
		this.setValues();
	}
	ngOnChanges(changes: SimpleChanges): void {
		this.setValues();
	}

	setValues() {
		if (!this.config.multiple) {
			if (this.value) {
				this.infoSelected = this.options.find((option: any) => option.value === this.value);
				this.infoSelected = { ...this.infoSelected, name: this.maxThisCharacter(this.infoSelected?.name, 19) };

				if (this.config.onlyIcon) {
					this.inView = this.infoSelected.icon;
				} else if (this.config.withIcon) {
					this.inView = { icon: this.infoSelected.icon, name: this.infoSelected.name };
				} else {
					this.inView = { name: this.infoSelected.name };
				}
			}
		} else {
			if (this.value.length > 0) {
				this.infoSelected = this.options.filter((option: any) => this.value.includes(option.value));
				this.infoViewMulti = [this.infoSelected[0], this.infoSelected[1]].filter((item) => item !== undefined);
				if (this.infoViewMulti[0]) {
					this.infoViewMulti[0] = { ...this.infoViewMulti[0], name: this.maxThisCharacter(this.infoViewMulti[0].name, 19) };
				}
			}
		}
	}

	change($event: any) {
		const { value } = $event;
		if (this.config.multiple) {
			this.infoSelected = this.options.filter((option: any) => [...value].includes(option.value));

			if (this.infoSelected.length <= 2) {
				this.infoViewMulti = [this.infoSelected[0], this.infoSelected[1]].filter((item) => item !== undefined);
			}

			this.changeSelection.emit(this.infoSelected.map((option: any) => option.value));
		} else {
			this.infoSelected = this.options.find((option: any) => option.value === value);

			if (this.config.onlyIcon) {
				this.inView = this.infoSelected.icon;
			} else if (this.config.withIcon) {
				this.inView = { icon: this.infoSelected.icon, name: this.infoSelected.name };
			} else {
				this.inView = { name: this.infoSelected.name };
			}
			this.changeSelection.emit(this.infoSelected.value);
		}
	}

	maxThisCharacter(text: string, max: number): string {
		if (text.length <= max) {
			return text;
		} else {
			return text.slice(0, max).concat('...');
		}
	}

	iconOfOption(value: string): string {
		return this.options.find((option: any) => option.value === value)?.icon;
	}
	checkOkay(value: any, name: string): boolean {
		let disabledItem = this.listDisabled.findIndex((data) => data == name);
		if (disabledItem == -1) {
			return false;
		} else {
			return true;
		}
	}
	checkOkayTitle(name: string): string {
		let disabledItem = this.listDisabled.findIndex((data) => data == name);
		if (disabledItem == -1) {
			return '';
		} else {
			return this.listDisabledTitle || 'Si desea agregar más usuarios de este tipo modifique su plan.';
		}
	}
}
