import { Component, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ConfirmDialogComponent } from '@components/confirm-dialog/confirm-dialog.component';
import { environment } from '@environments/environment';
import { ApiService } from '@services/backend/api.service';
import { FbsdkService } from '@services/backend/fbsdk.service';
import { MembershipService } from '@services/backend/membership.service';
import { PreferencesService } from '@services/backend/preferences.service';
import { ToastService } from '@services/backend/toast.service';
import { LoadingService } from '@services/components/loading.service';
import { lastValueFrom, Subject, takeUntil, tap } from 'rxjs';

@Component({
	selector: 'app-facebook-activation',
	templateUrl: './facebook-activation.component.html',
	styleUrls: ['./facebook-activation.component.scss'],
})
export class FacebookActivationComponent implements OnInit {
	contentTitle: string = 'Activación del canal de Facebook';
	contentDescription: string = '';
	formFacebookActivation: UntypedFormGroup;
	pages: Array<FBPageData> = [];
	companyId: string = '';
	facebook: boolean = false;
	instagram: boolean = false;
	userID: any;
	access: string = '';
	level: boolean = false;
	access_token: string = '';
	page_id: string = '';
	facebookMembership: boolean = false;
	public _unSuscribeAll: Subject<any> = new Subject();
	descriptionAlign: string = 'left';
	titleAlign: string = 'left';
	isChatbotActive: boolean = true;
	instagramPageId: string = '';
	constructor(
		public fbSdk: FbsdkService,
		private loadingService: LoadingService,
		private preferenceService: PreferencesService,
		private toastService: ToastService,
		public _matDialog: MatDialog,
		private api: ApiService,
		private _membershipService: MembershipService
	) {
		console.log(fbSdk.isLoggedIn);
		this.formFacebookActivation = new UntypedFormGroup({
			number: new UntypedFormControl('0', [Validators.required]),
			page: new UntypedFormControl('0', [Validators.required]),
		});
		this.getData();
		this._membershipService.membershipOfCompany
			.pipe(
				takeUntil(this._unSuscribeAll),
				tap((membershipData) => {
					if (Object.keys(membershipData).length === 0) return membershipData;
					this.facebookMembership = membershipData.membership.current.FB || false;
				})
			)
			.subscribe();
	}
	async getData() {
		this.loadingService.initiate();
		this.initializeData();
		await this.getInfoPreferences();
	}

	initializeData() {
		let token = localStorage.getItem('tokenAuth') || '';
		let decode = JSON.parse(atob(token.split('.')[1]));
		this.companyId = decode.company;
		this.level = decode.level >= 900;
	}
	async getInfoPreferences() {
		try {
			const result: any = await lastValueFrom(this.preferenceService.getPreferences(this.companyId));
			this.loadingService.hide();
			this.facebook = result.facebook || false;
			this.instagram = result.instagram || false;
			if (result.facebook) {
				this.contentDescription = 'El canal de Facebook ya fue activado y configurado.';
				this.titleAlign = 'center';
				this.descriptionAlign = 'center';
				this.isChatbotActive = result.facebookWelcome ? true : false;
			}

			this.formFacebookActivation.controls['number'].setValue(
				this.fbSdk.isLoggedIn && result.facebook ? result.facebookCallbackToken : '0'
			);
			this.formFacebookActivation.controls['page'].setValue(this.fbSdk.isLoggedIn && result.facebook ? result.facebookUsedIdPage : '0');
			this.access_token = result.facebookPageToken || '0';
			this.page_id = result.facebookCallbackToken || '0';
		} catch (e) {
			this.loadingService.hide();
			this.toastService.initiate({
				title: 'Error',
				content: 'Ha ocurrido un error inesperado.',
				type: 'error',
			});
		}
	}
	ngOnInit(): void {
		this.getBussisnessAccount();
		if (this.fbSdk.isLoggedIn) {
			this.getFacebookPage();
		}
	}
	async loginConFacebook() {
		const result = await this.fbSdk.login();
		this.getBussisnessAccount();
		this.access = result.authResponse.accessToken;
		this.userID = result.authResponse.userID;
		this.upPrefUserData(this.userID, this.access);
	}

	async getDataAccount(USER_ID: any, access_token: string) {
		const result = (await this.fbSdk.getInfoUser(USER_ID, access_token)) as any;
	}

	async upPrefUserData(userID: string, access: string) {
		try {
			await lastValueFrom(this.api.put(`/c/${this.companyId}/pref/user`, { facebookAccessToken: access, facebookIdUser: userID }));
		} catch (e) {
			console.log(e);
		}
	}
	ngOnDestroy() {
		this._unSuscribeAll.next({});
		this._unSuscribeAll.complete();
	}
	async getBussisnessAccount() {
		const result = (await this.fbSdk.getBusinessAccounts()) as any;
		console.log({ resultFInal: result });
		this.getFacebookPage();
	}

	async cerrarSessionFacebook() {
		const x = await this.fbSdk.logout();
		this.facebook = false;
		this.pages = [];
		this.formFacebookActivation.controls['number'].setValue('0');
		this.formFacebookActivation.controls['page'].setValue('0');
		this.onChangeSelect({ access_token: '0' });
	}
	async getFacebookPage() {
		this.pages = await this.fbSdk.getUserAccessTokens();

		//const instagram: any = await this.fbSdk.getInstagramBusinessAccount(this.pages[0].id, this.access);
		//if (instagram && instagram.id) this.instagramPageId = instagram.id;
		//console.log({ instagram });
		this.getDataAccount(this.userID, this.access);
	}
	onChangeSelect(data: any) {
		this.access_token = data.access_token;
		this.onSubmit();
	}
	async onSubmit() {
		try {
			if (this.facebookMembership) {
				let objPreference = {
					facebookCallbackToken:
						this.formFacebookActivation.controls['number'].value != '0' ? this.formFacebookActivation.controls['number'].value : '',
					facebookPageToken: this.access_token != '' ? this.access_token : '',
					facebookUsedIdPage:
						this.formFacebookActivation.controls['number'].value != '0' ? this.formFacebookActivation.controls['number'].value : '',
				};
				let objModule = {
					facebook: this.fbSdk.isLoggedIn ? true : false,
				};
				if (this.fbSdk.isLoggedIn) {
					let data = await this.fbSdk.subscribeToApp(
						this.formFacebookActivation.controls['number'].value,
						environment.facebookAppId,
						this.access_token
					);
				}
				let mod = await lastValueFrom(this.preferenceService.enableDisablePreferences(this.companyId, objModule));
				let pref = await lastValueFrom(this.preferenceService.updatePreferences(this.companyId, objPreference));
				this.openModal();
				this.loadingService.hide();
				this.toastService.initiate({
					title: 'Los cambios han sido guardados',
					content: 'Los cambios en el canal de facebook han sido guardados.',
					type: 'success',
				});
			} else {
				this.openModalMembership();
			}
		} catch (e) {
			console.log(e);
		}
	}

	openModal() {
		this.getInfoPreferences();

		const confirmDialogRef = this._matDialog.open(ConfirmDialogComponent, {
			data: {
				item: {
					img: 'channel-facebook',
				},
				action: 'create',
				title: 'Canal de facebook',
			},
			disableClose: false,
			panelClass: 'v-dialog',
			backdropClass: 'v-backdrop',
		});

		confirmDialogRef.afterClosed().subscribe(async (data: any) => {
			this.facebook = true;
		});
	}
	openModalMembership() {
		const confirmDialogRef = this._matDialog.open(ConfirmDialogComponent, {
			data: {
				item: {
					img: 'channel-facebook-acquire',
				},
				action: 'create',
				title: 'Adquirir',
			},
			disableClose: false,
			panelClass: 'v-dialog',
			backdropClass: 'v-backdrop',
		});

		confirmDialogRef.afterClosed().subscribe(async (data: any) => {});
	}
	async disconnectChannel() {
		try {
			let desuscribe = undefined;
			if (!this.instagram) desuscribe = await this.fbSdk.unsubscribeToApp(this.page_id, environment.facebookAppId, this.access_token);
			let objModule = {
				channel: 'facebook',
			};
			let mod = await lastValueFrom(this.api.put(`/c/${this.companyId}/pref/disconnect/channels`, objModule));
			if (mod.code) {
			}
			if (mod.ok) {
				this.formFacebookActivation.controls['page'].setValue('0');
				this.formFacebookActivation.controls['number'].setValue('0');
				this.toastService.initiate({
					title: 'El canal de Facebook ha sido desconectado',
					content: 'El canal de Facebook fue desconectado totalmente.',
					type: 'success',
				});
				this.facebook = false;
				this.contentDescription = '';
				this.titleAlign = 'left';
				this.descriptionAlign = 'left';
				this.isChatbotActive = true;
			}
		} catch (e) {
			console.log(e);
		}
	}
}
