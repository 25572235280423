import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { VlCardComponent } from './vl-card.component';
import { VlCardTitleComponent } from './vl-card-title/vl-card-title.component';
import { VlCardDescriptionComponent } from './vl-card-description/vl-card-description.component';
import { VlCardHeaderComponent } from './vl-card-header/vl-card-header.component';



@NgModule({
  declarations: [
    VlCardComponent,
    VlCardTitleComponent,
    VlCardDescriptionComponent,
    VlCardHeaderComponent
  ],
  imports: [
    CommonModule
  ],
  exports: [
    VlCardComponent,
    VlCardTitleComponent,
    VlCardDescriptionComponent,
    VlCardHeaderComponent

  ]
})
export class VlCardModule { }
