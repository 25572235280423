import { Directive, HostListener } from '@angular/core';
import { ToastService } from '@services/backend/toast.service';

@Directive({
	selector: '[appCopy]',
})
export class CopyDirective {
	@HostListener('click', ['$event'])
	onClick(event: any) {
		console.log(event);
		this.textToClipboard(event.target.innerText);
	}

	constructor(private toasterService: ToastService) {
	}

	textToClipboard(text: string) {
		if (navigator.clipboard && window.isSecureContext) {
			navigator.clipboard.writeText(text);
			this.notifyCopy()
		} else {
			let temp = document.createElement('textarea');
			temp.value = text;
			temp.style.position = 'fixed';
			temp.style.left = '-999999px';
			temp.style.top = '-999999px';
			document.body.appendChild(temp);
			temp.focus();
			temp.select();
			document.execCommand('copy');
			temp.remove();
			this.notifyCopy()
		}
	}

	notifyCopy() {
		this.toasterService.initiate({
			title: 'Éxito',
			content: 'Código copiado en el porta papeles',
			type: 'success',
		});
	}
}
