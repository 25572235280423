<button
  [disabled]="disabled"
  class="vl-btn-abilities first"
  [ngStyle]="{
    color: color.length ? color : '(--vl-client-btn-abilities-color',
    'background-color': background.length
    ? background
    : '(--vl-client-btn-abilities-bg-color'
  }"
  >
  <ng-content></ng-content>
</button>

<!-- [style]="'--btn-forms-abilities:' + sizeButton + ';'" -->