import { Component, Input, OnInit } from '@angular/core';

@Component({
	selector: 'v-icon',
	templateUrl: './v-icon.component.html',
	styleUrls: ['./v-icon.component.scss'],
})
export class VIconComponent implements OnInit {
	@Input('name') name: string = '';
	@Input('classCustom') classCustom?: string = '';
	constructor() {}

	ngOnInit(): void {}
}
