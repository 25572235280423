<main>
  <div
  (click)="closeDialog()" class="close-container">
  <svg (click)="closeDialog()" xmlns="http://www.w3.org/2000/svg" width="19" height="19" viewBox="0 0 19 19" fill="none">
      <path d="M6.06575 5.66617C5.96369 5.66617 5.86175 5.70526 5.78357 5.78251C5.62813 5.93554 5.62813 6.1905 5.78357 6.34594L11.9815 12.5415C12.1361 12.6945 12.3895 12.6945 12.5449 12.5415C12.6996 12.3884 12.6996 12.1311 12.5449 11.978L6.347 5.78251C6.26975 5.70526 6.16769 5.66617 6.06562 5.66617H6.06575Z" fill="#757575"/>
      <path d="M12.2647 5.6671C12.1626 5.6671 12.0606 5.70619 11.9825 5.78344L5.78455 11.98C5.62911 12.133 5.62911 12.3904 5.78455 12.5434C5.93998 12.6965 6.19254 12.6965 6.34798 12.5434L12.5459 6.3479C12.7013 6.19246 12.7013 5.93748 12.5459 5.78446C12.4687 5.70641 12.3674 5.66732 12.2645 5.66732L12.2647 5.6671Z" fill="#757575"/>
      <path d="M9.16504 8.3528e-07C4.11134 8.3528e-07 0 4.11237 0 9.16504C0 14.2194 4.11168 18.3301 9.16504 18.3301C14.2184 18.3301 18.3301 14.2194 18.3301 9.16504C18.3301 4.11237 14.2184 8.3528e-07 9.16504 8.3528e-07V8.3528e-07ZM9.16504 17.5332C4.55058 17.5332 0.796856 13.7802 0.796856 9.16504C0.796856 4.55127 4.55058 0.796857 9.16504 0.796857C13.7795 0.796857 17.5332 4.55127 17.5332 9.16504C17.5332 13.7802 13.7795 17.5332 9.16504 17.5332Z" fill="#757575"/>
      </svg>
  </div>
  <div class="limiter">
    <div class="content" style="max-width: 1171px;">

      <div class="preview-container {{dataPreviewer.nameStyle}}">
        <section class="preview-section">

          <div class="preview-content">
            <div
              class="pre-banner"
             [ngStyle]="{'background-color': dataPreviewer.colorBackground }"
            >
              <div class="logo-container">
                <img [src]="dataPreviewer.file" alt="logo" />
              </div>
              <div class="title-container">
                <h2>
                  Completa tus datos para la atención
                </h2>
              </div>
            </div>
            <div class="pre-content">
              <h3 class="title-pre" *ngIf="dataPreviewer.checkStatus">
                  Regístrate
              </h3>
             <div class="forms-container" *ngIf="dataPreviewer.dataContactItem.length > 0 && dataPreviewer.checkStatus">
               <ng-container *ngFor="let item of dataPreviewer.dataContactItem; let i = index">
                
               <ng-container *ngIf="item.type != 'info' && item.type != 'phone'">
                <div class="data-field item-field" [ngStyle]="{'order':item.order}">
                  <div class="input-field">
                    <input type="text" [placeholder]="item.placeholder" class="form-control" disabled>
                  </div>
                </div>
               </ng-container> 
               <ng-container *ngIf="item.state == 'used' && item.type == 'phone'">
                 <div class="data-field phone-container" [ngStyle]="{'order':item.order}">
                 <div class="select-number">
                      <div class="text">+58</div>
                      <label class="label-text">
                       <img src="../../../../assets/img/icon-down.svg" alt="">
                      </label>
                    </div>
                    <div class="input-field phone-field" >
                      <input type="text" placeholder="000 0000000" class="form-control" disabled>
                    </div>
                  </div>
               </ng-container>
              </ng-container>
             
            </div>
              <div class="buttons-container" *ngIf="dataPreviewer.checkStatus">
                <button class="button dark">
                  Volver
                </button>
                <button class="button light">
                  Siguiente
                </button>
              </div>
              <div class="help-container" *ngIf="dataPreviewer.checkStatus">
                <div class="one-line">
                  <p>¿Necesitas ayuda?</p>
                  <a>Contáctanos </a>
                </div>
                <div class="space-content"></div>
              </div>
            </div>
          </div>
         
        </section>
        </div>
    </div>
    <div class="space-content">
    </div>
  </div>
</main>
