<div [ngClass]="['vlTk__overlay__Options', isOpen ? 'active' : '']" (click)="close()">
	<div
		[ngClass]="['vlTk__drawerOptions', config.isMulti ? 'multi' : '', config.type === 'icon' ? 'icon' : '', isOpen ? 'open' : '']"
		[ngStyle]="{
			'--top': coordinates.y ? coordinates.bottom + 5 + 'px' : 'calc(100% + 5px)',
			'--left': coordinates.x ? coordinates.x + 'px' : 0,
			'--maxW': coordinates.width ? coordinates.width + 'px' : 'initial'
		}"
		(scroll)="checkScroll($event)"
	>
		<div class="vlTk__drawerOptions__options">
			<ng-container *ngFor="let option of config.options">
				<button
					type="button"
					[ngClass]="['vlTk__drawerOptions__options__option', optionsSelected.includes(option.value) ? 'selected' : '']"
					(click)="actionClick(option.value, $event)"
					[disabled]="option.disabled"
				>
					<ng-container [ngTemplateOutlet]="checkBox" *ngIf="config.isMulti"></ng-container>
					<ng-container
						[ngTemplateOutlet]="contentIcon"
						*ngIf="config.type === 'icon'"
						[ngTemplateOutletContext]="{ item: option }"
					></ng-container>
					<p class="vlTk__drawerOptions__options__option__label">{{ option.label }}</p>
				</button>
			</ng-container>
		</div>
	</div>
</div>

<ng-template #checkBox>
	<div [ngClass]="['vlTk__drawerOptions__options__option__checkbox']">
		<ng-container [ngTemplateOutlet]="checkIcon"></ng-container>
	</div>
</ng-template>

<ng-template #contentIcon let-item="item">
	<vl-icon class="vlTk__drawerOptions__options__option__icon" [name]="item.value"></vl-icon>
</ng-template>

<ng-template #checkIcon>
	<svg class="check" viewBox="0 0 8 6" fill="none" xmlns="http://www.w3.org/2000/svg">
		<path
			d="M7.47332 1.13926L3.4733 5.13928C3.34841 5.2635 3.17944 5.33314 3.00333 5.33314C2.82721 5.33314 2.65824 5.2635 2.53336 5.13928L0.533346 3.13927C0.38636 2.96762 0.33636 2.73324 0.400423 2.51661C0.464486 2.29987 0.634018 2.13044 0.850653 2.06638C1.06728 2.00221 1.30177 2.05232 1.47331 2.19931L3.00001 3.726L6.52659 0.199411C6.69824 0.0524247 6.93262 0.00231288 7.14937 0.0664874C7.366 0.130551 7.53554 0.299972 7.5996 0.516719C7.66366 0.733348 7.61366 0.96772 7.46667 1.13938L7.47332 1.13926Z"
			class="fill"
		/>
	</svg>
</ng-template>
