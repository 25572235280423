import { ToastService } from '@services/backend/toast.service';
import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Route, Router, RouterStateSnapshot, UrlSegment, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthService } from '../services/storage/auth.service';

@Injectable({
	providedIn: 'root',
})
export class HashTokenGuard  {
	constructor(private _auth: AuthService, private _router: Router, private _notificationSrc: ToastService) {}

	canActivate(
		route: ActivatedRouteSnapshot,
		state: RouterStateSnapshot
	): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
		return this.validateSession();
	}

	canLoad(route: Route, segments: UrlSegment[]): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
		return this.validateSession();
	}

	validateSession(): boolean {
		try {
			const { exp } = this._auth.getInfo();
			const isExpired = new Date(0);
			isExpired.setUTCSeconds(exp);

			if (new Date() > isExpired) throw { code: 401 };

			return true;
		} catch (error: any) {
			if (error?.code === 401) {
				this._notificationSrc.initiate({
					type: 'error',
					title: '¡Sesión finalizada!',
					content: 'Su sesión ha expirado',
					time: 4000,
				});
			}
			this._router.navigate(['/auth/login']);
			this._auth.clearAll();
			return false;
		}
	}
}
