<main *ngIf="!whatsapp">
	<app-title-section [title]="contentTitle" [description]="contentDescription"></app-title-section>

	<form [formGroup]="formWhatsAppActivation" class="form">
		<h3 class="step_title bottom_5">1. Inicia sesión con la cuenta de Facebook del administrador de la cuenta comercial.</h3>
		<div class="form_control__socialmedia">
			<div class="form_control__socialmedia___input">
				<button class="facebook_button" (click)="loginConFacebook()" *ngIf="!fbSdk.isLoggedIn">
					<img
						src="../../../../assets/img/facebook-logo-white.png"
						alt="Logo de facebook en color blanco"
						class="facebook_button__img-logo"
					/>
					Iniciar sesión con Facebook
				</button>
				<button class="facebook_button" (click)="cerrarSessionFacebook()" *ngIf="fbSdk.isLoggedIn">
					<img
						src="../../../../assets/img/facebook-logo-white.png"
						alt="Logo de facebook en color blanco"
						class="facebook_button__img-logo"
					/>
					Cerrar sesión con Facebook
				</button>
			</div>
		</div>

		<h4 class="step_title up_5 bottom_5">2. Seleccionar cuenta comercial.</h4>
		<div class="form_control__select blackArrowDD">
			<label for="">Cuentas de negocios</label>
			<mat-form-field appearence="none" style="height: 39px !important">
				<div class="input-context" style="margin-top: -20px !important">
					<mat-select formControlName="businessAccount">
						<mat-option value="0" disabled> Seleccionar </mat-option>
						<mat-option *ngFor="let page of whatsappPages" [value]="page.id" (click)="getWhatsappBusiness(page.id)">
							{{ page.name }}
						</mat-option>
					</mat-select>
				</div>
			</mat-form-field>
		</div>
		<h5 class="step_title up_5 bottom_up">3. Seleccionar tu cuenta de WhatsApp</h5>
		<div class="form_control__select">
			<label for="">Cuenta de WhatsApp</label>
			<mat-form-field appearence="none" style="height: 39px !important">
				<div class="input-context" style="margin-top: -20px !important">
					<mat-select formControlName="whatsappAccount">
						<mat-option value="0" disabled> Seleccionar </mat-option>
						<mat-option *ngFor="let page of whatsappAccount" [value]="page.id" (click)="setWhatsAppNumber(page.id)">
							{{ page.name }}
						</mat-option>
					</mat-select>
				</div>
			</mat-form-field>
		</div>
		<p class="step_title up_5">4. Agregar URL y token de verificación en Meta For Developers</p>
		<ul>
			<li class="li-text">Whatsapp -> Configuración -> Editar (pegar url y token)</li>
		</ul>
	</form>
	<form [formGroup]="urlDevCallVideo">
		<div class="form_control_container">
			<div class="sameline">
				<label for="url-dev">URL de devolución de llamada de Videolink</label>
				<hk-tooltip-info
					text="Copia la url de devolución de llamada de videolink y pégala en el campo correspondiente en facebook."
					position="left"
				></hk-tooltip-info>
			</div>
			<div class="input-container">
				<input [disabled]="true" type="text" id="url-dev" formControlName="urlDev" placeholder="https://wa.videolink.app/wa/<company>" />
				<div class="copy">
					<app-copy-b [contentCopy]="urlDevCallVideo.controls['urlDev'].value"></app-copy-b>
				</div>
			</div>
		</div>
	</form>
	<form [formGroup]="tokenVerify">
		<div class="form_control_container">
			<div class="sameline">
				<label for="company-to">Token de verificación</label>
				<hk-tooltip-info
					text="Copia el token de verificación y pégalo en el campo correspondiente en facebook."
					position="left"
				></hk-tooltip-info>
			</div>
			<div class="input-container">
				<input type="text" id="company-to" formControlName="tokenV" placeholder="<company>" />
				<div class="copy">
					<app-copy-b [contentCopy]="tokenVerify.controls['tokenV'].value"></app-copy-b>
				</div>
			</div>
		</div>
	</form>
	<div class="video-responsive">
		<iframe
			src="https://www.youtube.com/embed/_xJs2ePMmf4"
			title="YouTube video player"
			frameborder="0"
			allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
			allowfullscreen
		></iframe>
	</div>
	<p class="step_title up_5">5. Agregar Webhooks</p>
	<ul>
		<li class="li-text">WhatsApp -> Configuración -> Campos del webhook (habilitar campo “messages”)</li>
	</ul>
	<div class="img-container">
		<img src="../../../../assets/img/whatsapp-activation-img.png" alt="imagen de campos de facebook" class="img-activation" />
	</div>
</main>

<main *ngIf="whatsapp" class="inactive">
	<app-title-section
		title="Activación del canal de WhatsApp"
		description=""
		[titleAlign]="titleAlign"
		[descriptionAlign]="descriptionAlign"
	></app-title-section>
	<div class="fb-activation-container" *ngIf="whatsapp">
		<img src="../../../../assets/img/facebook-activation.svg" alt="el canal ya ha sido activado" />
	</div>
	<div class="fb-text-container" *ngIf="!isChatbotActive">
		<p>Recuerda configurar la sección de Chatbot para poder recibir mensajes.</p>
	</div>
	<div class="button-container" *ngIf="whatsapp && level" [ngClass]="whatsapp ? 'center-button' : ''">
		<button class="button-chatbot" *ngIf="!isChatbotActive" [routerLink]="['../personalize']">Configurar chatbot</button>
		<button class="button-disconnect" (click)="disconnectChannel()">Desconectar canal de WhatsApp</button>
	</div>
	<div class="accordion-container" (click)="opened = !opened">
		<div class="text-container">
			<p class="big">Agregar URL y token de verificación en Meta for Developers</p>
		</div>
		<div class="text-container end">
			<p class="little" *ngIf="!opened">Ver más</p>
			<p class="little" *ngIf="opened">Ver menos</p>
			<div class="icon-container">
				<img src="../../../../assets/img/abajo.svg" *ngIf="!opened" alt="icono señalando hacia abajo" />
				<img src="../../../../assets/img/arriba.svg" *ngIf="opened" alt="icono señalando hacia arriba" />
			</div>
		</div>
	</div>

	<div class="accordion-content" *ngIf="opened">
		<form [formGroup]="formWhatsAppActivation" class="form">
			<ul>
				<li class="li-text">Whatsapp -> Configuración -> Editar (pegar url y token)</li>
			</ul>
		</form>
		<form [formGroup]="urlDevCallVideo">
			<div class="form_control_container">
				<div class="sameline">
					<label for="url-dev">URL de devolución de llamada de Videolink</label>
					<hk-tooltip-info
						text="Copia la url de devolución de llamada de videolink y pégala en el campo correspondiente en facebook."
						position="left"
					></hk-tooltip-info>
				</div>
				<div class="input-container">
					<input [disabled]="true" type="text" id="url-dev" formControlName="urlDev" placeholder="https://wa.videolink.app/wa/<company>" />
					<div class="copy">
						<app-copy-b [contentCopy]="urlDevCallVideo.controls['urlDev'].value"></app-copy-b>
					</div>
				</div>
			</div>
		</form>
		<form [formGroup]="tokenVerify">
			<div class="form_control_container">
				<div class="sameline">
					<label for="company-to">Token de verificación</label>
					<hk-tooltip-info
						text="Copia el token de verificación y pégalo en el campo correspondiente en facebook."
						position="left"
					></hk-tooltip-info>
				</div>
				<div class="input-container">
					<input type="text" id="company-to" formControlName="tokenV" placeholder="<company>" />
					<div class="copy">
						<app-copy-b [contentCopy]="tokenVerify.controls['tokenV'].value"></app-copy-b>
					</div>
				</div>
			</div>
		</form>
		<div class="video-responsive">
			<iframe
				src="https://www.youtube.com/embed/_xJs2ePMmf4"
				title="YouTube video player"
				frameborder="0"
				allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
				allowfullscreen
			></iframe>
		</div>
		<p class="step_title up_5">Agregar Webhooks</p>
		<ul>
			<li class="li-text">WhatsApp -> Configuración -> Campos del webhook (habilitar campo “messages”)</li>
		</ul>
		<div class="img-container">
			<img src="../../../../assets/img/whatsapp-activation-img.png" alt="imagen de campos de facebook" class="img-activation" />
		</div>
		<!-- <div class="help-container">
			<div class="button-container">
				<p>Cualquier duda contáctenos acá</p>
				<button class="help-button">Ayuda</button>
				<p>y lo ayudamos en el proceso</p>
			</div>
			<div class="img-container">
				<img src="../../../../assets/img/videolinkespecialidades.png" alt="" />
			</div>
		</div> -->
	</div>
</main>
