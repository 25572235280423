import { Component, OnInit, ViewEncapsulation, OnDestroy } from '@angular/core';
import { Subject, takeUntil } from 'rxjs';
import { tap } from 'rxjs/operators';
import { PreferencesService } from 'src/app/services/backend/preferences.service';
import { AuthService } from 'src/app/services/storage/auth.service';

@Component({
	selector: 'v-menuMain',
	templateUrl: './menu.component.html',
	styleUrls: ['./menu.component.scss'],
	encapsulation: ViewEncapsulation.None,
})
export class MenuComponent implements OnInit, OnDestroy {
	private userInfo: any;
	public company: any = { name: '', imgSrc: '' };

	private _unSuscribeAll: Subject<any> = new Subject();

	constructor(private _preferencesService: PreferencesService, private _authStorage: AuthService) {
		this.userInfo = this.userInfo = this._authStorage.getInfo();
		this.company.name = this.userInfo.company_name;
		this.getPreferencesInfo();
		this._preferencesService.onChange
			.pipe(
				takeUntil(this._unSuscribeAll),
				tap(() => this.getPreferencesInfo())
			)
			.subscribe();
	}

	ngOnInit(): void {}

	ngOnDestroy(): void {
		this._unSuscribeAll.next({});
		this._unSuscribeAll.complete();
	}

	getPreferencesInfo() {
		this._preferencesService
			.getPreferences(this.userInfo.company)
			.pipe(
				takeUntil(this._unSuscribeAll),
				tap(({ banner }) => {
					this.company.imgSrc = banner;
				})
			)
			.subscribe();
	}
}
