<div
	style="overflow: auto"
	class="v-dialog_modal"
	[ngClass]="[
		type === 'copy' || type === 'delete'
			? 'noMin'
			: '' || type == 'pricing-membership'
			? 'noMin priMin'
			: '' || type == 'pricing'
			? 'info'
			: ''
	]"
>
	<div class="v-dialog__header" *ngIf="type !== 'pricing-membership'">
		<h2 class="v-dialog__header__title">{{ titleBanner }}</h2>
		<button class="btn_close_dialog" mat-dialog-close (click)="closeDialog($event, false)">
			<svg viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg">
				<path
					d="M6.06575 5.66714C5.96369 5.66714 5.86175 5.70624 5.78357 5.78349C5.62813 5.93652 5.62813 6.19148 5.78357 6.34692L11.9815 12.5425C12.1361 12.6955 12.3895 12.6955 12.5449 12.5425C12.6996 12.3894 12.6996 12.132 12.5449 11.979L6.347 5.78349C6.26975 5.70624 6.16769 5.66714 6.06562 5.66714H6.06575Z"
					class="withFill"
				/>
				<path
					d="M12.2643 5.6671C12.1623 5.6671 12.0603 5.70619 11.9822 5.78344L5.78424 11.98C5.6288 12.133 5.6288 12.3904 5.78424 12.5434C5.93968 12.6965 6.19223 12.6965 6.34768 12.5434L12.5456 6.3479C12.701 6.19246 12.701 5.93748 12.5456 5.78446C12.4683 5.70641 12.3671 5.66732 12.2642 5.66732L12.2643 5.6671Z"
					class="withFill"
				/>
				<path
					d="M9.16504 0C4.11134 0 0 4.11237 0 9.16504C0 14.2194 4.11168 18.3301 9.16504 18.3301C14.2184 18.3301 18.3301 14.2194 18.3301 9.16504C18.3301 4.11237 14.2184 0 9.16504 0ZM9.16504 17.5332C4.55058 17.5332 0.796856 13.7802 0.796856 9.16504C0.796856 4.55127 4.55058 0.796858 9.16504 0.796858C13.7795 0.796858 17.5332 4.55127 17.5332 9.16504C17.5332 13.7802 13.7795 17.5332 9.16504 17.5332Z"
					class="withFill"
				/>
			</svg>
		</button>
	</div>

	<div [ngClass]="[type === 'pricingDetails' ? '' : 'v-dialog__body']">
		<ng-container [ngTemplateOutlet]="type === 'pricing' ? pricing : type === 'pricingDetails' ? pricingDetails : basic"> </ng-container>
	</div>

	<div class="v-dialog__actions" [ngStyle]="{ 'justify-content': type === 'pricing' ? 'flex-start' : 'center' }">
		<button class="btn_confirm_dialog" *ngIf="type === 'copy'" (click)="copyClipboard(item.name)">Copiar al portapapeles</button>
		<button class="btn_confirm_dialog" mat-dialog-close (click)="closeDialog($event, true)" *ngIf="!isImport">
			{{ textConfirmButton }}
		</button>
		<button class="btn_cancel_dialog" mat-dialog-close (click)="closeDialog($event, false)" *ngIf="textCancelButton">
			{{ textCancelButton }}
		</button>
	</div>
</div>

<ng-template #iconClose>
	<svg viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg">
		<path
			d="M6.06575 5.66714C5.96369 5.66714 5.86175 5.70624 5.78357 5.78349C5.62813 5.93652 5.62813 6.19148 5.78357 6.34692L11.9815 12.5425C12.1361 12.6955 12.3895 12.6955 12.5449 12.5425C12.6996 12.3894 12.6996 12.132 12.5449 11.979L6.347 5.78349C6.26975 5.70624 6.16769 5.66714 6.06562 5.66714H6.06575Z"
			class="withFill"
		/>
		<path
			d="M12.2643 5.6671C12.1623 5.6671 12.0603 5.70619 11.9822 5.78344L5.78424 11.98C5.6288 12.133 5.6288 12.3904 5.78424 12.5434C5.93968 12.6965 6.19223 12.6965 6.34768 12.5434L12.5456 6.3479C12.701 6.19246 12.701 5.93748 12.5456 5.78446C12.4683 5.70641 12.3671 5.66732 12.2642 5.66732L12.2643 5.6671Z"
			class="withFill"
		/>
		<path
			d="M9.16504 0C4.11134 0 0 4.11237 0 9.16504C0 14.2194 4.11168 18.3301 9.16504 18.3301C14.2184 18.3301 18.3301 14.2194 18.3301 9.16504C18.3301 4.11237 14.2184 0 9.16504 0ZM9.16504 17.5332C4.55058 17.5332 0.796856 13.7802 0.796856 9.16504C0.796856 4.55127 4.55058 0.796858 9.16504 0.796858C13.7795 0.796858 17.5332 4.55127 17.5332 9.16504C17.5332 13.7802 13.7795 17.5332 9.16504 17.5332Z"
			class="withFill"
		/>
	</svg>
</ng-template>

<ng-template #basic>
	<div *ngIf="type !== 'pricing-membership'">
		<div class="v-dialog__body__msg" *ngIf="message">{{ message }}</div>
		<img class="v-dialog__body__item__img" *ngIf="item.img" [src]="item.img" />
		<div class="v-dialog__body__item__name" [ngClass]="[type === 'copy' ? 'copy' : '']" *ngIf="item.name">{{ item.name }}</div>
	</div>
	<div *ngIf="type === 'pricing-membership'">
		<div style="font-size: 20px; text-align: center">¿Deseas cambiar tu plan actual?</div>
		<div style="text-align: center; margin-top: 10px">
			<div style="font-size: 16px; margin-bottom: 4px">
				Tu plan es: <strong>{{ currentMembership }} </strong>
			</div>
			<div style="font-size: 16px; margin-bottom: 20px">
				Total: <strong>{{ currentPrice }}</strong>
			</div>
			<div style="font-size: 16px; margin-bottom: 4px">
				Lo deseas cambiar por: <strong>{{ nextMembership }}</strong>
			</div>
			<div style="font-size: 16px">
				Total: <strong>{{ nextPrice }}</strong>
			</div>
		</div>
	</div>
</ng-template>

<ng-template #import>
	<div class="v-dialog__body__subtitle" *ngIf="subTitle">{{ subTitle }}</div>
	<div class="v-dialog__body__msg" *ngIf="message">{{ message }}</div>
</ng-template>

<ng-template #pricing>
	<div class="v-dialog__body__subtitle" *ngIf="subTitle && !isImport">{{ subTitle }}</div>
	<div class="v-dialog__body__msg" *ngIf="message && !isImport">{{ message }}</div>
	<div class="v-dialog__body__msg" *ngIf="isImport" style="max-height: 468px; overflow: auto">
		<div style="width: 400px">
			<dropzone [config]="config" [message]="'Agregar archivo'" #filesDrop> </dropzone>
		</div>
		<div *ngIf="errorFormat === 'size'" style="display: flex; margin-left: 10px; flex-direction: column; align-items: flex-start">
			<div style="font-size: 10px">Se pueden cargar máximo 5000 contactos</div>
		</div>
		<div *ngIf="errorFormat === 'data'" style="display: flex; margin-left: 10px; flex-direction: column; align-items: flex-start">
			<div style="font-size: 10px">El archivo presenta errores en los campos de:</div>
			<ul style="font-size: 10px; margin-top: 10px">
				<li style="text-align: left" *ngFor="let item of dataErrorFormat">Fila {{ item.row }} {{ item.msg }}</li>
			</ul>
		</div>
		<div *ngIf="errorFormat === 'headers'" style="display: flex; margin-left: 10px; flex-direction: column; align-items: flex-start">
			<div style="font-size: 12px">El archivo presenta errores en las cabeceras</div>
		</div>
		<button
			class="btn_confirm_dialog"
			style="background: #ea345f; color: #ffffff; border-radius: 30px; width: 150px; height: 30px; cursor: pointer"
			(click)="uploadFile($event)"
		>
			Cargar
		</button>
	</div>
</ng-template>

<ng-template #pricingDetails>
	<div style="padding: 12px; padding-top: 0px; max-height: 600px" class="scroll-details">
		<div *ngIf="membershipType === 'custom'">Actualmente estás usando:</div>
		<div *ngIf="currentMembershipData && membershipType === 'custom'" style="margin-bottom: 24px">
			<div style="margin-top: 13px; background: #f6f6fc; border-radius: 8px">
				<div
					style="
						background: #4b27ef;
						display: flex;
						justify-content: center;
						padding: 4px;
						border-bottom: 1px solid #afb8cd;
						align-items: center;
						padding: 8px;
					"
				>
					<div style="color: #ffffff; width: 33%; text-align: center; font-size: 20px; font-weight: 500">Ítem</div>
					<div style="color: #ffffff; width: 33%; text-align: center; font-size: 20px; font-weight: 500">Cantidad</div>
					<div style="color: #ffffff; width: 33%; text-align: center; font-size: 20px; font-weight: 500">Valor</div>
				</div>
				<div *ngFor="let item of currentMembershipData">
					<div
						style="
							display: flex;
							justify-content: center;
							padding: 4px;
							border-bottom: 1px solid #afb8cd;
							align-items: center;
							padding: 8px;
						"
					>
						<div
							style="
								width: 33%;
								text-align: center;
								font-size: 20px;
								font-weight: 500;
								white-space: nowrap;
								overflow: hidden;
								text-overflow: ellipsis;
							"
						>
							{{ item.name }}
						</div>
						<div style="width: 33%; text-align: center; font-size: 20px; font-weight: 500">
							{{ item.value === true ? '' : item.type === 'storage' ? item.value + 'GB' : item.value }}
						</div>
						<div style="width: 33%; text-align: center; font-size: 20px; font-weight: 500">${{ item.amount.toFixed(2) }}</div>
					</div>
				</div>
				<div>
					<div style="display: flex; justify-content: center; padding: 4px; align-items: center; padding: 8px">
						<div style="width: 33%; text-align: center; font-size: 20px; font-weight: 500">TOTAL</div>
						<div style="width: 33%; text-align: center; font-size: 20px; font-weight: 500"></div>
						<div style="width: 33%; text-align: center; font-size: 20px; font-weight: 500">${{ oldPrice }}</div>
					</div>
				</div>
			</div>
		</div>
		<div>
			{{ firstTitle }}
		</div>
		<div *ngIf="newMembershipData">
			<div style="margin-top: 13px; background: #f6f6fc; border-radius: 8px">
				<div
					style="
						background: #4b27ef;
						display: flex;
						justify-content: center;
						padding: 4px;
						border-bottom: 1px solid #afb8cd;
						align-items: center;
						padding: 8px;
					"
				>
					<div style="color: #ffffff; width: 33%; text-align: center; font-size: 20px; font-weight: 500">Ítem</div>
					<div style="color: #ffffff; width: 33%; text-align: center; font-size: 20px; font-weight: 500">Cantidad</div>
					<div style="color: #ffffff; width: 33%; text-align: center; font-size: 20px; font-weight: 500">Valor</div>
				</div>
				<div *ngFor="let item of newMembershipData">
					<div
						style="
							display: flex;
							justify-content: center;
							padding: 4px;
							border-bottom: 1px solid #afb8cd;
							align-items: center;
							padding: 8px;
						"
					>
						<div
							style="
								width: 33%;
								text-align: center;
								font-size: 20px;
								font-weight: 500;
								white-space: nowrap;
								overflow: hidden;
								text-overflow: ellipsis;
							"
						>
							{{ item.name }}
						</div>
						<div style="width: 33%; text-align: center; font-size: 20px; font-weight: 500">
							{{ item.value === true ? '' : item.type === 'storage' ? item.value + 'GB' : item.value }}
						</div>
						<div style="width: 33%; text-align: center; font-size: 20px; font-weight: 500">${{ item.amount.toFixed(2) }}</div>
					</div>
				</div>
				<div>
					<div style="display: flex; justify-content: center; padding: 4px; align-items: center; padding: 8px">
						<div
							style="
								width: 33%;
								text-align: center;
								font-size: 20px;
								font-weight: 500;
								display: flex;
								align-items: center;
								justify-content: center;
							"
						>
							<div>TOTAL</div>
							<div style="margin-left: 8px" *ngIf="membershipType === 'custom' && !isRenew">
								<button
									class="typeAttentions__stepper__section__content__group__tooltip"
									[ngClass]="[tooltip.icon ? 'active' : '']"
									(click)="tooltip.icon = !tooltip.icon"
									(mouseenter)="tooltip.icon = true"
									(mouseleave)="tooltip.icon = false"
								>
									<v-icon
										name="info"
										(click)="tooltip.icon = !tooltip.icon"
										(mouseenter)="tooltip.icon = true"
										(mouseleave)="tooltip.icon = false"
									></v-icon>
									<div class="typeAttentions__stepper__section__content__group__tooltip__msg">
										<span>La cantidad correspondiente se divide (se prorratea) y se pagará los días calculados de forma porcentual.</span>
									</div>
								</button>
							</div>
						</div>
						<div style="width: 33%; text-align: center; font-size: 20px; font-weight: 500"></div>
						<div style="width: 33%; text-align: center; font-size: 20px; font-weight: 500">${{ newPrice }}</div>
					</div>
				</div>
			</div>
		</div>
		<div style="margin-top: 13px; text-align: justify; margin-bottom: 16px" *ngIf="membershipType === 'custom'">
			Cuando tu plan se renueve el {{ endDate }}, el nuevo monto total {{ membershipTime }} será: ${{ baseMount }}USD.
		</div>
		<div style="margin-top: 13px; text-align: justify; margin-bottom: 25px">
			La próxima factura incluirá el monto proporcional correspondiente al ciclo de facturación actual. Estos precios no incluyen impuestos
			ni descuentos vigentes. Todos los cambios están sujetos a nuestros términos de suscripción
		</div>

		<!-- <div class="v-dialog__body__subtitle" *ngIf="firstTitle">{{ firstTitle }}</div>
		<div class="v-dialog__body__msg" *ngIf="message">Lo que sea por 2</div> -->
	</div>
</ng-template>
