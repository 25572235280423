import { Component, Input, OnInit } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
	selector: 'vl-form-group-s1',
	templateUrl: './vl-form-group-s1.component.html',
	styleUrls: ['./vl-form-group-s1.component.scss'],
	providers: [
		{
			provide: NG_VALUE_ACCESSOR,
			useExisting: VlFormGroupS1Component,
			multi: true,
		},
	],
})
export class VlFormGroupS1Component implements OnInit {
	@Input() type:
		| 'text'
		| 'tel'
		| 'number'
		| 'textarea'
		| 'select-single'
		| 'select-icon'
		| 'select-multi'
		| 'range'
		| 'check'
		| 'check-invert' = 'text';
	@Input() id: string = '';
	@Input() checkMsg: string = '';
	@Input() label: string = '';
	@Input() placeholder: string = '';
	@Input() hasErrors: string | null = null;

	@Input() rangeDisable: number[] = [];

	@Input() optionsoFSelect: Array<{ value: string; label: string; disable?: boolean }> = [];

	@Input() disabled: boolean = false;
	@Input() maxlength: string | number = '';
	@Input() minlength: string | number = '';
	@Input() max: string | number = '';
	@Input() min: string | number = '';

	value!: any;
	isDisabled!: boolean;
	onChange!: (value: any) => void;
	onTouched!: () => void;

	constructor() {}
	writeValue(obj: any): void {
		this.value = typeof obj === 'string' ? obj.trim() : obj;
	}
	registerOnChange(fn: any): void {
		this.onChange = fn;
	}
	registerOnTouched(fn: any): void {
		this.onTouched = fn;
	}
	setDisabledState?(isDisabled: boolean): void {
		this.isDisabled = isDisabled;
	}

	checkChangeValue() {
		const ref = Boolean(this.value);
		this.value = !ref;
		this.onChange(this.value);
	}

	ngOnInit(): void {}
}
