import { Component, Input, OnInit } from '@angular/core';

@Component({
	selector: 'vl-card-header',
	templateUrl: './vl-card-header.component.html',
	styleUrls: ['./vl-card-header.component.scss'],
})
export class VlCardHeaderComponent implements OnInit {
	@Input() titleCard!: string;
	@Input() descriptionCard!: string;
	constructor() {}

	ngOnInit(): void {}
}
