import { Component, OnInit } from '@angular/core';

@Component({
	templateUrl: '../../../../../assets/svg/arrow-back.svg',
})
export class ArrowBackComponent implements OnInit {
	constructor() {}

	ngOnInit(): void {}
}
