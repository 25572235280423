export const environment = {
	production: false,
	hmr: false,
	logo: 'assets/images/logos/videolink_logo.png',

	easyServer: 'https://easy.dev.videolink.app',
	agentURL: 'https://agent.dev.videolink.app',
	adminURL: 'https://adminv2.dev.videolink.app',

	serverDev: 'http://admin.dev.videolink.app/admin/',
	serverRemote: 'https://api.dev.videolink.app',
	wsServer: 'https://ws.dev.videolink.app/videolink',
	storage: 'https://cdn.dev.videolink.app',

	hookJs: 'https://s3.amazonaws.com/cdn1.videolink.app/videolink.js',
	hookCss: 'https://s3.amazonaws.com/cdn1.videolink.app/styles.css',

	widgetCss: 'https://widgetqa.s3.amazonaws.com/assets/css/style.css',
	widgetJs: 'https://widgetqa.s3.amazonaws.com/assets/js/script.js',
	//facebookAppId: '1551556528677603',
	facebookAppId: '1155687701799929',
	waLink: 'https://wa.dev.videolink.app/wa/',
	lukaUser: 'videolink-suscripcion',
	lukaPasswordQA: 'come-HOME-hello-water',
	lukaAuthQA: 'https://bspaycoapi-qa.payco.net.ve/api/v1/servicio/login',
	lukaAuthPRO: 'https://lukaapi.payco.net.ve/api/v1/servicio/login',
	lukaPasswordPRO: 'london-OUTSIDE-five-FIJI',
	termsAndCondiitons: 'https://easy.dev.videolink.app/conditions',
	cdnWidget: 'https://s3.amazonaws.com/widget.dev.videolink.app',
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
