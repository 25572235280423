import { Component, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validator, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { ConfirmDialogComponent } from '@components/confirm-dialog/confirm-dialog.component';
import { ApiService } from '@services/backend/api.service';
import { FbsdkService } from '@services/backend/fbsdk.service';
import { MembershipService } from '@services/backend/membership.service';
import { PreferencesService } from '@services/backend/preferences.service';
import { ToastService } from '@services/backend/toast.service';
import { LoadingService } from '@services/components/loading.service';
import { lastValueFrom, Subject, takeUntil, tap } from 'rxjs';

@Component({
	selector: 'app-whatsapp-personalize',
	templateUrl: './whatsapp-personalize.component.html',
	styleUrls: ['./whatsapp-personalize.component.scss'],
})
export class WhatsappPersonalizeComponent implements OnInit, OnDestroy {
	contentTitle: string = 'Chatbot del canal de WhatsApp';
	contentDescription: string = 'Personaliza las opciones de tu Chatbot y mensajes para tu cuenta de WhatsApp';
	contentWhastappDescription: string = 'Debes activar el canal de WhatsApp para poder personalizarlo';
	companyId: any;
	isLoaded: boolean = false;
	maxLength: number = 512;
	minLength: number = 10;
	minLengthL: number = 5;
	maxLengthL: number = 50;
	whastappActivateState: boolean = false;
	littleInputs: any[] = [
		{
			title: 'Título del Menú',
			placeholder: '',
			name: 'menuTitle',
			id: 'menuTi',
			textValidationRequired: 'El título del menú es requerido.',
			textValidationMin: `El título del menú debe tener al menos ${this.minLengthL} caracteres.`,
			textValidationMax: `El título del menú debe tener máximo ${this.maxLengthL} caracteres.`,
			maxLength: this.maxLengthL,
			minLength: this.minLengthL,
		},
		{
			title: 'Descripción del Menú',
			placeholder: '',
			name: 'menuDescription',
			id: 'menuDescrip',
			textValidationRequired: 'El descripción del menú es requerido.',
			textValidationMin: `El descripción del menú debe tener al menos ${this.minLengthL} caracteres.`,
			textValidationMax: `El descripción del menú debe tener máximo ${this.maxLengthL} caracteres.`,
			maxLength: this.maxLengthL,
			minLength: this.minLengthL,
		},
		{
			title: 'Título de la acción',
			placeholder: '',
			name: 'actionTitle',
			id: 'actionTi',
			textValidationRequired: 'El título de la acción es requerido.',
			textValidationMin: `El título de la acción debe tener al menos ${this.minLengthL} caracteres.`,
			textValidationMax: `El título de la acción debe tener máximo ${this.maxLengthL} caracteres.`,
			maxLength: this.maxLengthL,
			minLength: this.minLengthL,
		},
		{
			title: 'Título de la atención',
			placeholder: '',
			name: 'attentionTitle',
			id: 'attentionTit',
			textValidationRequired: 'El título de la atención es requerido.',
			textValidationMin: `El título de la atención debe tener al menos ${this.minLengthL} caracteres.`,
			textValidationMax: `El título de la atención debe tener máximo ${this.maxLengthL} caracteres.`,
			maxLength: 10,
			minLength: this.minLengthL,
		},
	];
	bigInputs: any[] = [
		{
			title: 'Mensaje predeterminado en las conversaciones',
			placeholder: '',
			name: 'messagePredeterminate',
			id: 'messagePredeter',
			textValidationRequired: 'El mensaje predeterminado es requerido.',
			textValidationMin: `El mensaje de predeterminado debe tener al menos ${this.minLength} caracteres.`,
			textValidationMax: `El mensaje de predeterminado debe tener máximo ${this.maxLength} caracteres.`,
			maxLength: this.maxLength,
			minLength: this.minLength,
		},
		{
			title: 'Mensaje de finalización en conversaciones',
			placeholder: '',
			name: 'messageFinish',
			id: 'messageFinis',
			textValidationRequired: 'El mensaje de finalización en conversaciones es requerido.',
			textValidationMin: `El mensaje de finalización debe tener al menos ${this.minLength} caracteres.`,
			textValidationMax: `El mensaje de finalización debe tener máximo ${this.maxLength} caracteres.`,
			maxLength: this.maxLength,
			minLength: this.minLength,
		},
		{
			title: 'Mensaje de "Agente no disponible" en conversaciones',
			placeholder: '',
			name: 'messageAgentNo',
			id: 'messageAgentN',
			textValidationRequired: 'El mensaje de "Agente no disponible" en conversaciones es requerido.',
			textValidationMin: `El mensaje de "Agente no disponible" en conversaciones debe tener al menos ${this.minLength} caracteres.`,
			textValidationMax: `El mensaje de "Agente no disponible" en conversaciones debe tener máximo ${this.maxLength} caracteres.`,
			maxLength: this.maxLength,
			minLength: this.minLength,
		},
		{
			title: 'Mensaje para selección de atenciones',
			placeholder: '',
			name: 'messageAbilities',
			id: 'messageAbilitie',
			textValidationRequired: 'El mensaje para selección de atenciones es requerido.',
			textValidationMin: `El mensaje para selección de atenciones debe de tener al menos ${this.minLength} caracteres.`,
			textValidationMax: `El mensaje para selección de atenciones debe de tener máximo ${this.maxLength} caracteres.`,
			maxLength: this.maxLength,
			minLength: this.minLength,
		},
		{
			title: 'Mensaje de "Cliente en cola" en conversaciones',
			placeholder: '',
			name: 'messagePile',
			id: 'messagePil',
			textValidationRequired: 'El mensaje de "Cliente en cola" en conversaciones es requerido.',
			textValidationMin: `El mensaje de "Cliente en cola" en conversaciones debe de tener al menos ${this.minLength} caracteres.`,
			textValidationMax: `El mensaje de "Cliente en cola" en conversaciones debe de tener máximo ${this.maxLength} caracteres.`,
			maxLength: this.maxLength,
			minLength: this.minLength,
		},
	];
	formWhatsappPersonalize: UntypedFormGroup;
	facebookData: any;
	userID: string = '';
	accessToken: string = '';
	userName: string = 'No disponible';
	imgProfileUrl: string = '../../../../assets/img/imagenpordefectofacebook.jpg';
	imgBusinessUrl: string = '../../../../assets/img/imagenpordefectofacebook.jpg';
	imgWhatsappUrl: string = '../../../../assets/img/imagenpordefectofacebook.jpg';
	page: any;
	pages: any;
	pref: any;
	userBusiness: string = 'No disponible';
	userWhatsapp: string = 'No disponible';
	whatsappAccount: any;
	whatsappOneAccount: any = {
		name: 'No disponible',
	};
	public _unSuscribeAll: Subject<any> = new Subject();
	constructor(
		private _preferenceService: PreferencesService,
		private toastService: ToastService,
		private loadingService: LoadingService,
		private _membershipService: MembershipService,
		public _matDialog: MatDialog,
		public fbSdk: FbsdkService,
		private api: ApiService,
		private router: Router
	) {
		this.formWhatsappPersonalize = new UntypedFormGroup({
			menuTitle: new UntypedFormControl('', [
				Validators.required,
				Validators.maxLength(this.maxLengthL),
				Validators.minLength(this.minLengthL),
			]),
			menuDescription: new UntypedFormControl('', [
				Validators.required,
				Validators.maxLength(this.maxLengthL),
				Validators.minLength(this.minLengthL),
			]),
			actionTitle: new UntypedFormControl('', [
				Validators.required,
				Validators.maxLength(this.maxLengthL),
				Validators.minLength(this.minLengthL),
			]),
			attentionTitle: new UntypedFormControl('', [
				Validators.required,
				Validators.maxLength(this.maxLengthL),
				Validators.minLength(this.minLengthL),
			]),
			messagePredeterminate: new UntypedFormControl('', [
				Validators.required,
				Validators.maxLength(this.maxLength),
				Validators.minLength(this.minLength),
			]),
			messageFinish: new UntypedFormControl('', [
				Validators.required,
				Validators.maxLength(this.maxLength),
				Validators.minLength(this.minLength),
			]),
			messageAgentNo: new UntypedFormControl('', [
				Validators.required,
				Validators.maxLength(this.maxLength),
				Validators.minLength(this.minLength),
			]),
			messageAbilities: new UntypedFormControl('', [
				Validators.required,
				Validators.maxLength(this.maxLength),
				Validators.minLength(this.minLength),
			]),
			messagePile: new UntypedFormControl('', [
				Validators.required,
				Validators.maxLength(this.maxLength),
				Validators.minLength(this.minLength),
			]),
		});
		this.loadingService.initiate();
		this._membershipService.membershipOfCompany
			.pipe(
				takeUntil(this._unSuscribeAll),
				tap((membershipData) => {
					if (Object.keys(membershipData).length === 0) return membershipData;
					if (!membershipData.membership.current.WA) {
						this.router.navigate(['/dashboard/channels/whatsapp/firsts-steps']);
					}
				})
			)
			.subscribe();
		this.init();
		this._preferenceService.preferencesOfCompany.pipe(takeUntil(this._unSuscribeAll)).subscribe((result: any) => {
			this.fbSdk.loginStatus();
			this.pref = result;
			this.setData(result);
			this.isLoaded = true;
			this.loadingService.hide();
		});
	}
	async init() {
		this.initData();
	}
	ngOnInit(): void {}

	async initData() {
		let token = localStorage.getItem('tokenAuth') || '';
		let decode = JSON.parse(atob(token.split('.')[1]));
		this.companyId = decode.company;
	}

	ngOnDestroy() {
		this._unSuscribeAll.next({});
		this._unSuscribeAll.complete();
	}

	async setData(data: any) {
		if (data.whatsappMenu) {
			if (data.whatsappMenu.header) this.formWhatsappPersonalize.controls['menuTitle'].setValue(data.whatsappMenu.header);
			if (data.whatsappMenu.body) this.formWhatsappPersonalize.controls['menuDescription'].setValue(data.whatsappMenu.body);
			if (data.whatsappMenu.action) this.formWhatsappPersonalize.controls['actionTitle'].setValue(data.whatsappMenu.action);
			if (data.whatsappMenu.sectionTitle) this.formWhatsappPersonalize.controls['attentionTitle'].setValue(data.whatsappMenu.sectionTitle);
		}
		if (data.whatsappWelcome) this.formWhatsappPersonalize.controls['messagePredeterminate'].setValue(data.whatsappWelcome);
		if (data.whatsappBye) this.formWhatsappPersonalize.controls['messageFinish'].setValue(data.whatsappBye);
		if (data.whatsappAgent) this.formWhatsappPersonalize.controls['messageAgentNo'].setValue(data.whatsappAgent);
		if (data.whatsappAbilityMsg) this.formWhatsappPersonalize.controls['messageAbilities'].setValue(data.whatsappAbilityMsg);
		if (data.whatsappQueue) this.formWhatsappPersonalize.controls['messagePile'].setValue(data.whatsappQueue);
		if (data.whatsapp) this.whastappActivateState = data.whatsapp;
	}
	async submitForm() {
		try {
			if (this.formWhatsappPersonalize.invalid) {
				this.formWhatsappPersonalize.markAllAsTouched();
				return;
			}
			this.loadingService.initiate();
			let data = {
				whatsappMenu: {
					header: this.formWhatsappPersonalize.controls['menuTitle'].value,
					body: this.formWhatsappPersonalize.controls['menuDescription'].value,
					action: this.formWhatsappPersonalize.controls['actionTitle'].value,
					sectionTitle: this.formWhatsappPersonalize.controls['attentionTitle'].value,
				},
				whatsappWelcome: this.formWhatsappPersonalize.controls['messagePredeterminate'].value,
				whatsappBye: this.formWhatsappPersonalize.controls['messageFinish'].value,
				whatsappAgent: this.formWhatsappPersonalize.controls['messageAgentNo'].value,
				whatsappAbilityMsg: this.formWhatsappPersonalize.controls['messageAbilities'].value,
				whatsappQueue: this.formWhatsappPersonalize.controls['messagePile'].value,
			};
			const result: any = await lastValueFrom(this._preferenceService.updatePreferences(this.companyId, data));
			this._preferenceService.onChange.emit('');
			this.loadingService.hide();
			this.toastService.initiate({
				title: 'Éxito',
				content: 'Las personalizaciones del canal de WhatsApp han sido guardadas.',
				type: 'success',
			});
		} catch (error) {
			this.loadingService.hide();
			this.toastService.initiate({
				title: 'Error',
				content: 'Ha ocurrido un error inesperado.',
				type: 'error',
			});
		}
	}
}
