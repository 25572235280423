<div class="layout_one">
	<v-header (toggleSideMenuEmit)="toggleMenuMobile($event)"></v-header>
	<ng-container *ngIf="screenWidth < 1025">
		<div [ngClass]="['overlay', subMenuActive ? 'active' : '']">
			<v-subMenuMain
				(forceCloseMenuMobileEmit)="forceCloseMenuMobile($event)"
				[reset]="subMenuActive"
				style="overflow-y: auto"
			></v-subMenuMain>
			<div class="overlay_fill" (click)="toggleMenuMobile($event)"></div>
		</div>
	</ng-container>
	<div class="body">
		<ng-container *ngIf="screenWidth < 1025; else laptop">
			<div class="content">
				<router-outlet></router-outlet>
			</div>
		</ng-container>
		<ng-template #laptop>
			<v-menuMain></v-menuMain>
			<v-subMenuMain style="overflow-y: auto"></v-subMenuMain>
			<div class="content">
				<router-outlet></router-outlet>
			</div>
		</ng-template>
	</div>
</div>
<!-- <Tour></Tour> -->
