import { environment } from './../../../environments/environment';
import { Subject, distinctUntilChanged, lastValueFrom, takeUntil } from 'rxjs';
// import { TicketsService } from './../../shared/services/tickets.service'
// import { FileUploadService } from './../../shared/services/file-upload.service'
// import { HookConfigService } from 'src/app/shared/services/hook-config.service'
import { Router } from '@angular/router';
// import { CustomizeService } from 'src/app/shared/services/customize.service'
import { Component, Input, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { FileUploadService } from '@services/backend/file-upload.service';
import { ContactCenterService } from '@services/backend/contact-center.service';
@Component({
	selector: 'dynamic-form-full',
	templateUrl: './dynamic-form-full.component.html',
	styleUrls: ['./dynamic-form-full.component.scss'],
})
export class DynamicFormFullComponent implements OnInit {
	public _unSuscribeAll: Subject<any> = new Subject();
	done: boolean = false;
	hasVerificationField: boolean = false;
	verificationFields: string[] = [];
	fields: Array<any> = [];
	inputFiles: { [key: string]: Array<File> } = {};
	isInputsValid: boolean = false;
	running: boolean = false;
	storageUrl: string = environment.storage;
	btnColor = '#1B0845';
	forms: any[] = [];
	constructor(private router: Router, private flUpload: FileUploadService, private _ccAPI: ContactCenterService) {
		// this.buildForm();
		this.getData();
	}

	form = new FormGroup({});

	buildForm() {
		this.done = false;
		this.form = new FormGroup({});
		this.fields = this.forms || [];

		this.forms.forEach((item, i) => {
			const validations = [];

			// required fields
			if (item.required) validations.push(Validators.required);
			// phone number fields
			if (item.type === 'phone') {
				this.hasVerificationField = true;
			}

			if (item._id === 'email') {
				validations.push(Validators.email);
				this.hasVerificationField = true;
			}

			// only for number
			else if (item._id == 'number') {
				validations.push(Validators.pattern('^[0-9]*$'));
				validations.push(Validators.min(item.min));
				validations.push(Validators.max(item.max));
			} else if (item._id == 'text') {
				validations.push(Validators.minLength(2));
				validations.push(Validators.maxLength(120));
			} else if (item._id == 'textarea') {
				validations.push(Validators.minLength(2));
				validations.push(Validators.maxLength(512));
			}

			if (item._id === 'regex') {
				validations.push(Validators.pattern(new RegExp(item.regex)));
			}

			this.form.addControl(item.label + i, new FormControl('', validations));
		});

		this.done = true;
	}
	ngOnInit(): void {}

	async getData() {
		this._ccAPI.ccInfoData.pipe(takeUntil(this._unSuscribeAll)).subscribe((result: any) => {
			this.forms = result.form || [];
			this.buildForm();
		});
	}

	ngOnDestroy(): void {
		this._unSuscribeAll.next({});
		this._unSuscribeAll.complete();
	}

	onChangesFile(files: any, type: string, formControlName: string) {
		this.inputFiles[type] = files;
		(this.form as FormGroup).controls[formControlName].setValue(files);
		this.validateInputs();
	}

	validateInputs() {
		let v = true;
		Object.entries(this.inputFiles).forEach(([key, value]) => {
			if (value.length === 0) v = false;
		});
		this.isInputsValid = v;
	}
	// onChanges() {
	// 	this.buildForm();
	// }
}
