<main class="personalize" *ngIf="isLoaded && facebookActivate">
	<app-title-section [title]="contentTitle" [description]="contentDescription"></app-title-section>
	<div class="limiter">
		<form class="form-container" [formGroup]="formFacebookPersonalize">
			<div class="form-control-b">
				<div class="form-control-b-data-field" *ngFor="let itemB of bigInputs">
					<label [for]="itemB.id" class="message-label"> {{ itemB.title }}</label>
					<textarea
						[id]="itemB.id"
						[formControlName]="itemB.name"
						[attr.maxlength]="itemB.maxLength"
						[attr.minLength]="itemB.minLength"
						[placeholder]="itemB.placeholder"
					></textarea>
					<span
						class="text-input-warning"
						*ngIf="
							formFacebookPersonalize.controls[itemB.name].getError('required') &&
							(formFacebookPersonalize.controls[itemB.name].touched || formFacebookPersonalize.controls[itemB.name].dirty)
						"
					>
						{{ itemB.textValidationRequired }}
					</span>
					<span
						class="text-input-warning"
						*ngIf="
							formFacebookPersonalize.controls[itemB.name].getError('minlength') &&
							(formFacebookPersonalize.controls[itemB.name].touched || formFacebookPersonalize.controls[itemB.name].dirty)
						"
					>
						{{ itemB.textValidationMin }}
					</span>
					<span
						class="text-input-warning"
						*ngIf="
							formFacebookPersonalize.controls[itemB.name].getError('maxlength') &&
							(formFacebookPersonalize.controls[itemB.name].touched || formFacebookPersonalize.controls[itemB.name].dirty)
						"
					>
						{{ itemB.textValidationMax }}
					</span>
				</div>
			</div>

			<div class="button-container">
				<button class="button-form" (click)="submitForm()">Guardar cambios</button>
			</div>
		</form>
		<div class="phone-container">
			<div class="phone-container__img">
				<img src="../../../../assets/img/facebook-personalize-act.png" alt="" />
			</div>
		</div>
	</div>
</main>

<main *ngIf="!facebookActivate && isLoaded" class="no-loaded">
	<app-title-section [title]="contentTitle" [description]="contentFacebookDescription"></app-title-section>
	<div class="limiter-container">
		<div class="img-container">
			<img src="../../../../assets/img/activation.svg" alt="" />
		</div>
	</div>
</main>
