import { Injectable } from '@angular/core';
import { ApiService } from './api.service';

@Injectable({
  providedIn: 'root'
})
export class AppsService {

  constructor(private api: ApiService) {}
  
  getPreferences(company_id: string): any | Promise<any> {
    return this.api.get(`/c/${company_id}/app/v2/list`)
  }

}
