import { Component, Input, OnInit, inject } from '@angular/core';
import { VlIconModule } from '@components/vl-icon/vl-icon.module';

@Component({
	selector: 'vl-code-preview',
	templateUrl: './vl-code-preview.component.html',
	styleUrls: ['./vl-code-preview.component.scss'],
	standalone: true,
	imports: [VlIconModule],
})
export class VlCodePreviewComponent {
	@Input() code!: string;
	copyText() {
		navigator.clipboard.writeText(this.code);
		// this.notificationSrc.show({ title: 'Copiado', msg: 'El código ha sido copiado', type: 'success' });
	}
}
