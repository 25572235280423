import { Component, OnInit, HostListener, ViewChild, ViewEncapsulation, Input, SimpleChanges } from '@angular/core';
import { MatDrawer } from '@angular/material/sidenav';
import { ActivatedRoute, Router } from '@angular/router';
import { ApiService } from '@services/backend/api.service';
import { WebSocketService } from '@services/backend/web-socket.service';
import { DimensionsScreenService } from '@services/components/dimensions-screen.service';
import { AuthService } from '@services/storage/auth.service';
import { LocalstorageService } from '@services/storage/localstorage.service';
import { lastValueFrom, tap } from 'rxjs';

@Component({
	selector: 'app-dashboard',
	templateUrl: './dashboard.component.html',
	styleUrls: ['./dashboard.component.scss'],
	encapsulation: ViewEncapsulation.None,
})
export class DashboardComponent implements OnInit {
	@HostListener('window:keydown', ['$event'])
	onKeydown(btn: any) {
		if (btn.key === 'Escape' && this.subMenuActive) {
			this.forceCloseMenuMobile(btn);
		}
	}
	_user: any = undefined;
	private timeoutId: any;

	public subMenuActive: boolean = false;
	public screenWidth!: number;
	constructor(
		private _dimensionsScreen: DimensionsScreenService,
		private _authService: AuthService,
		private _api: ApiService,
		private _ws: WebSocketService
	) {
		this._dimensionsScreen.change
			.pipe(
				tap(({ width }) => {
					this.screenWidth = width;
					if (width >= 1024 && this.subMenuActive) {
						this.forceCloseMenuMobile({});
					}
				})
			)
			.subscribe();
	}

	onRefreshToken = async () => {
		try {
			const result = await lastValueFrom(this._api.post(`/auth/refresh-token`));
			return result.token;
		} catch (err) {
			console.log(err);
		}
	};

	set user(value: any) {
		this._user = value;
		this.checkTokenExpiration();
	}

	get user(): any {
		return this._user;
	}

	private checkTokenExpiration(): void {
		if (this.timeoutId) {
			clearTimeout(this.timeoutId); // Cancelar timeout anterior si existe
		}

		const currentTime = new Date().getTime();
		const expirationTime = new Date(this.user.exp * 1000).getTime();
		const timeRemaining = expirationTime - currentTime;
		const refreshTime = timeRemaining - 1800000;

		this.timeoutId = setTimeout(async () => {
			const token: string = await this.onRefreshToken();
			localStorage.setItem('tokenAuth', token);
			this.user = this._authService.getInfo();
			this._ws.closeSocket();
			this._ws.init();
		}, refreshTime);
	}

	ngOnInit(): void {
		this.user = this._authService.getInfo();

		if (this.timeoutId) {
			clearTimeout(this.timeoutId); // Cancelar timeout anterior si existe
		}
		this.checkTokenExpiration();
	}

	toggleMenuMobile(event: any) {
		this.subMenuActive = !this.subMenuActive;
	}
	forceCloseMenuMobile(event: any) {
		this.subMenuActive = false;
	}
}
