import { Component, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { ConfirmDialogComponent } from '@components/confirm-dialog/confirm-dialog.component';
import { environment } from '@environments/environment';
import { ApiService } from '@services/backend/api.service';
import { MembershipService } from '@services/backend/membership.service';
import { PreferencesService } from '@services/backend/preferences.service';
import { ToastService } from '@services/backend/toast.service';
import { lastValueFrom, Subject, takeUntil, tap } from 'rxjs';
import { FbsdkService } from 'src/app/services/backend/fbsdk.service';

@Component({
	selector: 'app-whatsapp-activation',
	templateUrl: './whatsapp-activation.component.html',
	styleUrls: ['./whatsapp-activation.component.scss'],
})
export class WhatsappActivationComponent implements OnInit {
	contentTitle: string = 'Activación del canal de WhatsApp';
	contentDescription: string = 'Activar canal de WhatsApp en Videolink';
	formWhatsAppActivation: UntypedFormGroup;
	urlDevCallVideo: UntypedFormGroup;
	tokenVerify: UntypedFormGroup;
	whatsappPages: any = [];
	whatsappAccount: any = [];
	tokenVerifyVal: string = '';
	urlDevVal: string = '';
	companyId: string = '';
	name: string = '';
	profile_pic: string = '';
	whatsapp: boolean = false;
	level: boolean = false;
	isChatbotActive: boolean = false;
	public _unSuscribeAll: Subject<any> = new Subject();
	descriptionAlign: string = 'left';
	titleAlign: string = 'left';
	opened: boolean = false;
	constructor(
		public fbSdk: FbsdkService,
		private _preferencesSrc: PreferencesService,
		private api: ApiService,
		public _matDialog: MatDialog,
		private toastService: ToastService,
		private router: Router,
		private _membershipService: MembershipService
	) {
		this.formWhatsAppActivation = new UntypedFormGroup({
			businessAccount: new UntypedFormControl('0', [Validators.required]),
			whatsappAccount: new UntypedFormControl('0', [Validators.required]),
			whatsapp: new UntypedFormControl(false, []),
		});
		this.urlDevCallVideo = new UntypedFormGroup({
			urlDev: new UntypedFormControl('', [Validators.required]),
		});
		this.tokenVerify = new UntypedFormGroup({
			tokenV: new UntypedFormControl('', [Validators.required]),
		});
		this.urlDevCallVideo.controls['urlDev'].disable();
		this.tokenVerify.controls['tokenV'].disable();
		this.getData();
		this._membershipService.membershipOfCompany
			.pipe(
				takeUntil(this._unSuscribeAll),
				tap((membershipData) => {
					if (Object.keys(membershipData).length === 0) return membershipData;
					if (!membershipData.membership.current.WA) {
						this.router.navigate(['/dashboard/channels/whatsapp/firsts-steps']);
					}
				})
			)
			.subscribe();

		this._preferencesSrc.preferencesOfCompany.pipe(takeUntil(this._unSuscribeAll)).subscribe((result: any) => {
			this.onChagePref(result);
		});

		this.onChagePref(this._preferencesSrc.preferencesOfCompany.getValue());
	}

	onChagePref({ whatsapp = false, whatsappWelcome, whatsappCallbackToken, whatsappTempPage = '0', whatsappNumber = '0' }: any) {
		this.fbSdk.loginStatus();
		this.whatsapp = whatsapp;
		if (this.whatsapp) {
			this.titleAlign = 'center';
			this.descriptionAlign = 'center';
		} else {
			this.titleAlign = 'left';
			this.descriptionAlign = 'left';
		}
		this.isChatbotActive = whatsappWelcome ? true : false;
		if (whatsappCallbackToken) {
			this.tokenVerify.controls['tokenV'].setValue(whatsappCallbackToken);
			this.urlDevCallVideo.controls['urlDev'].setValue(environment.waLink + whatsappCallbackToken);
			this.formWhatsAppActivation.controls['businessAccount'].setValue(whatsappTempPage);
			if (!this.fbSdk.isLoggedIn) {
			} else {
				this.getBussisnessAccount();
				this.getWhatsappBusiness(whatsappTempPage);
			}

			this.formWhatsAppActivation.controls['whatsappAccount'].setValue(whatsappNumber);
			this.formWhatsAppActivation.controls['whatsapp'].setValue(whatsapp);
		} else {
			this.setTokenData();
			this.saveAll();
			if (whatsapp) this.formWhatsAppActivation.controls['whatsapp'].setValue(whatsapp);
		}
	}

	async getData() {
		this.initData();
	}

	async initData() {
		let token = localStorage.getItem('tokenAuth') || '';
		let decode = JSON.parse(atob(token.split('.')[1]));
		this.companyId = decode.company;
		this.level = decode.level >= 900;
	}
	ngOnDestroy() {
		this._unSuscribeAll.next({});
		this._unSuscribeAll.complete();
	}

	onChanges() {
		if (this.whatsapp) {
			this.titleAlign = 'center';
			this.descriptionAlign = 'center';
		} else {
			this.titleAlign = 'left';
			this.descriptionAlign = 'left';
		}
	}
	setTokenData() {
		let generateRandomString = (num: any) => {
			const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
			let result1 = Math.random().toString(36).substring(1, num);

			return result1;
		};
		let token = generateRandomString(10).replace('.', '');
		let mitad = this.companyId.substring(0, 12);
		let otraMitad = this.companyId.substring(12);
		let all = mitad + token + otraMitad;
		this.tokenVerify.controls['tokenV'].setValue(all);
		let urlAll = environment.waLink + all;
		this.urlDevCallVideo.controls['urlDev'].setValue(urlAll);
	}

	async getWhatsappBusiness(id: string) {
		const result = (await this.fbSdk.getWhatsappBusinessAccount(id)) as any;

		this.whatsappAccount = result.data;
	}
	ngOnInit(): void {}
	async saveAll() {
		try {
			await lastValueFrom(
				this._preferencesSrc.updatePreferences(this.companyId, { whatsappCallbackToken: this.tokenVerify.controls['tokenV'].value })
			);
		} catch (e) {
			console.log(e);
		}
	}
	async loginConFacebook() {
		const result = await this.fbSdk.login();
		this.getBussisnessAccount();
		this.upPrefUserData(result.authResponse.userID, result.authResponse.accessToken);
	}

	async getBussisnessAccount() {
		const result = (await this.fbSdk.getBusinessAccounts()) as any;
		this.whatsappPages = result.data;
	}

	async cerrarSessionFacebook() {
		const x = await this.fbSdk.logout();
		this.whatsappPages = [];
		this.whatsappAccount = [];
		this.formWhatsAppActivation.controls['businessAccount'].setValue('0');
		this.formWhatsAppActivation.controls['whatsappAccount'].setValue('0');
	}

	async upPrefUserData(userID: string, access: string) {
		try {
			await lastValueFrom(this.api.put(`/c/${this.companyId}/pref/user`, { facebookAccessToken: access, facebookIdUser: userID }));
		} catch (e) {
			console.log(e);
		}
	}

	openModal() {
		const confirmDialogRef = this._matDialog.open(ConfirmDialogComponent, {
			data: {
				item: {
					img: 'channel-whatsapp',
				},
				action: 'create',
				title: 'Canal de WhatsApp',
			},
			disableClose: false,
			panelClass: 'v-dialog',
			backdropClass: 'v-backdrop',
		});

		confirmDialogRef.afterClosed().subscribe(async (data: any) => {
			this.whatsapp = true;
			if (this.whatsapp) {
				this.titleAlign = 'center';
				this.descriptionAlign = 'center';
			}
		});
	}

	async setWhatsAppNumber(id: any) {
		try {
			let objPreference = {
				whatsappNumber: `${id}`,
				whatsappTempPage: this.formWhatsAppActivation.controls['businessAccount'].value,
			};
			await lastValueFrom(this._preferencesSrc.updatePreferences(this.companyId, objPreference));
			await lastValueFrom(this._preferencesSrc.enableDisablePreferences(this.companyId, { whatsapp: true }));
			this.openModal();
		} catch (e) {
			console.log(e);
		}
	}
	async setDesactivatewhatsapp() {
		try {
			let objPreference = {
				whatsapp: false,
			};
			await lastValueFrom(this._preferencesSrc.enableDisablePreferences(this.companyId, objPreference));
		} catch (e) {
			console.log(e);
		}
	}
	async toggleWhatsapp() {
		try {
			let objPreference = {
				whatsapp: !this.formWhatsAppActivation.controls['whatsapp'].value,
			};
			this.whatsapp = !this.formWhatsAppActivation.controls['whatsapp'].value;
			let valor = await lastValueFrom(this._preferencesSrc.enableDisablePreferences(this.companyId, objPreference));
		} catch (e: any) {
			console.log(e);
		}
	}
	async disconnectChannel() {
		try {
			let objModule = {
				channel: 'whatsapp',
			};
			let mod = await lastValueFrom(this.api.put(`/c/${this.companyId}/pref/disconnect/channels`, objModule));

			if (mod.code) {
			}
			if (mod.ok) {
				this.formWhatsAppActivation.controls['businessAccount'].setValue('0');
				this.formWhatsAppActivation.controls['whatsappAccount'].setValue('0');
				this.formWhatsAppActivation.controls['whatsapp'].setValue(false);
				this.setTokenData();
				this.toastService.initiate({
					title: 'El canal de WhatsApp ha sido desconectado',
					content: 'El canal de WhatsApp fue desconectado totalmente.',
					type: 'success',
				});
				this.whatsapp = false;
				this.titleAlign = 'left';
				this.descriptionAlign = 'left';
			}

			await lastValueFrom(this._preferencesSrc.getPreferences(this.companyId));
		} catch (e) {
			console.log(e);
		}
	}
}
