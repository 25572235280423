<div class="wrapper__steps">
	<div class="stepsContainer">
		<div class="stepsContainer__header">
			<p class="stepsContainer__header__title">
				{{ data.title }} <span class="stepsContainer__header__step">{{ data.step }}/{{ data.maxSteps }}</span>
			</p>
		</div>
		<div class="stepsContainer__body">
			<p class="stepsContainer__body__text">
				{{ data.text }}
			</p>
		</div>
		<div class="stepsContainer__footer">
			<button class="btnNavigationTour" (click)="changeStep('prev')" *ngIf="data.hasPrev">
				<v-icon name="prevTour" class="icon"></v-icon>
			</button>
			<button class="btnNavigationTour" (click)="changeStep('next')">
				<v-icon name="nextTour" class="icon"></v-icon>
			</button>
		</div>
	</div>
	<button class="wrapper__steps__btnClose" (click)="exitTour()">
		<v-icon name="v-close"></v-icon>
	</button>
</div>
