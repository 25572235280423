import { Component, OnInit, Input, Output, HostListener, EventEmitter, AfterViewInit, OnChanges, SimpleChanges } from '@angular/core';

@Component({
	selector: 'v-option',
	templateUrl: './v-option.component.html',
	styleUrls: ['./v-option.component.scss'],
})
export class VOptionComponent implements OnInit, AfterViewInit, OnChanges {
	@Input('item') item: any = {};
	@Input('customClass') customClass: any = '';
	@Input('config') config?: {
		multiple?: boolean;
		withIcon?: boolean;
		onlyIcon?: boolean;
	} = {
		multiple: false,
		withIcon: false,
		onlyIcon: false,
	};
	@Input('isSelected') isSelected: boolean = false;

	@Output('toggleSelection') toggleSelectionEvent: EventEmitter<any> = new EventEmitter();

	@HostListener('click')
	onClick() {
		this.toggleSelection();
	}

	public selected: boolean = false;
	constructor() {}

	ngOnInit(): void {}
	ngAfterViewInit(): void {
		this.selected = this.isSelected;
	}
	ngOnChanges(changes: SimpleChanges): void {
		this.selected = this.isSelected;
	}

	toggleSelection() {
		this.selected = !this.selected;
		this.toggleSelectionEvent.emit({ isSelect: this.selected, item: this.item });
	}
}
