import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'vl-card-description',
  templateUrl: './vl-card-description.component.html',
  styleUrls: ['./vl-card-description.component.scss']
})
export class VlCardDescriptionComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
