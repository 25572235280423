<h2 class="vl-title-view">Resolutividad</h2>

<div class="vl-wrapper-horizontal">
	<ul class="vl-line_time_options">
		<li class="vl-line_time_options__option" *ngFor="let option of line_time_options; let i = index">
			<button [ngClass]="['vl-line_time_options__option__btn', line_time_active === i ? 'selected' : '']" (click)="setLineTimeActive(i)">
				{{ option?.label }}
			</button>
		</li>
	</ul>
	<div class="vl-wrapper-horizontal" *ngIf="[4].includes(line_time_active)">
		<p class="vl-form_group--vertical">
			<label class="vl-form_group--vertical__label" for="from">Desde:</label>
			<span class="vl-input-wrapper--date" (click)="pickerFrom.open()">
				<input
					type="text"
					placeholder="dd/mm/aaaa"
					[matDatepicker]="pickerFrom"
					name="from"
					class="vl-input-wrapper--date__input"
					[disabled]="true"
					[formControl]="filters.controls['from']"
					[max]="filters.controls['to'].value"
				/>
				<v-icon class="vl-input-wrapper--date__icon" name="calendar"></v-icon>
				<mat-datepicker #pickerFrom [disabled]="false"></mat-datepicker>
			</span>
		</p>
		<p class="vl-form_group--vertical">
			<label class="vl-form_group--vertical__label" for="to">Hasta:</label>
			<span class="vl-input-wrapper--date" (click)="pickerTo.open()">
				<input
					type="text"
					placeholder="dd/mm/aaaa"
					[matDatepicker]="pickerTo"
					name="to"
					class="vl-input-wrapper--date__input"
					[formControl]="filters.controls['to']"
					[disabled]="true"
					[min]="filters.controls['from'].value"
					[max]="today"
				/>
				<v-icon class="vl-input-wrapper--date__icon" name="calendar"></v-icon>
				<mat-datepicker #pickerTo [disabled]="false"></mat-datepicker>
			</span>
		</p>
	</div>
</div>

<div class="vl-card vl-card__average">
	<p class="vl-card-title">General</p>
	<p class="vl-average-resolutivy">{{ resolutivityGeneral.average }}%</p>
</div>

<div class="vl-card vl-grafic">
	<google-chart
		*ngIf="totalAttentions"
		[type]="graficConfig.type"
		[options]="graficConfig.options"
		[data]="graficConfig.data"
		style="width: 100%; height: 330px"
		[dynamicResize]="true"
	></google-chart>
	<p style="text-align: right">Atenciones totales: {{ totalAttentions }}</p>
</div>

<div class="vl-card vl-card__agents">
	<p class="vl-card-title">Por Agente</p>
	<input type="text" class="vl-input-search" placeholder="Buscar" #search (input)="searchAgent(search?.value ?? '')" />
	<ul class="vl-card__agents__list">
		<li class="vl-card__agents__list__headers">
			<p class="vl-card__agents__list__headers__text">Agente</p>
			<p class="vl-card__agents__list__headers__text">Porcentaje</p>
		</li>
		<li class="vl-card__agents__list__agent" *ngFor="let agent of agents">
			<p class="vl-card__agents__list__agent_fullName">
				{{ agent.firstname | titlecase }} {{ agent.lastname | titlecase }} <br />
				({{ agent.email }})
			</p>
			<p class="vl-card__agents__list__agent_percentage">{{ agent.percentage }}%</p>
		</li>
	</ul>
</div>

<div class="vl-card vl-card__cannels">
	<p class="vl-card-title">Por canal</p>
	<ul class="vl-list-channels">
		<li class="vl-list-channels__item" *ngFor="let channel of channels">
			<div class="vl-card-percentage resolutivity">
				<div class="vl-card-percentage__img">
					<img [src]="channel?.imgPath" alt="channel icon" />
				</div>
				<p class="vl-card-percentage__label">{{ channel?.label }}</p>
				<div class="vl-card-percentage__wrapper_bar">
					<div class="vl-card-percentage__wrapper_bar__bar" [ngStyle]="{ '--bg-color': channel?.color + '33' }">
						<div
							class="vl-card-percentage__wrapper_bar__bar__fill"
							[ngStyle]="{ '--bg-color': channel?.color, '--w': channel?.percentage + '%' }"
						></div>
					</div>
					<p class="vl-card-percentage__wrapper_bar__percentage">{{ channel?.percentage }}%</p>
				</div>
			</div>
		</li>
	</ul>
</div>
