import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { DashboardModule } from './@views/dashboard/dashboard.module';
import { HashTokenGuard as hasTokenGuard } from './guards/hash-token.guard';

const routes: Routes = [
	{
		path: 'auth',
		loadChildren: () => import('./@views/auth/auth.module').then((m) => m.AuthModule),
	},
	{
		path: '',
		canLoad: [hasTokenGuard],
		canActivate: [hasTokenGuard],
		loadChildren: () => import('./@views/dashboard/dashboard.module').then((m) => m.DashboardModule),
	},
	{
		path: '**',
		redirectTo: 'auth/login',
	},
];

@NgModule({
	imports: [RouterModule.forRoot(routes)],
	exports: [RouterModule],
	providers: [DashboardModule],
})
export class AppRoutingModule {}
