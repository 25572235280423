import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-title-section',
  templateUrl: './title-section.component.html',
  styleUrls: ['./title-section.component.scss']
})
export class TitleSectionComponent implements OnInit {
  @Input() title = ''
  @Input() description = ''
  @Input() titleAlign = 'left'
  @Input() descriptionAlign = 'left'

  constructor() { }

  ngOnInit(): void {
  }

}
