<main *ngIf="isLoaded">
	<div class="service-container" *ngIf="!whatsappMembership">
		<div class="one-line">
			<div class="img-container">
				<img src="../../../../assets/img/whatsappwhite.svg" alt="logotipo de whatsapp" />
			</div>
			<div class="text-container">
				<p>Adquiere WhatsApp por tan solo 20 $ mensuales</p>
			</div>
		</div>
		<div class="button-container">
			<button [routerLink]="['/dashboard/profile/pricing']">Comprar</button>
		</div>
	</div>
	<app-title-section [title]="contentTitle" [description]="contentDescription"></app-title-section>
	<div class="content">
		<div class="steps-container" *ngIf="getScreenWidth >= 720">
			<div class="step-item" *ngFor="let itemStep of steps; let i = index">
				<div class="step-item-interno">
					<img src="{{ itemStep.imgUrl }}" alt="{{ itemStep.imgAlt }}" class="img-step" />
					<div class="title-container">
						<p class="title">{{ itemStep.title }}</p>
					</div>
					<p class="step-text">{{ itemStep.step }}</p>
				</div>
				<div class="step-item-externo">
					<hk-tooltip-info [text]="itemStep.tooltipText" [position]="itemStep.tooltipPosition"></hk-tooltip-info>
				</div>
			</div>
		</div>
		<div class="content-recommend" *ngIf="getScreenWidth < 720">
			<div class="content-box">
				<p>
					Te recomendamos usar una computadora para la vinculación de tu <strong>WhatsApp</strong> con
					<strong>Videolink.</strong>
				</p>
			</div>
			<img src="../../../../assets/img/only-phone-whatsapp-recommend.png" alt="Mujer con una computadora" />
		</div>
		<div class="informative-container" *ngIf="getScreenWidth >= 1024">
			<p class="text">
				Debe tener una cuenta comercial de Meta Business (Facebook Business) verificada y con una cuenta de WhatsApp activa. Si no lo posee
				lo ayudamos a crearla siguiendo unos sencillos pasos:
			</p>
		</div>
		<div class="stepper-container" *ngIf="getScreenWidth >= 1024">
			<div class="Group__form__body">
				<mat-accordion>
					<mat-stepper
						(selectionChange)="changeStepper($event)"
						class="Group__stepper"
						orientation="vertical"
						animationDuration="1000"
						linear
						#stepper
					>
						<mat-step completed="false" [stepControl]="formStep">
							<ng-template matStepLabel>
								<div class="Group__stepper__title">Paso 1. Cuenta Comercial de Meta Business</div>
							</ng-template>

							<div class="Group__stepper__section" id="section-1">
								<p class="p-content">
									Es un espacio central de Meta (Facebook) donde puedes integrar toda la información de tu empresa, así como la actividad de
									marketing de Facebook en un solo lugar. Te permite compartir, con facilidad y eficacia, el acceso a los activos con todo
									tu equipo, así como con agencias externas y proveedores como Videolink.
								</p>
								<div class="container-gif">
									<div class="text-container">
										<p class="p-content">
											1. Si <strong> NO tienes </strong>una cuenta comercial puedes crearla acá
											<a target="_blank" href="https://business.facebook.com/overview">Overview</a>
										</p>
									</div>
									<div class="video-responsive">
										<iframe
											src="https://www.youtube.com/embed/qeGqdPrG6z4"
											title="video de cuenta comercial de meta business"
											frameborder="0"
											allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
											allowfullscreen
										></iframe>
									</div>
								</div>

								<div class="Group__stepper__section__btn_actions">
									<button class="btn_form invert" matStepperNext (click)="saveStep(1)">Siguiente</button>
								</div>
							</div>
						</mat-step>
						<mat-step completed="false" [stepControl]="formStep">
							<ng-template matStepLabel>
								<div class="Group__stepper__title">Paso 2. Número de WhatsApp</div>
							</ng-template>
							<div class="Group__stepper__section">
								<div class="animos-container">
									<img
										src="../../../../assets/img/paso2-animos.png"
										alt="imagen con texto muy bien, vas muy bien, en este paso vas a necesitar un numero de telefono que no esté registrado en WhatsApp"
										class="animos-img"
									/>
								</div>
								<div class="container-gif" id="section-2">
									<div class="text-container">
										<p class="p-content">1. Debe ser un número verificable vía SMS (le enviarán un código de verificación).</p>
										<p class="p-content">2. No debe tener cuenta activa de WhatsApp ni WhatsApp Business.</p>
										<p class="p-content-left">
											En caso de tenerla, deberá eliminarla
											<a href="https://business.facebook.com/" target="_blank">Facebook Business</a>
										</p>
										<p class="p-content-left">(Configuración->Más opciones de configuración del negocio->Cuenta->Eliminar mi cuenta)</p>
									</div>
									<div class="video-responsive">
										<iframe
											src="https://www.youtube.com/embed/flO7PIGlOOo"
											title="Número de WhatsApp"
											frameborder="0"
											allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
											allowfullscreen
										></iframe>
									</div>
								</div>
								<div class="Group__stepper__section__btn_actions">
									<button class="btn_form_before" matStepperPrevious (click)="setTabActive(); saveStep(0)">Volver</button>
									<button class="btn_form invert" matStepperNext (click)="saveStep(2)">Siguiente</button>
								</div>
							</div>
						</mat-step>
						<mat-step completed="false" [stepControl]="formStep">
							<ng-template matStepLabel>
								<div class="Group__stepper__title">Paso 3. Agregar número de WhatsApp a la cuenta comercial</div>
							</ng-template>
							<div class="Group__stepper__section">
								<div class="cbody">
									<div class="contentTriggersTab">
										<button class="triggersTab" (click)="setContentTrigger(1)" [ngClass]="[contentTrigger.one ? 'active' : '']">
											Crear APP
										</button>
										<button class="triggersTab" (click)="setContentTrigger(2)" [ngClass]="[contentTrigger.two ? 'active' : '']">
											Agregar número
										</button>
										<button class="triggersTab" (click)="setContentTrigger(3)" [ngClass]="[contentTrigger.three ? 'active' : '']">
											Usuario de sistema
										</button>
									</div>
								</div>
								<div *ngIf="contentTrigger.one" class="container-gif">
									<div class="text-container" id="section-4" id="section-3">
										<p class="p-content">1. Crear Aplicación en Meta para Desarrolladores.</p>
										<p class="p-content">
											2. Ir a
											<a href="https://developers.facebook.com" target="_blank">https://developers.facebook.com</a>
										</p>
										<p class="p-content">3. Iniciar sesión con la cuenta de Facebook del Administrador de la Cuenta Comercial.</p>
									</div>
									<img src="../../../../assets/img/iniciar-sesion-facebook.png" alt="iniciar sesion en facebook" class="img-step-fb" />
									<div class="text-container">
										<p class="p-content">4. Dar click en "Empezar (Get Started)"</p>
										<p class="p-content">5. Seleccionar cargo de "desarrollador"</p>
									</div>
									<div class="video-responsive">
										<iframe
											src="https://www.youtube.com/embed/NvT4i9bdPGc"
											title="crear cuenta developers"
											frameborder="0"
											allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
											allowfullscreen
										></iframe>
									</div>
									<div class="text-container">
										<p class="p-content">6. Dar click en "Crear app"</p>
										<p class="p-content">
											7. Seleccionar tipo de aplicación de Negocios: Se crea una aplicación de este tipo para poder acceder a las API de
											mensajería de Facebook, WhatsApp e Instagram.
										</p>
										<p class="p-content">8. Dar click en "Siguiente"</p>
										<p class="p-content">9. Proporcionar información básica.</p>
										<p class="p-content-left">
											<strong>Nombre:</strong> ingresar nombre genérico para identificar la aplicación. Ejemplo: Farmacia Alivio App
										</p>
										<p class="p-content-left"><strong>Correo:</strong> ingresar correo de contacto</p>
										<p class="p-content-left">
											<strong>Cuenta comercial:</strong> Debemos seleccionar la Cuenta Comercial de nuestra compañía, aunque este paso dice
											que es Opcional para nuestro caso es obligatorio.
										</p>
										<p class="p-content">10. Dar click en "Crear app".</p>
										<p class="p-content">11. Ingresar contraseña.</p>
									</div>
									<div class="video-responsive">
										<iframe
											src="https://www.youtube.com/embed/LFy9Gsz4vOs"
											title="YouTube video player"
											frameborder="0"
											allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
											allowfullscreen
										></iframe>
									</div>
									<div class="button-container">
										<button class="button-blue invert" matStepperPrevious>Volver</button>
										<button class="button-blue" (click)="setContentTrigger(2); setTabActive()">Siguiente</button>
									</div>
								</div>
								<div class="animos-container">
									<img
										src="../../../../assets/img/animo3sero.png"
										alt="imagen con texto el 3er paso es el más largo, pero con esta guía se hará más fácil"
										*ngIf="contentTrigger.one"
										class="animos-img"
									/>
								</div>
								<div *ngIf="contentTrigger.two" class="container-gif">
									<div class="text-container">
										<p class="p-content" id="section-5">
											1. Agregar número de WhatsApp en Meta para Desarrolladores
											<a href="https://developers.facebook.com" target="_blank">https://developers.facebook.com</a>
										</p>
										<p class="p-content">2. Dar click en "Configurar" en el panel de WhatsApp.</p>
										<p class="p-content">3. Dar click en "Continuar" teniendo la cuenta comercial de Meta seleccionada.</p>
										<p class="p-content">4. Dar click en Agregar número de teléfono en el paso 5.</p>
										<p class="p-content">
											5. Llenar formulario de Perfil de WhatsApp Business, indicando el nombre a mostrar, categoría y descripción del
											negocio y dar click en "Siguiente".
										</p>
										<p class="p-content">6. Agregar número de teléfono para WhatsApp dando click en "Siguiente".</p>
										<p class="p-content">
											7. Verifica el número de teléfono ingresando el código a SMS o llamada y dar click a "Siguiente".
										</p>
									</div>
									<div class="video-responsive">
										<iframe
											src="https://www.youtube.com/embed/kXl1ugLDniM"
											title=""
											frameborder="0"
											allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
											allowfullscreen
										></iframe>
									</div>
									<div class="text-container">
										<p class="p-content">8. Obtén el ID del número de teléfono.</p>
									</div>
									<img src="../../../../assets/img/identificador-img-ilus.png" alt="" class="img-ilus-step" />
									<form class="form-container" [formGroup]="whatsappIdentificator">
										<div class="input-container">
											<div class="label-container">
												<label for="whatsappIdentificator"
													>Identificador del número de teléfono de WhatsApp
													<hk-tooltip-info
														text="Ingresa en el campo el identificador del número de teléfono de WhatsApp proporcionado por META."
														position="left"
													></hk-tooltip-info
												></label>
											</div>
											<div class="input-container">
												<textarea
													placeholder="Pega aquí el Identificador del número de teléfono de WhatsApp"
													formControlName="identificationNumberWhatsApp"
													id="whatsappIdentificator"
													(focusout)="savePreferences(false)"
												></textarea>
												<span
													class="text-input-warning"
													*ngIf="
														whatsappIdentificator.controls['identificationNumberWhatsApp'].getError('maxlength') &&
														(whatsappIdentificator.controls['identificationNumberWhatsApp'].touched ||
															whatsappIdentificator.controls['identificationNumberWhatsApp'].dirty)
													"
												>
													El identificador del número de teléfono no puede ser superior a 17 dígitos.
												</span>
												<span
													class="text-input-warning"
													*ngIf="
														whatsappIdentificator.controls['identificationNumberWhatsApp'].getError('minlength') &&
														(whatsappIdentificator.controls['identificationNumberWhatsApp'].touched ||
															whatsappIdentificator.controls['identificationNumberWhatsApp'].dirty)
													"
												>
													El identificador del número de teléfono no puede ser menor a 10 dígitos.
												</span>
												<span
													class="text-input-warning"
													*ngIf="
														whatsappIdentificator.controls['identificationNumberWhatsApp'].getError('required') &&
														(whatsappIdentificator.controls['identificationNumberWhatsApp'].touched ||
															whatsappIdentificator.controls['identificationNumberWhatsApp'].dirty)
													"
												>
													El identificador del número de teléfono es requerido.
												</span>
												<span
													class="text-input-warning"
													*ngIf="
														whatsappIdentificator.controls['identificationNumberWhatsApp'].getError('´pattern') &&
														(whatsappIdentificator.controls['identificationNumberWhatsApp'].touched ||
															whatsappIdentificator.controls['identificationNumberWhatsApp'].dirty)
													"
												>
													El identificador del número de teléfono de WhatsApp sólo aceptan números.
												</span>
											</div>
										</div>
									</form>
									<ng-content *ngIf="whatsappIdentificator.invalid; then invalidElementWhat; else validElementWhat"></ng-content>
									<ng-template #validElementWhat>
										<div class="button-container">
											<button class="button-blue invert" (click)="setContentTrigger(1); setTabActive()">Volver</button>
											<button class="button-blue" (click)="setContentTrigger(3); setTabActive()">Siguiente</button>
										</div>
									</ng-template>
									<ng-template #invalidElementWhat>
										<div class="button-container">
											<button class="button-blue invert" (click)="setContentTrigger(1); setTabActive()">Volver</button>
											<button class="button-blue disabled" disabled (click)="setContentTrigger(3)">Siguiente</button>
										</div>
									</ng-template>
								</div>
								<div class="animos-container">
									<img
										src="../../../../assets/img/animo4to.png"
										alt="imagen con texto Excelente! Ya te falta poco"
										*ngIf="contentTrigger.two"
										class="animos-img"
									/>
								</div>
								<div *ngIf="contentTrigger.three" class="container-gif" id="section-6">
									<div class="text-container">
										<p class="p-content">Crear usuario de sistema de "VideoLink" como Administrador (para obtener el token de acceso)</p>
										<p class="p-content">
											1. Ingresamos en la business console del negocio al cual queremos crearle el usuario de sistema
											<a target="_blank" href="https://business.facebook.com/latest/home" rel="noopener noreferrer"
												>"https://business.facebook.com/latest/home"</a
											>, previamente seleccionando el negocio en la parte superior izquierda.
										</p>
										<p class="p-content">
											2. Damos click en panel del lado izquierdo en el botón de configuración, esto nos llevará al panel de configuración
											del negocio, damos click en “Más Opciones de configuración”.
										</p>
										<p class="p-content">3. Hacemos click en el hipervínculo de “Usuarios de sistema” en la sección de usuario.</p>
										<p class="p-content">4. Damos click en el botón "Agregar".</p>
										<p class="p-content">
											5. Creamos un usuario con el nombre “Videolink” con el rol de administrador, hacemos click en “Crear usuario de
											sistema”.
										</p>
									</div>
									<img src="../../../../assets/img/usuario-sistema-facebook.png" alt="crear usuario del sistema" class="img-step-fb2" />
									<div class="text-container">
										<p class="p-content">
											6. Generar Token Definitivo: Una vez creado el usuario lo seleccionamos y damos click en Generar nuevo token, esto
											desplegará un modal donde seleccionaremos la aplicación que creamos anteriormente.
										</p>
										<p class="p-content">
											7. Una vez seleccionada la aplicación se desplegará un listado de los permisos que podemos seleccionar, Debemos
											seleccionar los siguientes permisos:
										</p>
										<ul>
											<li>whatsapp_business_messaging</li>
											<li>whatsapp_business_management</li>
											<li>business_management</li>
										</ul>
										<p class="p-content">8. Damos click en Generar token, Copiamos el token que nos da el modal y lo pegas aquí.</p>
									</div>
									<form class="form-container" [formGroup]="whatsappMetaToken">
										<div class="input-container">
											<div class="label-container">
												<label for="metaToken"
													>Whatsapp META token
													<hk-tooltip-info
														text="Indica en el área de texto, el META token generado desde WhatsApp."
														position="left"
													></hk-tooltip-info
												></label>
											</div>
											<div class="input-container">
												<textarea
													placeholder="Pega aquí el WhatsApp META token"
													formControlName="whatsappMetaToken"
													id="metaToken"
													(focusout)="savePreferences(true)"
													maxlength="250"
												></textarea>
												<span
													class="text-input-warning"
													*ngIf="
														whatsappMetaToken.controls['whatsappMetaToken'].getError('required') &&
														(whatsappMetaToken.controls['whatsappMetaToken'].touched ||
															whatsappMetaToken.controls['whatsappMetaToken'].dirty)
													"
												>
													El WhatsApp META Token es requerido.
												</span>
												<span
													class="text-input-warning"
													*ngIf="
														whatsappMetaToken.controls['whatsappMetaToken'].getError('maxlength') &&
														(whatsappMetaToken.controls['whatsappMetaToken'].touched ||
															whatsappMetaToken.controls['whatsappMetaToken'].dirty)
													"
												>
													El WhatsApp META token debe tener máximo 250 caracteres.
												</span>
											</div>
										</div>
									</form>
									<div class="video-responsive">
										<iframe
											src="https://www.youtube.com/embed/ZwICbYn3zus"
											title="YouTube video player"
											frameborder="0"
											allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
											allowfullscreen
										></iframe>
									</div>
									<div class="text-img-container">
										<p class="p-content">9. Seguido a esto damos click en el botón que dice</p>
										<img src="../../../../assets/img/whatsapp-ilus-activos.png" alt="" class="img-side-p" />
									</div>
									<div class="text-container">
										<p class="p-content">
											10. Esto desplegará un modal en donde debemos ir a la sección de apps (pánel del lado izquierdo)
										</p>
										<p class="p-content">
											11. Seleccionaremos la app creada previamente, activamos el control total de la app y damos click en "Guardar cambios"
										</p>
									</div>
									<div class="video-responsive">
										<iframe
											src="https://www.youtube.com/embed/Y20pqLFqB0I"
											title="YouTube video player"
											frameborder="0"
											allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
											allowfullscreen
										></iframe>
									</div>
								</div>
								<div class="animos-container">
									<img src="../../../../assets/img/animos5to.png" alt="" *ngIf="contentTrigger.three" class="animos-img" />
								</div>
								<div *ngIf="whatsappMetaToken.invalid; then invalidElement; else validElement"></div>
								<ng-template #validElement>
									<div class="Group__stepper__section__btn_actions" *ngIf="contentTrigger.three">
										<button class="btn_form_before" (click)="saveStep(2)">Volver</button>
										<button class="btn_form invert" (click)="setTabActive(); saveStep(3)" matStepperNext>Siguiente</button>
									</div>
								</ng-template>
								<ng-template #invalidElement>
									<div class="Group__stepper__section__btn_actions" *ngIf="contentTrigger.three">
										<button class="btn_form_before" matStepperPrevious>Volver</button>
										<button class="btn_form invert" [disabled]="whatsappMetaToken.invalid" (click)="markAsTouchedForm()">Siguiente</button>
									</div>
								</ng-template>
							</div>
						</mat-step>
						<mat-step completed="false" [stepControl]="formStep" id="section-7">
							<ng-template matStepLabel>
								<div class="Group__stepper__title">Paso 4. Verificar cuenta comercial</div>
							</ng-template>
							<div class="Group__stepper__section">
								<div class="animos-container">
									<img src="../../../../assets/img/paso4lograrias.png" alt="sabia que lo lograrías " class="animos-img-custom" />
								</div>
								<div class="container-gif">
									<div class="text-container">
										<p class="p-content">
											1. Para conectar la mensajería de META a Videolink es necesario la verificación de la cuenta comercial en META, este
											proceso depende de META y sus procesos de verificación, aproximadamente tardan de 1 a 3 días en dar respuesta. Acá te
											explicamos como hacerlo
										</p>
										<p class="p-content">
											2. Ir a más opciones de configuración de negocio -> Centro de Seguridad -> Iniciar Verificación
											<a href="https://business.facebook.com" target="_blank" rel="noopener noreferrer">https://business.facebook.com/</a>
										</p>
										<p class="p-content">3. Seleccionar país.</p>
										<p class="p-content">4. Agregar datos de la organización.</p>
										<p class="p-content">5. Seleccionar método de contacto.</p>
										<p class="p-content">6. Agregar documentos de acreditación.</p>
										<p class="p-content">7. Confirmar número de teléfono</p>
									</div>
									<div class="video-responsive">
										<iframe
											src="https://www.youtube.com/embed/C0rD_Vm2dKk"
											title="YouTube video player"
											frameborder="0"
											allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
											allowfullscreen
										></iframe>
									</div>
								</div>
								<div class="Group__stepper__section__btn_actions">
									<button class="btn_form_before" matStepperPrevious (click)="setTabActive(); saveStep(3)">Volver</button>
									<button class="btn_form invert" (click)="finishStep(); saveStep(4)">Finalizar</button>
								</div>
							</div>
						</mat-step>
					</mat-stepper>
				</mat-accordion>
			</div>
		</div>

		<div class="stepper-container" *ngIf="getScreenWidth < 1024">
			<div class="Group__form__body">
				<mat-accordion>
					<mat-stepper (selectionChange)="changeStepper($event)" class="Group__stepper" animationDuration="1000" linear #stepper>
						<mat-step completed="false" [stepControl]="formStep">
							<div class="Group__stepper__title">Paso 1. Cuenta Comercial de Meta Business</div>

							<div class="Group__stepper__section" id="section-1">
								<p class="p-content">
									Es un espacio central de Meta (Facebook) donde puedes integrar toda la información de tu empresa, así como la actividad de
									marketing de Facebook en un solo lugar. Te permite compartir, con facilidad y eficacia, el acceso a los activos con todo
									tu equipo, así como con agencias externas y proveedores como Videolink.
								</p>
								<div class="container-gif">
									<div class="text-container">
										<p class="p-content">
											1. Si <strong> NO tienes </strong>una cuenta comercial puedes crearla acá
											<a target="_blank" href="https://business.facebook.com/overview">Overview</a>
										</p>
									</div>
									<div class="video-responsive">
										<iframe
											src="https://www.youtube.com/embed/qeGqdPrG6z4"
											title="vidoe de cuenta comercial de meta business"
											frameborder="0"
											allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
											allowfullscreen
										></iframe>
									</div>
								</div>

								<div class="Group__stepper__section__btn_actions">
									<button class="btn_form invert" matStepperNext (click)="saveStep(1)">Siguiente</button>
								</div>
							</div>
						</mat-step>
						<mat-step completed="false" [stepControl]="formStep">
							<div class="Group__stepper__title">Paso 2. Número de WhatsApp</div>
							<div class="Group__stepper__section">
								<div class="animos-container" *ngIf="getScreenWidth > 500">
									<img
										src="../../../../assets/img/paso2-animos.png"
										alt="imagen con texto muy bien, vas muy bien, en este paso vas a necesitar un numero de telefono que no esté registrado en WhatsApp"
										class="animos-img"
									/>
								</div>
								<div class="container-gif" id="section-2">
									<div class="text-container">
										<p class="p-content">1. Debe ser un número verificable vía SMS (le enviarán un código de verificación).</p>
										<p class="p-content">2. No debe tener cuenta activa de WhatsApp ni WhatsApp Business.</p>
										<p class="p-content-left">
											En caso de tenerla, deberá eliminarla
											<a href="https://business.facebook.com/" target="_blank">Facebook Business</a>
										</p>
										<p class="p-content-left">(Configuración->Más opciones de configuración del negocio->Cuenta->Eliminar mi cuenta)</p>
									</div>
									<div class="video-responsive">
										<iframe
											src="https://www.youtube.com/embed/flO7PIGlOOo"
											title="Número de WhatsApp"
											frameborder="0"
											allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
											allowfullscreen
										></iframe>
									</div>
								</div>
								<div class="Group__stepper__section__btn_actions">
									<button class="btn_form_before" matStepperPrevious (click)="setTabActive(); saveStep(0)">Volver</button>
									<button class="btn_form invert" matStepperNext (click)="saveStep(2)">Siguiente</button>
								</div>
							</div>
						</mat-step>
						<mat-step completed="false" [stepControl]="formStep">
							<div class="Group__stepper__title">Paso 3. Agregar número de WhatsApp a la cuenta comercial</div>
							<div class="Group__stepper__section">
								<div class="cbody">
									<div class="contentTriggersTab">
										<button class="triggersTab" (click)="setContentTrigger(1)" [ngClass]="[contentTrigger.one ? 'active' : '']">
											Crear APP
										</button>
										<button class="triggersTab" (click)="setContentTrigger(2)" [ngClass]="[contentTrigger.two ? 'active' : '']">
											Agregar número
										</button>
										<button class="triggersTab" (click)="setContentTrigger(3)" [ngClass]="[contentTrigger.three ? 'active' : '']">
											Usuario de sistema
										</button>
									</div>
								</div>
								<div *ngIf="contentTrigger.one" class="container-gif">
									<div class="text-container" id="section-4" id="section-3">
										<p class="p-content">1. Crear Aplicación en Meta para Desarrolladores.</p>
										<p class="p-content">
											2. Ir a
											<a href="https://developers.facebook.com" target="_blank">https://developers.facebook.com</a>
										</p>
										<p class="p-content">3. Iniciar sesión con la cuenta de Facebook del Administrador de la Cuenta Comercial.</p>
									</div>
									<img src="../../../../assets/img/iniciar-sesion-facebook.png" alt="iniciar sesion en facebook" class="img-step-fb" />
									<div class="text-container">
										<p class="p-content">4. Dar click en "Empezar (Get Started)"</p>
										<p class="p-content">5. Seleccionar cargo de "desarrollador"</p>
									</div>
									<div class="video-responsive">
										<iframe
											src="https://www.youtube.com/embed/NvT4i9bdPGc"
											title="crear cuenta developers"
											frameborder="0"
											allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
											allowfullscreen
										></iframe>
									</div>
									<div class="text-container">
										<p class="p-content">6. Dar click en "Crear app"</p>
										<p class="p-content">
											7. Seleccionar tipo de aplicación de Negocios: Se crea una aplicación de este tipo para poder acceder a las API de
											mensajería de Facebook, WhatsApp e Instagram.
										</p>
										<p class="p-content">8. Dar click en "Siguiente"</p>
										<p class="p-content">9. Proporcionar información básica.</p>
										<p class="p-content-left">
											<strong>Nombre:</strong> ingresar nombre genérico para identificar la aplicación. Ejemplo: Farmacia Alivio App
										</p>
										<p class="p-content-left"><strong>Correo:</strong> ingresar correo de contacto</p>
										<p class="p-content-left">
											<strong>Cuenta comercial:</strong> Debemos seleccionar la Cuenta Comercial de nuestra compañía, aunque este paso dice
											que es Opcional para nuestro caso es obligatorio.
										</p>
										<p class="p-content">10. Dar click en "Crear app".</p>
										<p class="p-content">11. Ingresar contraseña.</p>
									</div>
									<div class="video-responsive">
										<iframe
											src="https://www.youtube.com/embed/LFy9Gsz4vOs"
											title="YouTube video player"
											frameborder="0"
											allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
											allowfullscreen
										></iframe>
									</div>
									<div class="button-container">
										<button class="button-blue invert" matStepperPrevious>Volver</button>
										<button class="button-blue" (click)="setContentTrigger(2); setTabActive()">Siguiente</button>
									</div>
								</div>
								<div class="animos-container" *ngIf="getScreenWidth > 500">
									<img
										src="../../../../assets/img/animo3sero.png"
										alt="imagen con texto el 3er paso es el más largo, pero con esta guía se hará más fácil"
										*ngIf="contentTrigger.one"
										class="animos-img"
									/>
								</div>
								<div *ngIf="contentTrigger.two" class="container-gif">
									<div class="text-container">
										<p class="p-content" id="section-5">
											1. Agregar número de WhatsApp en Meta para Desarrolladores
											<a href="https://developers.facebook.com" target="_blank">https://developers.facebook.com</a>
										</p>
										<p class="p-content">2. Dar click en "Configurar" en el panel de WhatsApp.</p>
										<p class="p-content">3. Dar click en "Continuar" teniendo la cuenta comercial de Meta seleccionada.</p>
										<p class="p-content">4. Dar click en Agregar número de teléfono en el paso 5.</p>
										<p class="p-content">
											5. Llenar formulario de Perfil de WhatsApp Business, indicando el nombre a mostrar, categoría y descripción del
											negocio y dar click en "Siguiente".
										</p>
										<p class="p-content">6. Agregar número de teléfono para WhatsApp dando click en "Siguiente".</p>
										<p class="p-content">
											7. Verifica el número de teléfono ingresando el código a SMS o llamada y dar click a "Siguiente".
										</p>
									</div>
									<div class="video-responsive">
										<iframe
											src="https://www.youtube.com/embed/kXl1ugLDniM"
											title=""
											frameborder="0"
											allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
											allowfullscreen
										></iframe>
									</div>
									<div class="text-container">
										<p class="p-content">8. Obtén el ID del número de teléfono.</p>
									</div>
									<img src="../../../../assets/img/identificador-img-ilus.png" alt="" class="img-ilus-step" />
									<form class="form-container" [formGroup]="whatsappIdentificator">
										<div class="input-container">
											<div class="label-container">
												<label for="whatsappIdentificator"
													>Identificador del número de teléfono de WhatsApp
													<hk-tooltip-info
														text="Indica en el área de texto, el identificador del número de teléfono de WhatsApp generado por WhatsApp."
														position="left"
													></hk-tooltip-info
												></label>
											</div>
											<div class="input-container">
												<textarea
													placeholder="Pega aquí el Identificador del número de teléfono de WhatsApp"
													formControlName="identificationNumberWhatsApp"
													id="whatsappIdentificator"
													(focusout)="savePreferences(false)"
												></textarea>
												<span
													class="text-input-warning"
													*ngIf="
														whatsappIdentificator.controls['identificationNumberWhatsApp'].getError('maxlength') &&
														(whatsappIdentificator.controls['identificationNumberWhatsApp'].touched ||
															whatsappIdentificator.controls['identificationNumberWhatsApp'].dirty)
													"
												>
													El identificador del número de teléfono no puede ser superior a 17 dígitos.
												</span>
												<span
													class="text-input-warning"
													*ngIf="
														whatsappIdentificator.controls['identificationNumberWhatsApp'].getError('minlength') &&
														(whatsappIdentificator.controls['identificationNumberWhatsApp'].touched ||
															whatsappIdentificator.controls['identificationNumberWhatsApp'].dirty)
													"
												>
													El identificador del número de teléfono no puede ser menor a 10 dígitos.
												</span>
												<span
													class="text-input-warning"
													*ngIf="
														whatsappIdentificator.controls['identificationNumberWhatsApp'].getError('required') &&
														(whatsappIdentificator.controls['identificationNumberWhatsApp'].touched ||
															whatsappIdentificator.controls['identificationNumberWhatsApp'].dirty)
													"
												>
													El identificador del número de teléfono es requerido.
												</span>
												<span
													class="text-input-warning"
													*ngIf="
														whatsappIdentificator.controls['identificationNumberWhatsApp'].getError('´pattern') &&
														(whatsappIdentificator.controls['identificationNumberWhatsApp'].touched ||
															whatsappIdentificator.controls['identificationNumberWhatsApp'].dirty)
													"
												>
													El identificador del número de teléfono de WhatsApp sólo aceptan números.
												</span>
											</div>
										</div>
									</form>
									<ng-content *ngIf="whatsappIdentificator.invalid; then invalidElementWhatt; else validElementWhatt"></ng-content>
									<ng-template #validElementWhatt>
										<div class="button-container">
											<button class="button-blue invert" (click)="setContentTrigger(1); setTabActive()">Volver</button>
											<button class="button-blue" (click)="setContentTrigger(3); setTabActive()">Siguiente</button>
										</div>
									</ng-template>
									<ng-template #invalidElementWhatt>
										<div class="button-container">
											<button class="button-blue invert" (click)="setContentTrigger(1); setTabActive()">Volver</button>
											<button class="button-blue" disabled (click)="setContentTrigger(3)">Siguiente</button>
										</div>
									</ng-template>
								</div>
								<div class="animos-container" *ngIf="getScreenWidth > 500">
									<img
										src="../../../../assets/img/animo4to.png"
										alt="imagen con texto Excelente! Ya te falta poco"
										*ngIf="contentTrigger.two"
										class="animos-img"
									/>
								</div>
								<div *ngIf="contentTrigger.three" class="container-gif" id="section-6">
									<div class="text-container">
										<p class="p-content">Crear usuario de sistema de "VideoLink" como Administrador (para obtener el token de acceso)</p>
										<p class="p-content">
											1. Ingresamos en la business console del negocio al cual queremos crearle el usuario de sistema
											<a target="_blank" href="https://business.facebook.com/latest/home" rel="noopener noreferrer"
												>"https://business.facebook.com/latest/home"</a
											>, previamente seleccionando el negocio en la parte superior izquierda.
										</p>
										<p class="p-content">
											2. Damos click en panel del lado izquierdo en el botón de configuración, esto nos llevará al panel de configuración
											del negocio, damos click en “Más Opciones de configuración”.
										</p>
										<p class="p-content">3. Hacemos click en el hipervínculo de “Usuarios de sistema” en la sección de usuario.</p>
										<p class="p-content">4. Damos click en el botón "Agregar".</p>
										<p class="p-content">
											5. Creamos un usuario con el nombre “Videolink” con el rol de administrador, hacemos click en “Crear usuario de
											sistema”.
										</p>
									</div>
									<img src="../../../../assets/img/usuario-sistema-facebook.png" alt="crear usuario del sistema" class="img-step-fb2" />
									<div class="text-container">
										<p class="p-content">
											6. Generar Token Definitivo: Una vez creado el usuario lo seleccionamos y damos click en Generar nuevo token, esto
											desplegará un modal donde seleccionaremos la aplicación que creamos anteriormente.
										</p>
										<p class="p-content">
											7. Una vez seleccionada la aplicación se desplegará un listado de los permisos que podemos seleccionar, Debemos
											seleccionar los siguientes permisos:
										</p>
										<ul>
											<li>whatsapp_business_messaging</li>
											<li>whatsapp_business_management</li>
											<li>business_management</li>
										</ul>
										<p class="p-content">8. Damos click en Generar token, Copiamos el token que nos da el modal y lo pegas aquí.</p>
									</div>
									<form class="form-container" [formGroup]="whatsappMetaToken">
										<div class="input-container">
											<div class="label-container">
												<label for="metaToken"
													>Whatsapp META token
													<hk-tooltip-info
														text="Indica en el área de texto, el META token generado desde WhatsApp."
														position="left"
													></hk-tooltip-info
												></label>
											</div>
											<div class="input-container">
												<textarea
													placeholder="Pega aquí el WhatsApp META token"
													formControlName="whatsappMetaToken"
													id="metaToken"
													(focusout)="savePreferences(true)"
												></textarea>
												<span
													class="text-input-warning"
													*ngIf="
														whatsappMetaToken.controls['whatsappMetaToken'].getError('required') &&
														(whatsappMetaToken.controls['whatsappMetaToken'].touched ||
															whatsappMetaToken.controls['whatsappMetaToken'].dirty)
													"
												>
													El WhatsApp META Token es requerido.
												</span>
											</div>
										</div>
									</form>
									<div class="video-responsive">
										<iframe
											src="https://www.youtube.com/embed/ZwICbYn3zus"
											title="YouTube video player"
											frameborder="0"
											allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
											allowfullscreen
										></iframe>
									</div>
									<div class="text-img-container">
										<p class="p-content">9. Seguido a esto damos click en el botón que dice</p>
										<img src="../../../../assets/img/whatsapp-ilus-activos.png" alt="" class="img-side-p" />
									</div>
									<div class="text-container">
										<p class="p-content">
											10. Esto desplegará un modal en donde debemos ir a la sección de apps (pánel del lado izquierdo)
										</p>
										<p class="p-content">
											11. Seleccionaremos la app creada previamente, activamos el control total de la app y damos click en "Guardar cambios"
										</p>
									</div>
									<div class="video-responsive">
										<iframe
											src="https://www.youtube.com/embed/Y20pqLFqB0I"
											title="YouTube video player"
											frameborder="0"
											allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
											allowfullscreen
										></iframe>
									</div>
								</div>
								<div class="animos-container" *ngIf="getScreenWidth > 500">
									<img src="../../../../assets/img/animos5to.png" alt="" *ngIf="contentTrigger.three" class="animos-img" />
								</div>
								<div *ngIf="whatsappMetaToken.invalid; then invalidElement; else validElement"></div>
								<ng-template #validElement>
									<div class="Group__stepper__section__btn_actions" *ngIf="contentTrigger.three">
										<button class="btn_form_before" matStepperPrevious (click)="saveStep(2)">Volver</button>
										<button class="btn_form invert" (click)="setTabActive(); saveStep(3)" matStepperNext>Siguiente</button>
									</div>
								</ng-template>
								<ng-template #invalidElement>
									<div class="Group__stepper__section__btn_actions" *ngIf="contentTrigger.three">
										<button class="btn_form_before" matStepperPrevious>Volver</button>
										<button class="btn_form invert" [disabled]="whatsappMetaToken.invalid">Siguiente</button>
									</div>
								</ng-template>
							</div>
						</mat-step>
						<mat-step completed="false" [stepControl]="formStep" id="section-7">
							<div class="Group__stepper__title">Paso 4. Verificar cuenta comercial</div>
							<div class="Group__stepper__section">
								<div class="animos-container" *ngIf="getScreenWidth > 500">
									<img src="../../../../assets/img/paso4lograrias.png" alt="sabia que lo lograrías " class="animos-img-custom" />
								</div>
								<div class="container-gif">
									<div class="text-container">
										<p class="p-content">
											1. Para conectar la mensajería de META a Videolink es necesario la verificación de la cuenta comercial en META, este
											proceso depende de META y sus procesos de verificación, aproximadamente tardan de 1 a 3 días en dar respuesta. Acá te
											explicamos como hacerlo
										</p>
										<p class="p-content">
											2. Ir a más opciones de configuración de negocio -> Centro de Seguridad -> Iniciar Verificación
											<a href="https://business.facebook.com" target="_blank" rel="noopener noreferrer">https://business.facebook.com/</a>
										</p>
										<p class="p-content">3. Seleccionar país.</p>
										<p class="p-content">4. Agregar datos de la organización.</p>
										<p class="p-content">5. Seleccionar método de contacto.</p>
										<p class="p-content">6. Agregar documentos de acreditación.</p>
										<p class="p-content">7. Confirmar número de teléfono</p>
									</div>
									<div class="video-responsive">
										<iframe
											src="https://www.youtube.com/embed/C0rD_Vm2dKk"
											title="YouTube video player"
											frameborder="0"
											allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
											allowfullscreen
										></iframe>
									</div>
								</div>
								<div class="Group__stepper__section__btn_actions">
									<button class="btn_form_before" matStepperPrevious (click)="setTabActive(); saveStep(3)">Volver</button>
									<button class="btn_form invert" (click)="finishStep(); saveStep(4)">Finalizar</button>
								</div>
							</div>
						</mat-step>
					</mat-stepper>
				</mat-accordion>
			</div>
		</div>
		<div class="happy-container" *ngIf="finishItemStep">
			<div class="text-container">
				<h2 class="title">¡Felicitaciones!</h2>
				<p>Ahora ya puedes ir a activar tu WhatsApp</p>
				<a [routerLink]="['/dashboard/channels/whatsapp/activation']">Activar</a>
			</div>
			<img src="../../../../assets/img/felicitacioneswhatsapp.png" alt="felicitaciones!" class="img-happy" />
		</div>
	</div>
</main>
