<div
  class="vl-attention-card"
 [ngStyle]="{ 'flex-direction': grid  ? 'column' : 'row' }" 
  [ngClass]="{ 'vl-attention-card__disabled': disabled }"
  >
  <div class="vl-even">
    <span
      class="vl-attention-card__disabled--info"
      *ngIf="disabled"
      data-tooltip="Aún no hay agentes conectados para este tipo de atención."
      >i</span
    >
    <div class="vl-attention-card-avatar">
      <img [src]="ability.avatar || noAvatarUrl" *ngIf="!ability.icon" />

      <span
        class="vl-attention-card-avatar__icon"
        [ngClass]="{ 'vl-attention-card-avatar__icon--disabled': disabled }"
         [ngStyle]="{ color: iconColor }" 
      >
        {{ ability.icon }}
      </span>
    </div>
    <div
      class="vl-attention-card-header"
      [ngClass]="{ 'vl-attention-card-header-minimal': minimal }"
      [ngStyle]="{ 'min-width': desktopView && !even ? '40px' : false }"
    >
      <p class="vl-attention-card-header-title">{{ ability.name }}</p>
      <p class="vl-attention-card-header-description">
        {{ ability.description }}
      </p>
    </div>
  </div>
  <div
    class="vl-attention-card-actions"
    [ngStyle]="{ 'margin-top': minimal ? '0px' : '0', 'display': grid ? 'block' : 'grid', 'justify-content': grid ? 'center' : 'end' }"
  >
     <hk-btn-abilities
      [disabled]="disabled"
      [background]="btnBGColor"
      >{{ btnAbilityText }}</hk-btn-abilities
    > 
  </div>
</div>
