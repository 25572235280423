<div class="subAbility">
	<h3 class="subAbility__title">Formulario</h3>

	<ng-container *ngFor="let form of subAbilitiesForms; let i = index">
		<form [formGroup]="this.subAbilitiesForms[i]!.form">
			<div class="subAbility__form__header">
				<mat-checkbox
					formControlName="required"
					(change)="changeRequired($event, i)"
					[value]="this.subAbilitiesForms[i]!.form?.value?.required"
					>Requerido</mat-checkbox
				>
				<mat-icon (click)="removeForm(i); change()" *ngIf="subAbilitiesForms.length - 1">delete</mat-icon>
			</div>

			<div class="subAbility__form__group">
				<label for="time" class="subAbility__form__group__label">Opci&oacute;n {{ i + 1 }}</label>
				<vl-select-custom
					[config]="{ type: 'text', options: formTypes }"
					(changeSelection)="changeOption($event, i); change()"
					[value]="value ? subAbilitiesForms[i].value : undefined"
				></vl-select-custom>
			</div>
			<!-- Field title -->
			<div class="subAbility__form__group">
				<label for="title" class="subAbility__form__group__label">Título del campo</label>
				<input
					type="text"
					id="title"
					formControlName="title"
					placeholder="Titulo de el campo"
					maxlength="120"
					minlength="3"
					class="subAbility__form__group__input"
					(change)="change()"
					(keyup)="change()"
				/>
			</div>

			<!-- Download file -->

			<label for="download" *ngIf="subAbilitiesForms[i].value == 'download'" class="subAbility__form__group__input__download">
				<input
					type="file"
					id="download"
					style="display: none"
					(change)="!subAbilitiesForms[i].progress ? uploadFile($event, i) : undefined"
				/>
				<div class="dotted-border">
					<img src="/assets/svg/upload_file.svg" alt="file upload icon" />
					<ng-container *ngIf="!subAbilitiesForms[i].progress">
						<span class="subAbility__form__group__label">Arrastra y suelta los archivos aquí</span>
						<span class="subAbility__form__group__upload_button"> Seleccionar un archivo </span>
					</ng-container>
					<ng-container *ngIf="subAbilitiesForms[i].progress">
						<span class="subAbility__form__group__label">Subiendo archivo...</span>
						<mat-progress-bar mode="determinate" [value]="subAbilitiesForms[i].progress"></mat-progress-bar>
					</ng-container>
				</div>

				<mat-error *ngIf="subAbilitiesForms[i]!.form.get('download')?.hasError('size')"> El archivo no puede ser mayor a 10MB </mat-error>
				<mat-error *ngIf="subAbilitiesForms[i]!.form.get('download')?.hasError('format')">
					Formato inválido, solo se permiten archivos de tipo: .pdf, .xls, .xlsx, imagen y .txt
				</mat-error>
				<mat-error *ngIf="subAbilitiesForms[i]!.form.get('download')?.hasError('server')">
					Error al subir el archivo, intente de nuevo
				</mat-error>
			</label>

			<!-- preview file  -->
			<div class="file-preview" *ngIf="subAbilitiesForms[i].value == 'download' && subAbilitiesForms[i]!.download?.length">
				{{ subAbilitiesForms[i].filename }}
			</div>

			<!-- regex cconfig -->
			<div *ngIf="subAbilitiesForms[i].value == 'regex'">
				<div class="subAbility__form__group">
					<input
						type="text"
						id="regex"
						formControlName="regex"
						placeholder="Expresion regular: regex"
						maxlength="1024"
						minlength="3"
						class="subAbility__form__group__input"
						(change)="change()"
						(keyup)="change()"
					/>
				</div>
			</div>

			<!-- Select config -->
			<ng-container *ngIf="subAbilitiesForms[i].value == 'select'">
				<br />
				<!-- Field title -->
				<div class="subAbility__form__group__label">Opciones del campo</div>
				<div class="subAbility__form__group" *ngFor="let option of subAbilitiesForms[i].options; let j = index">
					<div class="input-row-wrapper">
						<input
							type="text"
							[id]="'option-' + option.value"
							[formControlName]="'option-' + option.value"
							[placeholder]="'Opcion ' + (j + 1)"
							maxlength="120"
							minlength="3"
							class="subAbility__form__group__input"
							(change)="change()"
							(keyup)="change()"
							(paste)="change()"
						/>
						<mat-icon (click)="removeOptionFromSelect(i, j, option.value)" *ngIf="subAbilitiesForms[i].options.length - 1">close</mat-icon>
					</div>

					<mat-error *ngIf="subAbilitiesForms[i]!.form.get('option-' + j)?.hasError('maxlength')">
						El campo no puede tener mas de 120 caracteres
					</mat-error>
					<mat-error *ngIf="subAbilitiesForms[i]!.form.get('option-' + j)?.hasError('minlength')">
						El campo no puede tener menos de 3 caracteres
					</mat-error>
				</div>

				<div (click)="addOptionToSelect(i)" style="color: #757575; cursor: pointer; padding-left: 15px" class="custom-option-list__buttom">
					<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
						<path
							d="M9 15H11V11H15V9H11V5H9V9H5V11H9V15ZM10 20C8.61667 20 7.31667 19.7373 6.1 19.212C4.88333 18.6867 3.825 17.9743 2.925 17.075C2.025 16.175 1.31267 15.1167 0.788 13.9C0.263333 12.6833 0.000666667 11.3833 0 10C0 8.61667 0.262667 7.31667 0.788 6.1C1.31333 4.88333 2.02567 3.825 2.925 2.925C3.825 2.025 4.88333 1.31267 6.1 0.788C7.31667 0.263333 8.61667 0.000666667 10 0C11.3833 0 12.6833 0.262667 13.9 0.788C15.1167 1.31333 16.175 2.02567 17.075 2.925C17.975 3.825 18.6877 4.88333 19.213 6.1C19.7383 7.31667 20.0007 8.61667 20 10C20 11.3833 19.7373 12.6833 19.212 13.9C18.6867 15.1167 17.9743 16.175 17.075 17.075C16.175 17.975 15.1167 18.6877 13.9 19.213C12.6833 19.7383 11.3833 20.0007 10 20ZM10 18C12.2333 18 14.125 17.225 15.675 15.675C17.225 14.125 18 12.2333 18 10C18 7.76667 17.225 5.875 15.675 4.325C14.125 2.775 12.2333 2 10 2C7.76667 2 5.875 2.775 4.325 4.325C2.775 5.875 2 7.76667 2 10C2 12.2333 2.775 14.125 4.325 15.675C5.875 17.225 7.76667 18 10 18Z"
							fill="#757575"
						/>
					</svg>

					<span style="padding-left: 5px">Agregar Opción</span>
				</div>
			</ng-container>
		</form>

		<br />
		<br />
	</ng-container>
	<div class="subAbility__button">
		<button class="subAbility__button__add" (click)="addForm()">Otra Opción</button>
	</div>
</div>
