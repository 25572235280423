import { Component, Input, Output, EventEmitter } from '@angular/core';

@Component({
	selector: 'li-input-file',
	templateUrl: './li-input-file.component.html',
	styleUrls: ['./li-input-file.component.scss'],
})
export class LiInputFileComponent {
	files: Array<File> = [];
	@Input() multiple: boolean = false;
	@Output() changesValue: EventEmitter<any> = new EventEmitter<Array<File>>();
	constructor() {}

	changeFiles(e: any) {
		this.files = Array.from(e.target.files);
		this.changesValue.emit(this.files);
	}

	removeFile(event: any, index: number) {
		event.stopPropagation();
		event.preventDefault();
		this.files.splice(index, 1);
		this.changesValue.emit(this.files);
	}
}
