import { TitleCasePipe } from '@angular/common';
import { HttpParams } from '@angular/common/http';
import { Component, OnInit, inject } from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';
import { ApiService } from '@services/backend/api.service';
import { LoadingService } from '@services/components/loading.service';
import { ChartType } from 'angular-google-charts';
import { lastValueFrom } from 'rxjs';

@Component({
	selector: 'app-resolutivity',
	templateUrl: './resolutivity.component.html',
	styleUrls: ['./resolutivity.component.scss'],
	providers: [TitleCasePipe],
})
export class ResolutivityComponent {
	private _apiSrc: ApiService = inject(ApiService);
	private _loadingSrc: LoadingService = inject(LoadingService);
	private _titlePipe: TitleCasePipe = inject(TitleCasePipe);
	private _channelsConfig: any = {
		whatsapp: {
			imgPath: 'assets/img/channels/what.png',
			label: 'Whatsapp',
			average: 0,
			percentage: 0,
			color: '#0AC443',
		},
		facebook: {
			imgPath: 'assets/img/channels/fc.png',
			label: 'Facebook',
			average: 0,
			percentage: 0,
			color: '#5A99EC',
		},
		instagram: {
			imgPath: 'assets/img/channels/ins.png',
			label: 'Instagram',
			average: 0,
			percentage: 0,
			color: '#EA345F',
		},
		web: {
			imgPath: 'assets/img/channels/hook.png',
			label: 'Hook',
			average: 0,
			percentage: 0,
			color: '#F7941D',
		},
		'web-widget': {
			imgPath: 'assets/img/channels/widget.png',
			label: 'Widget',
			average: 0,
			percentage: 0,
			color: '#9F49A3',
		},
		telegram: {
			imgPath: 'assets/img/channels/widget.png',
			label: 'Telegram',
			average: 0,
			percentage: 0,
			color: '#9F49A3',
		},
	};

	public today = new Date();

	public totalAttentions: number = 0;
	public resolutivityGeneral: any = { total: 0, average: 0 };
	public graficConfig: any = {
		type: ChartType.BarChart,
		data: [],
		options: {
			colors: ['#6848F3'],
			legend: 'none',
			isStacked: true,
		},
	};

	public line_time_options = [
		{
			label: 'Hoy',
		},
		{
			label: 'Semana',
		},
		{
			label: 'Mes',
		},
		{
			label: 'Año',
		},
		{
			label: 'Personalizar',
		},
	];
	public line_time_active: any = undefined;

	public channels: any[] = [];
	public _agents: any[] = [];
	public agents: any[] = [];
	public filters = new FormGroup({
		from: new FormControl(''),
		to: new FormControl(''),
	});

	constructor() {
		this.getData = this.getData.bind(this);

		this.filters.valueChanges.subscribe(() => {
			this.getData();
		});

		this.setLineTimeActive(3);
	}

	async getData() {
		try {
			this._loadingSrc.initiate();
			const params = new HttpParams({ fromObject: this.filters.value as any });
			const response = await lastValueFrom(this._apiSrc.get('/v2/resolutivity/', params));
			this.setCardChannel(response.channelList);
			this.setCardAgent(response.userList);
			this.setTotalAttentions(response.chatsCount);
			this.setGeneralResolutivity(response.resolutivityGlobal);
			this.setGrafic(response.chatsForDay);
		} catch (error) {
			console.log(error);
		} finally {
			this._loadingSrc.hide();
		}
	}

	setCardChannel(channels: any) {
		this.channels = [];
		Object.keys(channels).forEach((key: string) => {
			this.channels.push({
				...this._channelsConfig[key],
				average: channels[key].total,
				percentage: channels[key].percentage,
			});
		});
	}

	setCardAgent(agents: any) {
		this._agents = agents.sort((a: any, b: any) => b.percentage - a.percentage);
		this.agents = agents.sort((a: any, b: any) => b.percentage - a.percentage);
	}

	setTotalAttentions(total: any) {
		this.totalAttentions = total;
	}

	setGeneralResolutivity(resolutivityGeneral: any) {
		this.resolutivityGeneral = resolutivityGeneral;
	}

	setGrafic(data: any) {
		let group;
		let keyBuilder = 'day';

		const buildStructure: any = {
			day: this.groupDataByDay.bind(this),
			week: this.groupDataByWeek.bind(this),
			month: this.groupDataByMonth.bind(this),
			year: this.groupDataByYear.bind(this),
		};

		if ([0, 1].includes(this.line_time_active)) keyBuilder = 'week';
		if (this.line_time_active === 3) keyBuilder = 'month';

		if (this.line_time_active === 4) {
			const from: any = new Date(Intl.DateTimeFormat('en').format(this.filters.controls['from'].value as any));
			const to: any = new Date(Intl.DateTimeFormat('en').format(this.filters.controls['to'].value as any));
			const diffDay = Math.floor((to - from) / (24 * 60 * 60 * 1000));

			if (diffDay < 7) keyBuilder = 'week';
			else if (diffDay < 31) keyBuilder = 'day';
			else if (diffDay <= 365) keyBuilder = 'month';
			else keyBuilder = 'year';
		}

		group = buildStructure[keyBuilder](data);

		this.graficConfig.data = Object.values(group);
	}

	groupDataByDay(data: any) {
		return Object.entries(data).reduce((prev: any, [key, value]: any) => {
			const [day, month, year]: any = key.split('/');

			const date = new Date();

			date.setFullYear(year, month - 1, day);

			if (!prev[Intl.DateTimeFormat('es').format(date)]) {
				prev[Intl.DateTimeFormat('es').format(date)] = [Intl.DateTimeFormat('es').format(date), Number(value.resolved)];
			} else {
				prev[Intl.DateTimeFormat('es').format(date)][1] += Number(value.resolved);
			}

			return prev;
		}, {});
	}
	groupDataByWeek(data: any) {
		return Object.entries(data).reduce((prev: any, [key, value]: any) => {
			const [day, month, year]: any = key.split('/');

			const date = new Date();

			date.setFullYear(year, month - 1, day);

			if (!prev[Intl.DateTimeFormat('es', { weekday: 'short' }).format(date)]) {
				prev[Intl.DateTimeFormat('es', { weekday: 'short' }).format(date)] = [
					Intl.DateTimeFormat('es', { weekday: 'short' }).format(date),
					Number(value.resolved),
				];
			} else {
				prev[Intl.DateTimeFormat('es', { weekday: 'short' }).format(date)][1] += Number(value.resolved);
			}

			return prev;
		}, {});
	}
	groupDataByMonth(data: any) {
		return Object.entries(data).reduce((prev: any, [key, value]: any) => {
			const month: any = key.split('/')[1];
			const date = new Date();
			date.setDate(1);
			date.setMonth(month - 1);

			if (!prev[Intl.DateTimeFormat('es', { month: 'short' }).format(date)]) {
				prev[Intl.DateTimeFormat('es', { month: 'short' }).format(date)] = [
					this._titlePipe.transform(Intl.DateTimeFormat('es', { month: 'short' }).format(date)),
					Number(value.resolved),
				];
			} else {
				prev[Intl.DateTimeFormat('es', { month: 'short' }).format(date)][1] += Number(value.resolved);
			}

			return prev;
		}, {});
	}
	groupDataByYear(data: any) {
		return Object.entries(data).reduce((prev: any, [key, value]: any) => {
			const year: any = key.split('/')[2];
			const date = new Date();
			date.setFullYear(year, 0, 1);

			if (!prev[Intl.DateTimeFormat('es', { year: 'numeric' }).format(date)]) {
				prev[Intl.DateTimeFormat('es', { year: 'numeric' }).format(date)] = [
					Intl.DateTimeFormat('es', { year: 'numeric' }).format(date),
					Number(value.resolved),
				];
			} else {
				prev[Intl.DateTimeFormat('es', { year: 'numeric' }).format(date)][1] += Number(value.resolved);
			}

			return prev;
		}, {});
	}

	setLineTimeActive(index: number) {
		if (index === this.line_time_active) return;

		this.line_time_active = index;

		if (index === 4) return;

		let from: any = new Date();
		let to: any = new Date();

		if (index === 1) to.setDate(to.getDate() + 6);

		if (index === 2) {
			from.setFullYear(from.getFullYear(), from.getMonth(), 1);
			to.setFullYear(to.getFullYear(), to.getMonth() + 1, 0);
		}
		if (index === 3) {
			from.setFullYear(from.getFullYear(), 0, 1);
			to.setFullYear(to.getFullYear() + 1, 0, 0);
		}

		this.filters.setValue({
			from,
			to,
		});
	}

	searchAgent(str: string) {
		if (!str) {
			this.agents = this._agents;
			return;
		}

		this.agents = this._agents.filter((agent: any) => {
			return (
				agent.firstname.toLowerCase().includes(str.toLowerCase()) ||
				agent.lastname.toLowerCase().includes(str.toLowerCase()) ||
				agent.email.toLowerCase().includes(str.toLowerCase())
			);
		});
	}
}
