import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AccountsComponent } from './accounts/accounts.component';

import { ContentComponent } from './content.component';
import { FormComponent } from './form/form.component';
import { HookFormsComponent } from './contact-center/hook-forms/hook-forms.component';
import { InstagramComponent } from './instagram/instagram.component';
import { WidgetComponent } from './widget/widget.component';
import { WhatsappActivationComponent } from './whatsapp-activation/whatsapp-activation.component';
import { WhatsappPersonalizeComponent } from './whatsapp-personalize/whatsapp-personalize.component';
import { FacebookActivationComponent } from './facebook-activation/facebook-activation.component';
import { FacebookPersonalizeComponent } from './facebook-personalize/facebook-personalize.component';
import { AttentionsComponent } from './attentions/attentions.component';
import { SatisfactionComponent } from './satisfaction/satisfaction.component';
import { ResolutivityComponent } from './resolutivity/resolutivity.component';
import { TestComponent } from './test/test.component';
import { FirstWhatsappStepsComponent } from './first-whatsapp-steps/first-whatsapp-steps.component';
import { TicketsIntegrationComponent } from './tickets-integration/tickets-integration.component';
import { HashTokenGuard } from 'src/app/guards/hash-token.guard';
import { CommingSoonComponent } from './comming-soon/comming-soon.component';

const routes: Routes = [
	{
		path: 'analytics',
		title: 'Videolink Admin | Analíticas',
		component: ContentComponent,
		canLoad: [HashTokenGuard],
		children: [
			{
				path: '',
				pathMatch: 'full',
				redirectTo: 'attentions',
			},
			{
				path: 'attentions',
				component: AttentionsComponent,
			},
			{
				path: 'satisfaction',
				// component: SatisfactionComponent,
				component: CommingSoonComponent,
			},
			{
				path: 'resolutivity',
				// component: ResolutivityComponent,
				component: CommingSoonComponent,
			},
		],
	},
	{
		path: 'attentions',
		component: ContentComponent,
		canLoad: [HashTokenGuard],
		loadChildren: () => import('./conversations/conversations.module').then((m) => m.ConversationsModule),
	},
	{
		path: 'channels',
		component: ContentComponent,
		canLoad: [HashTokenGuard],
		loadChildren: () => import('./channels/channels.module').then((m) => m.ChannelsModule),
	},
	{
		path: 'configurations',
		component: ContentComponent,
		canLoad: [HashTokenGuard],
		loadChildren: () => import('./real-settings/real-settings.module').then((m) => m.RealSettingsModule),
	},
	{
		path: 'profile',
		component: ContentComponent,
		canLoad: [HashTokenGuard],
		loadChildren: () => import('./settings/settings.module').then((m) => m.SettingsModule),
	},
	{
		path: 'test',
		component: TestComponent,
	},
	{
		path: '**',
		redirectTo: 'analytics',
	},
];

@NgModule({
	imports: [RouterModule.forChild(routes)],
	exports: [RouterModule],
})
export class ContentRoutingModule {}
