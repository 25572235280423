import { DimensionsScreenService } from '@services/components/dimensions-screen.service';
import { AfterViewInit, Component, HostListener, NgZone } from '@angular/core';
import { environment } from '@environments/environment';
import { AuthService } from '@services/storage/auth.service';
import { Subject, of } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
// import { CardSeriesComponent } from '@swimlane/ngx-charts';

@Component({
	selector: 'app-root',
	templateUrl: './app.component.html',
	styleUrls: ['./app.component.scss'],
})
export class AppComponent implements AfterViewInit {
	@HostListener('window:resize', ['$event'])
	onWindowResize() {
		this.dimensionsScreenSrc.change.next({ ...this.dimensionsScreenSrc.data, width: window.innerWidth, height: window.innerHeight });
	}

	@HostListener('window:load', ['$event'])
	onWindowLoad() {
		this.dimensionsScreenSrc.change.next({ ...this.dimensionsScreenSrc.data, width: window.innerWidth, height: window.innerHeight });
	}

	constructor(private dimensionsScreenSrc: DimensionsScreenService, private _authService: AuthService, private ngZone: NgZone) {}

	ngAfterViewInit(): void {}
	loadScript() {
		// let body = document.body;
		// let css = document.createElement('link');
		// css.rel = 'stylesheet';
		// css.href = environment.hookCss;
		// let script = document.createElement('script');
		// script.type = 'text/javascript';
		// script.src = environment.hookJs;
		// script.async = true;
		// script.defer = true;
		// body.appendChild(script);
		// body.appendChild(css);
	}

	public removeScript(id: string) {
		// let script = document.getElementById(id);
		// if (script) {
		// 	script.remove();
		// }
	}

	ngOnInit(): void {}

	ngOnDestroy(): void {}
}
