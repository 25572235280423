import { Component, Input, OnInit } from '@angular/core';
import { ToastService } from '@services/backend/toast.service';

@Component({
  selector: 'app-copy-button',
  templateUrl: './copy-button.component.html',
  styleUrls: ['./copy-button.component.scss']
})
export class CopyButtonComponent {
  @Input() contentCopy: string = ''
  constructor(private toasterService: ToastService) {
    
	}

	textToClipboard(text: string) {
		if (navigator.clipboard && window.isSecureContext) {
			navigator.clipboard.writeText(text);
			this.notifyCopy()
		} else {
			let temp = document.createElement('textarea');
			temp.value = text;
			temp.style.position = 'fixed';
			temp.style.left = '-999999px';
			temp.style.top = '-999999px';
			document.body.appendChild(temp);
			temp.focus();
			temp.select();
			document.execCommand('copy');
			temp.remove();
			this.notifyCopy()
		}
	}

	notifyCopy() {
		this.toasterService.initiate({
			title: 'Éxito',
			content: 'Token copiado en el porta papeles',
			type: 'success',
		});
	}
}
