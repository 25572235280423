import { tap } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { Component, OnInit } from '@angular/core';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { ToastService } from '../../services/backend/toast.service';
@Component({
	selector: 'app-custom-toaster',
	templateUrl: './custom-toaster.component.html',
	styleUrls: ['./custom-toaster.component.scss'],
	animations: [
		trigger('openClose', [
			state(
				'closed',
				style({
					visibility: 'hidden',
					right: '-100%',
				})
			),
			state(
				'open',
				style({
					right: '10px',
				})
			),
			transition('open <=> closed', [animate('0.5s ease-in-out')]),
		]),
	],
})
export class CustomToasterComponent implements OnInit {
	progressInterval: any;
	data_info: {
		type: string;
		title?: string;
		content?: string;
		time?: number;
	} = {
		type: '',
		title: '',
		content: '',
		time: 1000,
	};

	private stackNotification: Array<{
		type: string;
		title?: string;
		content?: string;
		time?: number;
	}> = [];

	public show: boolean = false;

	constructor(public toastService: ToastService) {
		this.toastService.executeWith
			.pipe(
				tap((stack: any) => {
					this.showNotifications(stack);
				})
			)
			.subscribe();
	}

	showNotifications(stack: any) {
		if (stack.length === 0) return;

		const notification = stack[0];

		if (notification) {
			this.data_info = notification;
			this.show = true;
			setTimeout(() => {
				this.close();
			}, this.data_info.time || 3000);
		}
	}

	close() {
		this.show = false;
		setTimeout(() => {
			this.toastService.executionCompleted(this.data_info);
		}, 500);
	}

	ngOnInit() {}
}
