import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-modal-previewer-form',
  templateUrl: './modal-previewer-form.component.html',
  styleUrls: ['./modal-previewer-form.component.scss']
})
export class ModalPreviewerFormComponent implements OnInit {
  dataPreviewer: any = {
    file: '',
    colorBackground: '',
    nameStyle: ''  
  }
  constructor(  private dialogRef: MatDialogRef<ModalPreviewerFormComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) {
      let datosTemp = this.dataPreviewer
      this.dataPreviewer = {
        ...datosTemp,
        ...data
      }
      console.log(this.dataPreviewer)
     }

  ngOnInit(): void {
  }
  closeDialog() {
    this.dialogRef.close()
  }

}
