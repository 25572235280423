import { Component, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ApiService } from '@services/backend/api.service';
import { MembershipService } from '@services/backend/membership.service';
import { PreferencesService } from '@services/backend/preferences.service';
import { ToastService } from '@services/backend/toast.service';
import { LoadingService } from '@services/components/loading.service';
import { lastValueFrom, Subject, takeUntil, tap } from 'rxjs';

@Component({
	selector: 'app-ig-steps',
	templateUrl: './instagram-steps.component.html',
	styleUrls: ['./instagram-steps.component.scss'],
})
export class InstagramStepsComponent implements OnInit {
	instagramMembership: boolean = false;
	public _unSuscribeAll: Subject<any> = new Subject();
	constructor(private router: Router, private _membershipService: MembershipService) {
		this._membershipService.membershipOfCompany
			.pipe(
				takeUntil(this._unSuscribeAll),
				tap((membershipData) => {
					console.log({ membershipData: membershipData });
					if (Object.keys(membershipData).length === 0) return membershipData;
					this.instagramMembership = membershipData.membership.current.IG || false;
				})
			)
			.subscribe();
	}

	goToSupport() {
		const queryParams = { to: 'support' };
		this.router.navigate(['/dashboard/profile/account'], { queryParams });
	}

	ngOnInit(): void {}
}
