import { Component, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ApiService } from '@services/backend/api.service';
import { MembershipService } from '@services/backend/membership.service';
import { PreferencesService } from '@services/backend/preferences.service';
import { ToastService } from '@services/backend/toast.service';
import { LoadingService } from '@services/components/loading.service';
import { lastValueFrom, Subject, takeUntil, tap } from 'rxjs';

@Component({
	selector: 'app-facebook-personalize',
	templateUrl: './instagram-personalize.component.html',
	styleUrls: ['./instagram-personalize.component.scss'],
})
export class InstagramPersonalizeComponent implements OnInit {
	contentTitle: string = 'Chatbot del canal de Instagram';
	contentDescription: string = 'Personaliza las opciones de tu Chatbot y mensajes para tu cuenta de Instagram';
	contentFacebookDescription: string = 'Debes activar el canal de instagram para poder personalizarlo';
	companyId: any;
	isLoaded: boolean = false;
	maxLength: number = 512;
	minLength: number = 10;

	bigInputs: any[] = [
		{
			title: 'Mensaje predeterminado en las conversaciones',
			placeholder: '',
			name: 'messagePredeterminate',
			id: 'messagePredeter',
			textValidationRequired: 'El mensaje predeterminado es requerido.',
			textValidationMin: `El mensaje de predeterminado debe tener al menos ${this.minLength} caracteres.`,
			textValidationMax: `El mensaje de predeterminado debe tener máximo ${this.maxLength} caracteres.`,
			maxLength: this.maxLength,
			minLength: this.minLength,
		},
		{
			title: 'Mensaje de finalización en conversaciones',
			placeholder: '',
			name: 'messageFinish',
			id: 'messageFinis',
			textValidationRequired: 'El mensaje de finalización en conversaciones es requerido.',
			textValidationMin: `El mensaje de finalización debe tener al menos ${this.minLength} caracteres.`,
			textValidationMax: `El mensaje de finalización debe tener máximo ${this.maxLength} caracteres.`,
			maxLength: this.maxLength,
			minLength: this.minLength,
		},
		{
			title: 'Mensaje de "Agente no disponible" en conversaciones',
			placeholder: '',
			name: 'messageAgentNo',
			id: 'messageAgentN',
			textValidationRequired: 'El mensaje de "Agente no disponible" en conversaciones es requerido.',
			textValidationMin: `El mensaje de "Agente no disponible" en conversaciones debe tener al menos ${this.minLength} caracteres.`,
			textValidationMax: `El mensaje de "Agente no disponible" en conversaciones debe tener máximo ${this.maxLength} caracteres.`,
			maxLength: this.maxLength,
			minLength: this.minLength,
		},
		{
			title: 'Mensaje para selección de tipos de atenciones',
			placeholder: '',
			name: 'messageAbilities',
			id: 'messageAbilitie',
			textValidationRequired: 'El mensaje para selección de atenciones es requerido.',
			textValidationMin: `El mensaje para selección de atenciones debe de tener al menos ${this.minLength} caracteres.`,
			textValidationMax: `El mensaje para selección de atenciones debe de tener máximo ${this.maxLength} caracteres.`,
			maxLength: this.maxLength,
			minLength: this.minLength,
		},
		{
			title: 'Mensaje de "Cliente en cola" en conversaciones',
			placeholder: '',
			name: 'messagePile',
			id: 'messagePil',
			textValidationRequired: 'El mensaje de "Cliente en cola" en conversaciones es requerido.',
			textValidationMin: `El mensaje de "Cliente en cola" en conversaciones debe de tener al menos ${this.minLength} caracteres.`,
			textValidationMax: `El mensaje de "Cliente en cola" en conversaciones debe de tener máximo ${this.maxLength} caracteres.`,
			maxLength: this.maxLength,
			minLength: this.minLength,
		},
	];
	formFacebookPersonalize: UntypedFormGroup;
	facebookActivate: boolean = false;
	public _unSuscribeAll: Subject<any> = new Subject();
	constructor(
		private preferenceService: PreferencesService,
		private toastService: ToastService,
		private loadingService: LoadingService,
		private api: ApiService,
		private router: Router,
		private _membershipService: MembershipService
	) {
		this.formFacebookPersonalize = new UntypedFormGroup({
			messagePredeterminate: new UntypedFormControl('', [
				Validators.required,
				Validators.maxLength(this.maxLength),
				Validators.minLength(this.minLength),
			]),
			messageFinish: new UntypedFormControl('', [
				Validators.required,
				Validators.maxLength(this.maxLength),
				Validators.minLength(this.minLength),
			]),
			messageAgentNo: new UntypedFormControl('', [
				Validators.required,
				Validators.maxLength(this.maxLength),
				Validators.minLength(this.minLength),
			]),
			messageAbilities: new UntypedFormControl('', [
				Validators.required,
				Validators.maxLength(this.maxLength),
				Validators.minLength(this.minLength),
			]),
			messagePile: new UntypedFormControl('', [
				Validators.required,
				Validators.maxLength(this.maxLength),
				Validators.minLength(this.minLength),
			]),
		});
		this.loadingService.initiate();
		this.init();
		this._membershipService.membershipOfCompany
			.pipe(
				takeUntil(this._unSuscribeAll),
				tap((membershipData) => {
					if (Object.keys(membershipData).length === 0) return membershipData;
					let fb = membershipData.membership.current.IG || false;
					if (!fb) {
						this.router.navigate(['/dashboard/channels/instagram/activation']);
					}
				})
			)
			.subscribe();
	}
	ngOnInit(): void {}

	async init() {
		await this.initData();
		await this.getInfoPreferences();
	}
	async initData() {
		let token = localStorage.getItem('tokenAuth') || '';
		let decode = JSON.parse(atob(token.split('.')[1]));
		this.companyId = decode.company;
	}
	async getInfoPreferences() {
		try {
			let token = localStorage.getItem('tokenAuth') || '';
			let decode = JSON.parse(atob(token.split('.')[1]));
			this.companyId = decode.company;
			const result: any = await lastValueFrom(this.preferenceService.getPreferences(this.companyId));
			this.setData(result);
			this.loadingService.hide();
			this.isLoaded = true;
		} catch (e) {
			console.log(e);
			this.isLoaded = true;
			this.loadingService.hide();
			this.toastService.initiate({
				title: 'Error',
				content: 'Ha ocurrido un error inesperado.',
				type: 'error',
			});
		}
	}

	ngOnDestroy() {
		this._unSuscribeAll.next({});
		this._unSuscribeAll.complete();
	}
	async setData(data: any) {
		if (data.instagramWelcome) this.formFacebookPersonalize.controls['messagePredeterminate'].setValue(data.instagramWelcome);
		if (data.instagramBye) this.formFacebookPersonalize.controls['messageFinish'].setValue(data.instagramBye);
		if (data.instagramAgent) this.formFacebookPersonalize.controls['messageAgentNo'].setValue(data.instagramAgent);
		if (data.instagramAbilityMsg) this.formFacebookPersonalize.controls['messageAbilities'].setValue(data.instagramAbilityMsg);
		if (data.instagramQueue) this.formFacebookPersonalize.controls['messagePile'].setValue(data.instagramQueue);
		if (data.instagram) this.facebookActivate = data.instagram;
	}

	async submitForm() {
		try {
			if (this.formFacebookPersonalize.invalid) {
				this.formFacebookPersonalize.markAllAsTouched();
				return;
			}
			this.loadingService.initiate();
			let data = {
				instagramWelcome: this.formFacebookPersonalize.controls['messagePredeterminate'].value,
				instagramBye: this.formFacebookPersonalize.controls['messageFinish'].value,
				instagramAgent: this.formFacebookPersonalize.controls['messageAgentNo'].value,
				instagramAbilityMsg: this.formFacebookPersonalize.controls['messageAbilities'].value,
				instagramQueue: this.formFacebookPersonalize.controls['messagePile'].value,
			};
			const result: any = await lastValueFrom(this.preferenceService.updatePreferences(this.companyId, data));
			this.loadingService.hide();
			this.toastService.initiate({
				title: 'Éxito',
				content: 'Las personalizaciones del canal de instagram han sido guardadas.',
				type: 'success',
			});
		} catch (error) {
			console.log(error);
			this.loadingService.hide();
			this.toastService.initiate({
				title: 'Error',
				content: 'Ha ocurrido un error inesperado.',
				type: 'error',
			});
		}
	}
}
